import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { cnpjMask, cpfMask, genericFormatter } from 'src/app/core/helpers/masks';
import { StateService } from 'src/app/services/state.service';
import { Address } from 'src/app/shared/models/address.model';
import { Contact } from 'src/app/shared/models/contact.model';
import { CreditAnalisis } from 'src/app/shared/models/credit-analisis.model';
import { Proposal } from 'src/app/shared/models/proposal.model';
import { State } from 'src/app/shared/models/state.model';
import { User } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-doc-ccb',
  templateUrl: './doc-ccb.component.html',
  styleUrls: ['./doc-ccb.component.scss']
})
export class DocCcbComponent implements OnInit, OnChanges {
  @Input() proposal?: Proposal;
  @Input() ccbNumber?: any;
  @Input() formGroup?: FormGroup;
  @Input() financingOption?: CreditAnalisis;
  @Input() stateList: State[] = [];
  @Input() emissionDate?: any;
  @Input() fixedParameters: any;

  protected ccbFlux: any[] = [];
  protected prazo?: string;
  protected totalDebt = 0;
  protected ultimaParcela?: any;
  tables: {
    rows: {
      size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
      label: string;
      content?: any;
    }[][]
  }[] = [];
  pageClauses: {
    text?: string;
    children?: {
      text?: string;
      children?: {
        text: string;
        children?: string[]
      }[]
    }[]
  }[][] = [];

  constructor() {
    this.makeClauses();
  }

  ngOnInit(): void {
    this.formGroup?.valueChanges.subscribe(val => {
      if (this.proposal) {
        this.makeTables();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.proposal && (changes['proposal'] || changes['financingOption'] || changes['fixedParameters'])) {
      if (!changes['proposal']?.previousValue?.uid
       || (changes['financingOption'].previousValue == undefined
            && changes['financingOption'].currentValue != undefined)
      ) {
        this.makeTables();
        this.setQuadroCCB();
      }
      if ((changes['ccbNumber']
        && changes['ccbNumber']?.previousValue != changes['ccbNumber'].currentValue)
        || (changes['emissionDate']
        && changes['emissionDate']?.previousValue != changes['emissionDate'].currentValue)
      ) {
        this.makeTables();
      }
    }
  }

  makeTables() {
    let client: User = this.proposal!.cliente;
    let contact: Contact | null = client.ultimoContato;
    let address: Address | null = client?.ultimoEndereco;
    let installationAddress: Address | undefined = this.proposal?.endereco;

    let estado;
    let estadoUfrg;
    if (this.stateList) {
      if (address?.estadoId) {
        estado = this.stateList.find(e => e.id.toString() == address?.estadoId?.toString())?.sigla ?? '';
      }
      if (contact?.ufrg) {
        estadoUfrg = this.stateList.find(e => e.id.toString() == (this.formGroup?.controls['ufrg'].value ?? contact?.ufrg)?.toString()
        )?.sigla ?? '';
      }
    }

    let primeiraParcela = this.financingOption?.resposta?.Proposta?.Parcelas[0];
    this.ultimaParcela = this.financingOption?.resposta?.Proposta?.Parcelas[this.financingOption?.resposta?.Proposta?.Parcelas.length - 1];
    let dueDay = primeiraParcela?.DtVencimento.toString().split('T')[0];
    dueDay = dueDay?.split('-')[dueDay.split('-').length - 1];
    this.prazo = (Math.ceil(((new Date(this.ultimaParcela?.DtVencimento ?? new Date()) as any) - (new Date() as any))) / (1000 * 60 * 60 * 24)).toFixed(0)
    this.totalDebt = parseFloat((this.proposal?.valorTotalInstalacao ?? 0).toString())
      + parseFloat((this.financingOption?.resposta?.Proposta?.VlrIOF ?? 0).toString())
      + (this.fixedParameters ? this.fixedParameters['tac'] : 0);
    this.totalDebt -= this.proposal?.valorEntrada ?? 0;

    this.tables = [];

    /// Cédula de crédito bancário
    this.tables.push({
      rows: [
        [
          {
            label: 'Cédula Nº:',
            content: this.ccbNumber ?? this.proposal?.numeroCcb ?? '',
            size: 3
          },
          {
            label: 'Praça de Pagamento:',
            content: 'São Paulo - SP',
            size: 3
          },
          {
            label: 'Data de Emissão:',
            content: this.formGroup?.controls['dataEmissaoPagamento'].value ?? this.emissionDate ?? '',
            size: 3
          },
          {
            label: 'Data de Vencimento:',
            content: genericFormatter(this.ultimaParcela?.DtVencimento ?? new Date(), 'date'),
            size: 3
          }
        ],
        [
          {
            label: 'Credor:',
            content: 'BMP SOCIEDADE DE CREDITO DIRETO S/A, instituição financeira, inscrita no CNPJ/ME sob nº 34.337.707/0001-00, com sede na Av. Paulista, 1765, 1º Andar, CEP 01311-200, São Paulo, SP, neste ato, representada na forma do seu Contrato Social. E-mail: atendimento@moneyp.com.br',
            size: 12
          }
        ]
      ]
    });

    /// Emitente
    this.tables.push({
      rows: [
        [
          {
            label: 'Nome:',
            content: this.formGroup?.controls['nome'].value ?? contact?.nome,
            size: 9
          },
          {
            label: 'CPF:',
            content: cpfMask(this.formGroup?.controls['cpf'].value ?? client?.documento),
            size: 3
          }
        ],
        [
          {
            label: 'Dt Nasc:',
            content: genericFormatter(this.formGroup?.controls['nascimento'].value ?? client?.nascimento, 'date'),
            size: 2
          },
          {
            label: 'Estado Civil:',
            content: this.formGroup?.controls['estadoCivil'].value ?? contact?.estadoCivil ?? '',
            size: 2
          },
          {
            label: 'Doc. Identidade:',
            content: this.formGroup?.controls['documento'].value ?? contact?.documentoIdentificacao ?? '',
            size: 3
          },
          {
            label: 'Emissor/UF:',
            content: estadoUfrg ?? '',
            size: 2
          },
          {
            label: 'Data da Emissão:',
            content: genericFormatter(this.formGroup?.controls['dataEmissao'].value ?? contact?.dataEmissaoDocumento ?? '', 'date'),
            size: 3
          }
        ],
        [
          {
            label: 'Endereço:',
            content: (this.formGroup?.controls['logradouro'].value ?? address?.logradouro ?? '') + ', ' + (this.formGroup?.controls['numero'].value ?? address?.numero) + (this.formGroup?.controls['complemento'].value || address?.complemento ? (', ' + (this.formGroup?.controls['complemento'].value ?? address?.complemento)) : ''),
            size: 9
          },
          {
            label: 'Bairro:',
            content: this.formGroup?.controls['bairro'].value ?? address?.bairro ?? '',
            size: 3
          }
        ],
        [
          {
            label: 'Cidade:',
            content: this.formGroup?.controls['cidade'].value ?? address?.cidade ?? '',
            size: 7
          },
          {
            label: 'Estado:',
            content: estado,
            size: 2
          },
          {
            label: 'CEP:',
            content: (this.formGroup?.controls['cep'].value ?? address?.cep)?.replace(/^([\d]{2})\.*([\d]{3})-*([\d]{3})/, "$1$2-$3"),
            size: 3
          }
        ],
        [
          {
            label: 'E-mail:',
            content: this.formGroup?.controls['email'].value ?? contact?.email ?? '',
            size: 12
          }
        ]
      ]
    });

    /// Caracteristicas da operação
    this.tables.push({
      rows: [
        [
          {
            label: "Valor Líquido do Crédito:",
            content: genericFormatter(((this.proposal?.valorTotalInstalacao ?? 0) - (this.proposal?.valorEntrada ?? 0)) ?? '', 'money'),
            size: 4
          },
          {
            label: "Valor do IOF:",
            content: genericFormatter(this.financingOption?.resposta?.Proposta?.VlrIOF ?? '', 'money'),
            size: 4
          },
          {
            label: "Valor Total da Dívida:",
            content: genericFormatter(this.totalDebt, 'money'),
            size: 4
          }
        ],
        [
          {
            label: "Data de Desembolso:",
            content: this.formGroup?.controls['dataDesembolso'].value ?? this.emissionDate ?? this.getCurdate,
            size: 4
          },
          {
            label: "Prazo (dias):",
            content: this.prazo,
            size: 4
          },
          {
            label: "Quantidade de Parcelas:",
            content: (this.proposal?.parcelaSelecionada ?? '').toString(),
            size: 4
          }
        ],
        [
          {
            label: "Carência (dias):",
            content: (this.proposal?.carenciaSelecionada ?? '').toString(),
            size: 4
          },
          {
            label: "Forma de Pagamento das Parcelas:",
            content: "Boleto, TED ou PIX",
            size: 4
          },
          {
            label: "Vencimento das Parcelas:",
            content: "Todo dia " + dueDay + " ou dia útil subsequente.",
            size: 4
          }
        ],
        [
          {
            label: "Vencimento da Primeira Parcela:",
            content: genericFormatter(primeiraParcela?.DtVencimento ?? new Date(), 'date'),
            size: 4
          },
          {
            label: "Vencimento da Última Parcela:",
            content: genericFormatter(this.ultimaParcela?.DtVencimento ?? new Date(), 'date'),
            size: 4
          },
          {
            label: "Valor da Parcela:",
            content: genericFormatter(this.financingOption?.resposta?.Proposta?.VlrParcela ?? '', 'money'),
            size: 4
          }
        ],
        [
          {
            label: "Taxa de Juros (% a.a. / % a.m):",
            content: `${genericFormatter(this.financingOption?.resposta?.Proposta?.PercJurosAnual ?? 0, 'decimal', 4).toString(4)}% a.a. / ${genericFormatter(this.financingOption?.resposta?.Proposta?.PercJurosMensal ?? 0, 'decimal', 4).toString(4)}% a.m.`,
            size: 4
          },
          {
            label: "Custo Efetivo Total (% a.a. / % a.m):",
            content: `${genericFormatter(this.financingOption?.resposta?.Proposta?.PercCETAnual ?? 0, 'decimal', 4).toString()}% a.a. / ${genericFormatter(this.financingOption?.resposta?.Proposta?.PercCETMensal ?? 0, 'decimal', 4).toString()}% a.m.`,
            size: 4
          },
          {
            label: "Tarifa de Análise de Crédito",
            content: genericFormatter((this.fixedParameters ? this.fixedParameters['tac'] : 0), 'money'),
            size: 4
          },
        ]
      ]
    });

    /// Liberação de crédito
    const numeration = this.hasSplit ? '1' : '';
    const instalationTotal = this.hasSplit
      ? ((this.proposal?.valorTotalInstalacao ?? 0)
        - (this.formGroup?.controls['splitValue'].value ?? this.proposal?.valorSplit ?? 0))
      : this.proposal?.valorTotalInstalacao;
    this.tables.push({
      rows: [
        [
          {
            label: `Valor do Desembolso ${numeration}:`,
            content: genericFormatter(instalationTotal ?? '', 'money'),
            size: 5
          },
          {
            label: `Nº do Banco ${numeration}:`,
            content: '341',
            size: 2
          },
          {
            label: `Nº da Agência ${numeration}:`,
            content: '8147',
            size: 2
          },
          {
            label: `Nº da Conta ${numeration}:`,
            content: '38808-9',
            size: 3
          }
        ],
        [
          {
            label: `Razão Social do Favorecido ${numeration}:`,
            content: 'ENGEMETAL COMERCIO E MANUTENCAO LTDA',
            size: 9
          },
          {
            label: `CPF/CNPJ ${numeration}:`,
            content: '10.383.997/0001-60',
            size: 3
          }
        ]
      ]
    });

    /// Split crédito
    if (this.hasSplit) {
      this.tables[this.tables.length - 1].rows.push([
        {
          label: 'Valor do Desembolso 2:',
          content: genericFormatter(this.formGroup?.controls['splitValue'].value ?? this.proposal?.valorSplit ?? '', 'money'),
          size: 5
        },
        {
          label: 'Nº do Banco 2:',
          content: this.formGroup?.controls['splitCodigoBanco'].value ?? this.proposal?.dadosSplit?.codigoBanco ?? '',
          size: 2
        },
        {
          label: 'Nº da Agência 2:',
          content: this.formGroup?.controls['splitAgencia'].value ?? this.proposal?.dadosSplit?.agencia ?? '',
          size: 2
        },
        {
          label: 'Nº da Conta 2:',
          content: this.formGroup?.controls['splitContaEDigito'].value ?? this.proposal?.dadosSplit?.contaEDigito ?? '',
          size: 3
        }
      ],
      [
        {
          label: 'Razão Social do Favorecido 2:',
          content: this.proposal?.dadosSplit?.nomePagamento ?? '',
          size: 9
        },
        {
          label: 'CPF/CNPJ 2:',
          content: cnpjMask(this.formGroup?.controls['splitDocumentoFederal'].value ?? this.proposal?.dadosSplit?.documentoFederal ?? ''),
          size: 3
        }
      ]);
    }

    /// Descrição e local
    this.tables.push({
      rows: [
        [
          {
            label: "Razão Social Fornecedor:",
            content: 'ENGEMETAL COMERCIO E MANUTENCAO LTDA',
            size: 3
          },
          {
            label: "CNPJ Fornecedor:",
            content: '10.383.997/0001-60',
            size: 3
          },
          {
            label: "Endereço de Instalação:",
            content: this.getFullEquipamentAddress(),
            size: 6
          }
        ],
        [
          {
            label: "Potência Instalada:",
            content: (this.formGroup?.controls['potenciaInstalada'].value ?? this.proposal?.potenciaInstalada ?? 0).toString() + ' kw',
            size: 3
          },
          {
            label: "Quantidade de Paineis Instalados:",
            content: (this.formGroup?.controls['quantidadePaineis'].value ?? this.proposal?.quantidadePaineisInstalados ?? '').toString(),
            size: 4
          },
          {
            label: "Descrição dos Paineis Instalados:",
            content: this.formGroup?.controls['descricaoPaineis'].value ?? this.proposal?.descricaoPaineisInstalados ?? '',
            size: 5
          }
        ],
        [
          {
            label: "Quantidade de Inversores Instalados:",
            content: (this.formGroup?.controls['quantidadeInversores'].value ?? this.proposal?.quantidadeInversoresInstalados ?? '').toString(),
            size: 4
          },
          {
            label: "Descrição dos Inversores Instalados:",
            content: this.formGroup?.controls['descricaoInversores'].value ?? this.proposal?.descricaoInversoresInstalados ?? '',
            size: 4
          },
          {
            label: "Demais Itens Instalados:",
            content: this.formGroup?.controls['demaisItensInstalados'].value ?? this.proposal?.demaisItensInstalados ?? '',
            size: 4
          }
        ]
      ]
    });
  }

  makeClauses() {
    this.pageClauses.push([
      {
        text: "DA CONCESSÃO DO CRÉDITO, FORMA DE PAGAMENTO E DA CONDIÇÃO SUSPENSIVA",
        children: [
          { text: "Pela presente <b>CÉDULA DE CRÉDITO BANCÁRIO</b> (“<b>CCB</b>” ou “<b>CÉDULA</b>”), disciplinada pela legislação aplicável à espécie e pelas cláusulas e condições abaixo constantes, emitida de forma eletrônica em conformidade com a Lei nº 10.931 de 02/08/2004, conforme alterada (“Lei nº 10.931”), declara o <b>EMITENTE</b> já devidamente qualificado, que pagará por esta <b>CCB</b> ao <b>CREDOR</b>, ou a quem este vier a indicar, e a eventuais sucessores e/ou cessionários, nas respectivas datas de vencimento designadas <b>QUADRO III</b> acima e conforme detalhado no <b>ANEXO I</b> desta <b>CCB</b>, em moeda corrente nacional, a quantia líquida, certa e exigível, correspondente ao Valor da Parcela indicado no subitem 3.12, acrescida dos juros remuneratórios à taxa indicada no subitem 3.13 ambos presentes no <b>QUADRO III</b> acima, capitalizados diariamente com base em um ano de 365 (trezentos e sessenta e cinco) dias, a partir da Data de Desembolso dos recursos pelo <b>CREDOR</b> aos Favorecidos indicados no <b>QUADRO IV</b>, bem como demais encargos devidos conforme previsto em lei e disposto na presente <b>CCB</b>. Referido valor se refere à concessão de crédito, pelo <b>CREDOR</b> ao <b>EMITENTE</b>, nos termos da presente <b>CCB</b>." },
          { text: "Assim, o <b>CREDOR</b> concede ao <b>EMITENTE</b>, que aceita, um crédito no valor e forma especificados no <b>Quadro III</b>, assim como autoriza expressamente ao <b>CREDOR</b> a creditar os recursos do Valor Líquido do Crédito diretamente aos favorecidos e conta(s) especificadas no <b>Quadro IV</b> acima, por conta e ordem do <b>EMITENTE</b> até a Data de Desembolso especificada no item 3.4 do <b>QUADRO III</b> acima. O <b>EMITENTE</b> declara e garante ao <b>CREDOR</b> que na eventualidade de parte ou totalidade do Valor Líquido do Crédito ser utilizado, por conta e ordem do <b>EMITENTE</b>, para pagamento para aquisição de produtos e/ou serviços contratados pelo <b>EMITENTE</b> junto aos terceiros favorecidos indicados no <b>QUADRO IV</b>, tal fato de forma alguma implicará em responsabilidade do <b>CREDOR</b> com relação a tal operação de fornecimento de bens e serviços e/ou eventuais questões, vícios ou reclamações porventura decorrentes, sendo tais terceiros os exclusivos responsáveis perante o <b>EMITENTE</b> pela entrega, funcionamento, garantia e assistência técnica de tais bens e serviços. Nessa hipótese, o <b>EMITENTE</b> declara reconhecer que a presente <b>CCB</b> e a operação de crédito ora contratada permanecerá válida e exequível para todos os fins legais, sendo o Valor Total da Dívida integralmente devido pela <b>EMITENTE</b> nos termos da Cláusula 1.1 acima, e conforme <b>Quadro III</b> acima." },
          { text: "O <b>EMITENTE</b> declara e garante que está devidamente autorizado a firmar a presente <b>CCB</b>, assumir todas as obrigações aqui pactuadas e cumprir todos os seus termos e condições até a quitação final de todas as obrigações aqui estabelecidas, uma vez que as obrigações pecuniárias assumidas nesta <b>CCB</b> são compativeis com a sua capacidade econômico-financeira para honrá-las." },
          { text: "O <b>EMITENTE</b> tem expresso conhecimento de que os juros ajustados para o empréstimo a que se refere a presente <b>CCB</b> são calculados e capitalizados, sempre e invariavelmente, de forma diária, conforme permitido pela legislação aplicável." },
          { text: "O <b>EMITENTE</b> declara, ainda, ter lido previamente e compreendido a presente <b>CCB</b> e não ter dúvidas sobre quaisquer de suas condições, principalmente no que tange às tarifas e aos ﬂuxos para cálculo do CET (Custo Efetivo Total), e têm ciência dos termos da Lei nº 10.931/2004, especialmente seus artigos 26 e 28." },
          { text: "O <b>CREDOR</b> fica expressamente autorizado a qualquer tempo, a seu exclusivo critério e independentemente da prévia notificação ou anuência do <b>EMITENTE</b>, negociar, ceder ou endossar a terceiros os direitos de crédito que detém em razão desta <b>CCB</b>, bem como a transferi-la a terceiros, incluindo, sem limitação, a instituições financeiras e fundos de investimento em direitos creditórios, mediante endosso da “via negociável”, sendo certo que a cessão ou o endosso para qualquer terceiro não caracterizarão violação desta <b>CCB</b> ou do sigilo bancário em relação ao <b>EMITENTE</b>. Ocorrendo a cessão ou o endosso, o cessionário/endossatário desta <b>CCB</b> assumirá automaticamente a qualidade de credor desta <b>CCB</b>, passando a ser titular de todos os direitos e obrigações dela decorrentes. Nos termos do artigo 287 da Lei nº 10.406/2002 (“Código Civil”), eventual cessão, pelo <b>CREDOR</b>, dos direitos de crédito que detém em razão desta <b>CCB</b> abrangerá não somente tais direitos de crédito, como também tudo que os direitos de crédito representam, inclusive juros e encargos, bem como todos os direitos, ações, coobrigações e garantias outorgados por terceiros assegurados ao <b>CREDOR</b> por força dos direitos de crédito." },
        ]
      }
    ]);
    this.pageClauses.push([
      {
        children: [
          { text: "O <b>EMITENTE</b> autoriza o <b>CREDOR</b>, ou terceiros por ele indicados, a, em assim desejando, registrar esta <b>CCB</b> em sistema de negociação eletrônica, notadamente no sistema operacionalizado pela CETIP S.A. - Mercados Organizados ou outros que venham a substitui-lo. O <b>EMITENTE</b> autoriza o <b>CREDOR</b> ou terceiros indicados pelo <b>CREDOR</b> a tomarem todas as medidas necessárias à devida formalização, custódia e registro dos Certificados em sistema de negociação eletrônica." },
          { text: "O <b>EMITENTE</b> se obriga a efetuar o pagamento do valor principal, acrescido dos encargos incidentes, mediante o pagamento do Valor da Parcela indicada no subitem 3.12 do <b>QUADRO III</b> acima, nas correspondentes datas de vencimento expressas nos subitens 3.9, 3.10 e 3.11, por meio de transferência bancária para a conta indicada pelo <b>CREDOR</b>, boletos bancários enviados pelo <b>CREDOR</b> ao e-mail do <b>EMITENTE</b> ou outra forma determinada pelo <b>CREDOR</b>, ou seu eventual sucessor ou cessionário, desde que comunicada previamente ao <b>EMITENTE</b>. O <b>EMITENTE</b> declara ter ciência que eventual não recebimento dos documentos de cobrança que lhe serão enviados, não o eximirá da responsabilidade de pagar os valores devidos nos exatos vencimentos, conforme indicados nos subitens 3.9, 3.10 e 3.11 do <b>QUADRO III</b> acima, os quais declara serem de seu pleno conhecimento. Nesse caso, o <b>EMITENTE</b> deverá contatar o <b>CREDOR</b> por meio de qualquer um dos canais de atendimento colocados à sua disposição e indicados abaixo, sendo que, em caso de alteração, serão informados ao <b>EMITENTE</b> no endereço de e-mail indicado no subitem 2.13 do <b>QUADRO II</b> acima ou por qualquer outro meio de comunicação disponível ao <b>CREDOR</b>, devendo o <b>EMITENTE</b> efetuar a liquidação de suas obrigações, consoante os termos desta <b>CCB</b>." },
          { text: "Qualquer valor recebido pelo <b>CREDOR</b> (em virtude de pagamento do boleto, TED, excussão de garantias ou outro meio qualquer) será imputado em pagamento das despesas da operação de crédito (incluindo despesas relacionadas à sua cobrança), do saldo acumulado de multa moratória, do saldo acumulado dos juros moratórios, do saldo acumulado de juros remuneratórios indicado no subitem <b>3.13 do QUADRO III</b> acima e, então, do principal, nessa ordem." },
          { text: "O <b>EMITENTE</b> declara e garante que cumpre o disposto na legislação referente à Política Nacional de Meio Ambiente e não aplicará os recursos decorrentes desta <b>CCB</b> no financiamento de qualquer equipamento, atividade ou projeto que caracterize crime contra o meio ambiente, que cause poluição e/ou que prejudique o ordenamento urbano e o patrimônio cultural, obrigando-se a respeitar integralmente as normas contidas nas Leis nº 9.605/98 e nº 9.985/2000 e demais regras complementares e ainda, que não utilizará os recursos no desenvolvimento de suas atividades comerciais ou vinculadas ao seu objeto social, no caso de pessoas jurídicas, formas nocivas ou de exploração de trabalho forçado e/ou mão de obra infantil." },
          { text: "O <b>CREDOR</b> e o <b>EMITENTE</b> acordam que constitui condição suspensiva para a eficácia desta <b>CCB</b>, nos termos do artigo 125 do Código Civil, a disponibilização pelo <b>CREDOR</b> ao <b>EMITENTE</b>, ou terceiros por ele indicados, do Valor Líquido do Crédito indicado no subitem 3.1 do <b>Quadro III</b> acima (“Condição Suspensiva”). Na hipótese de a Condição Suspensiva não ocorrer em até 10 (dez) dias úteis contados da Data de Emissão, a presente <b>CCB</b> não será revestida de eficácia e se extinguirá de pleno direito, sem qualquer ônus ou penalidade para qualquer das partes." },
        ]
      },
      {
        text: "DO ATRASO NO PAGAMENTO E ENCARGOS MORATÓRIOS",
        children: [
          {
            text: "Na hipótese de inadimplemento ou mora das obrigações pecuniárias decorrentes desta <b>CCB</b>, além da inclusão do <b>EMITENTE</b> nos órgãos de proteção de crédito e negativação, o <b>EMITENTE</b> estará obrigado a pagar a <b>CREDOR</b> ou a quem este indicar, cumulativamente, além da quantia correspondente à dívida em aberto, os seguintes encargos:",
            children: [
              { text: "Juros de mora à razão de 1% a.m. (um por cento ao mês) ou fração, calculados a partir da data do inadimplemento até a data do efetivo pagamento pelo <b>EMITENTE</b>;" },
              { text: "Multa contratual, de natureza não compensatória, de 2% (dois por cento) incidente sobre o saldo <b>EMITENTE</b> atualizado (incluindo juros remuneratórios e juros de mora); e" },
              { text: "Na hipótese do <b>CREDOR</b> vir a ser compelido a recorrer a meios administrativos ou judiciais para receber os valores devidos no âmbito desta <b>CCB</b>, as despesas de cobrança administrativa e judicial, incluindo, sem limitação, despesas com procedimentos junto aos cartórios de protesto competentes, honorários advocaticios, custas processuais, despesas com registro e excussão das garantias, tais como registro da alienação fiduciária no cartório compentente, comissão do leiloeiro e com anúncios, despesas operacionais relativas à remoção dos bens para quitação, entre outros." },
            ]
          }
        ]
      }
    ]);
    this.pageClauses.push([
      {
        children: [
          {
            text: "Para fins de esclarecimento:",
            children: [
              { text: "Os juros de mora indicado na Cláusula 2.1.1 só se aplicarão sobre o Valor da Parcela, acrescida dos juros remuneratórios à taxa indicada no subitem 3.13 do <b>QUADRO III</b> acima, que esteja vencida e que permaneça não paga após a respectiva Data do Vencimento." },
              { text: "A multa contratual só se aplicará sobre o Valor da Parcela, acrescida dos juros remuneratórios à taxa indicada no subitem 3.13 do <b>QUADRO III</b> acima e dos juros de mora que tenham vencido e permaneçam não pagos após a respectiva Data do Vencimento." }
            ]
          },
          { text: "Todas e quaisquer despesas necessárias ou convenientes à devida formalização desta <b>CCB</b>, suas garantias e documentos relacionados junto a cartórios de registro ou outras entidades competentes, bem como qualquer outra despesa, judicial ou extrajudicial, necessária ou conveniente à segurança, comprovação da existência e/ou regularidade do crédito do <b>CREDOR</b>, seu sucessor ou cessionários, serão suportadas pelo <b>EMITENTE</b>, podendo ser deduzidos do Valor Líquido do Crédito a ser desembolsado aos favorecidos por conta e ordem do <b>EMITENTE</b>, sem que isso implique qualquer redução no Valor Total da Dívida devido pelo <b>EMITENTE</b> ao <b>CREDOR</b>." },
          { text: "A quitação de parcela(s) posterior(es) não implica quitação de parcela(s) anterior(es) e/ou de suas multas e juros moratórios." },
        ]
      },
      {
        text: "DA GARANTIA",
        children: [
          { text: "O <b>EMITENTE</b> declara que os Equipamentos estão livres e desembaraçados de qualquer ônus ou encargos de qualquer natureza." },
          {
            text: "Na qualidade de credor fiduciário, poderá o <b>CREDOR</b> exercer sobre os Equipamentos todos os direitos que lhe são conferidos na forma da Lei, inclusive os de:",
            children: [
              { text: "Conservar e recuperar a posse dos bens contra qualquer detentor, inclusive o próprio <b>EMITENTE</b>." }
            ]
          },
          { text: "Correrão por conta do <b>EMITENTE</b> todas as despesas incorridas pelo <b>CREDOR</b> no exercício de seu direito, juntamente com todas as demais aqui previstas como de sua responsabilidade, bem como quaisquer outras havidas na proteção e exercício do direito do <b>CREDOR</b>, as quais serão também cobertas pela presente garantia." },
          {
            text: "Enquanto o <b>EMITENTE</b> estiver adimplente à presente <b>CCB</b>, a suas expensas e riscos, poderá utilizar os Equipamentos a sua destinação, sendo obrigado como fiel depositário: ",
            children: [
              { text: "A empregar na guarda da coisa a diligência exigida por sua natureza." },
              { text: "A entregá-la ao credor em funcionamento regular e bom estado de conservação, se a dívida não for paga no vencimento." },
            ]
          },
          { text: "O <b>EMITENTE</b> não poderá ceder e transmitir os direitos de que sejam ou venha a se tornar titular sobre os Equipamentos, salvo se houver a expressa concordância do <b>CREDOR</b>." },
          { text: "O <b>EMITENTE</b> deverá conservar os Equipamentos contra todos os riscos a que possam estar sujeitos até a final liquidação do débito decorrente dessa <b>CCB</b>." },
          { text: "Com a constituição da propriedade fiduciária, dá-se o desdobramento da posse, tornando-se o <b>EMITENTE</b> possuidor direto e o <b>CREDOR</b> o possuidor indireto dos Equipamentos." },
          {
            text: "Será considerado, além das hipoteses previstas na cláusula 5, causa de vencimento antecipada da dívida:",
            children: [
              { text: "Se, deteriorando-se ou depreciando-se o(s) Equipamento(s) dado(s) em garantia, desfalcar a garantia e o <b>EMITENTE</b> intimado não a reforçar ou substituir." },
              { text: "Se o <b>EMITENTE</b> caír em insolvência ou falir." },
              { text: "Se perecerem os Equipamentos dados em garantia e não forem substituídos." },
              { text: "Se o <b>EMITENTE</b> ceder, alienar, substituir ou gravar, sob qualquer forma, os Equipamentos dados em garantia sem prévia e expressa autorização do <b>CREDOR</b>." },
              { text: "Em caso de descumprimento de qualquer obrigação deste contrato pelo <b>EMITENTE</b>. (ex. Caso de utilização para atividade criminosa ou ilegal)." },
            ]
          }
        ]
      }
    ]);
    this.pageClauses.push([
      {
        children: [
          { text: "Após 05 (cinco) dias úteis da data do vencimento sem o adimplemento do <b>EMITENTE</b> estes serão intimados na forma da lei para, para, no prazo de 15 (quinze) dias úteis, satisfazerem as obrigações vencidas e as que se vencerem até a data do efetivo pagamento, acrescidas dos encargos contratuais, além das despesas de cobrança e da intimação." },
          { text: "Vencido o prazo disposto no item acima, e não sendo paga a dívida, fica o <b>CREDOR</b> autorizado a consolidar a propriedade, realizar a reintegração de posse e proceder à venda dos Equipamentos, independente de leilão, hasta pública ou qualquer outra medida judicial ou extrajudicial, a terceiros, aplicando o preço no pagamento de seu crédito e das despesas de cobrança. Se o preço de venda não bastar para a liquidação do crédito do proprietário fiduciário, continuará o <b>EMITENTE</b> obrigado pelo restante." },
          { text: "O <b>FIEL DEPOSITÁRIO</b>, conforme definido no <b>QUADRO II</b> acima, obriga-se a manter os Equipamentos objetos da aquisição sob sua guarda em perfeito estado de conservação, correndo por conta do <b>EMITENTE</b> todas as despesas da guarda e necessárias à conservação dos Equipamentos." },
          { text: "Na hipótese de o <b>EMITENTE</b>, quando aplicável, ajuizar pedido de recuperação judicial ou insolvência, o <b>CREDOR</b>, ou seu eventual sucessor, não se submeterá aos efeitos da recuperação judicial e prevalecerão os direitos de propriedade sobre a coisa e os termos contratuais, na condição de proprietário fiduciário, conforme dispõe o artigo 49 §3º da Lei 11.101/2005." },
          { text: "Em caso de danificação dos Equipamentos dados em garantida, ou se o mesmo perecer por fato imputável a terceiro, o <b>CREDOR</b> sub-rogar-se-á no direito à indenização devida pelo terceiro causador do dano, até o montante necessário para liquidar a obrigação assumida na presente <b>CCB</b>. Faculta-se ao <b>CREDOR</b> exigir a substituição da garantia ou o seu reforço, renunciando o direito à percepção da indenização devida." },
          { text: "O <b>CREDOR</b> poderá exigir do <b>EMITENTE</b> a substituição ou o reforço da garantia ora constituída em caso de perda, deterioração ou diminuição do valor dos Equipamentos." },
        ]
      },
      {
        text: "DO VENCIMENTO ANTECIPADO",
        children: [
          {
            text: "Além das demais hipóteses estabelecidas em lei e nesta <b>CCB</b>, a dívida aqui contraída pelo <b>EMITENTE</b> reputar-se-á antecipadamente vencida, facultando-se ao <b>CREDOR</b> exigir a imediata e integral satisfação de seu crédito, independentemente de aviso ou notificação judicial ou extrajudicial de qualquer espécie, na ocorrência de qualquer das hipóteses previstas nos artigos 333 e 1.425 do Código Civil Brasileiro e, ainda, nas hipóteses abaixo:",
            children: [
              { text: "Se ocorrer inadimplemento de qualquer obrigação assumida pelo <b>EMITENTE</b> em consonância com as cláusulas e condições aqui estabelecidas, principalmente no que tange à higidez da garantia e/ou ao pagamento das parcelas devidas em decorrências do crédito a ele concedido por força da presente <b>CCB</b>." },
              { text: "Se for comprovada a falsidade de qualquer declaração, informação ou documento que houver sido respectivamente firmada, prestada ou entregue pelo <b>EMITENTE</b>." },
              { text: "Se o <b>EMITENTE</b> sofrer qualquer (quaisquer) medida(s) judicial(ais) ou extrajudicial(ais), incluindo, sem limitação, execuções e protestos ou se houver mudança adversa relevante na capacidade econômica-financeira do <b>EMITENTE</b> que por qualquer forma, possa(m) afetar negativamente os créditos do empréstimo e/ou as garantias conferidas ao credor da <b>CCB</b>." },
              { text: "Se as garantias da <b>CCB</b>, ora constituídas, por qualquer fato atinente ao seu objeto ou prestador se tornar inábil, imprópria, ou insuficiente para assegurar o pagamento da dívida, e desde que não seja substituída, ou complementada, quando solicitada por escrito pelo <b>CREDOR</b> ou a quem este vier a indicar." },
              { text: "Se, sem o expresso consentimento do <b>CREDOR</b> da <b>CCB</b>, ocorrer a transferência a terceiros dos direitos e obrigações do <b>EMITENTE</b> previstos nesta <b>CCB</b>." },
              { text: "Se houver a venda ou qualquer outra forma de alienação ou transferência de propriedade do imóvel no qual o(s) Equipamentos descritos no <b>QUADRO V</b> desta <b>CCB</b> tenham sido instalados ou venda ou alteração do próprio Local de Instalação sem a prévia anuência do <b>CREDOR</b>, seu sucessor ou cessionário." },
              { text: "Ocorrendo outras hipóteses expressas nesta <b>CCB</b> e não listadas acima." },
            ]
          }
        ]
      },
    ]);
    this.pageClauses.push([
      {
        children: [
          { text: "Para os fins do disposto nesta cláusula, será devido ao <b>CREDOR</b> o pagamento imediato e integral do saldo <b>EMITENTE</b> da <b>CCB</b>, nele incluidos os juros e encargos, sendo que toda e qualquer obrigação do <b>EMITENTE</b> decorrente desta <b>CCB</b> devem ser livres de quaisquer tributos e/ou contribuições ficando estabelecido que o <b>EMITENTE</b> deverá pagar quantias adicionais eventualmente necessárias para fazer frente às deduções e recolhimentos impostos pela legislação tributária, de forma que o <b>CREDOR</b> receba uma quantia equivalente à que teria sido recebida se tais deduções, recolhimentos ou pagamentos não fossem necessários." }
        ]
      },
      {
        text: "DAS DECLARAÇÕES",
        children: [
          {
            text: "As Partes signatárias, cada uma por si, declaram e garantem que:",
            children: [
              { text: "Possuem plena capacidade e legitimidade para celebrar a presente <b>CCB</b>, realizar todas as operações e cumprir todas as obrigações assumidas, tendo tomado todas as medidas de natureza societária e outras eventualmente necessárias para autorizar a sua celebração, implementação e cumprimento de todas as obrigações constituídas." },
              {
                text: "A celebração desta <b>CCB</b> e o cumprimento das obrigações de cada uma das <b>PARTES</b>: ",
                children: [
                  "Não violam qualquer disposição contida nos seus documentos societários, caso aplicável.",
                  "Não violam qualquer lei, regulamento, decisão judicial, administrativa ou arbitral, aos quais a respectiva PARTE esteja vinculada.",
                  "Não exigem qualquer consentimento ação ou autorização, prévia ou posterior, de terceiros.",
                ]
              },
              { text: "Esta <b>CCB</b> é validamente celebrada e constitui obrigação legal, válida, vinculante e exequível contra cada uma das <b>PARTES</b>, de acordo com os seus termos." },
              { text: "Cada <b>PARTE</b> está apta a cumprir as obrigações ora previstas nesta <b>CCB</b> e agirá em relação à mesma de boa-fé e com lealdade." },
              { text: "Nenhuma <b>PARTE</b> depende economicamente da outra." },
              { text: "Nenhuma das <b>PARTES</b> se encontra em estado de necessidade ou sob coação para celebrar esta <b>CCB</b> e/ou quaisquer contratos e compromissos a ela relacionados e acessórios." },
              { text: "As discussões sobre o objeto contratual, crédito, encargos incidentes e obrigações acessórias, oriundos desta <b>CCB</b>, foram feitas, conduzidas e implementadas por livre iniciativa das <b>PARTES</b>." },
              { text: "Anuem com a formalização desta <b>CCB</b> por meio de todas as formas em direito admitidas, incluindo meios eletrônicos e plenamente eficazes, ainda que seja estabelecida assinatura e aceitação eletrônica ou certificação fora dos padrões ICP – Brasil, conforme disposto pelo art. 10 da Medida Provisória nº 2.200/2001 em vigor no Brasil." },
            ]
          },
          {
            text: "O <b>EMITENTE</b> declara e garante que:",
            children: [
              { text: "Não se encontra, de fato ou de direito, em situação de falência ou de insolvência." },
              { text: "Nenhuma decisão judicial, liminar, determinação, ordem ou decreto de qualquer juízo ou de qualquer autoridade federal, estadual ou municipal ou qualquer outra autoridade foi emitida ao <b>EMITENTE</b> e o <b>EMITENTE</b> não apresentou qualquer requerimento ou peticionaram junto a qualquer pessoa e não recebeu qualquer notificação de qualquer pessoa, requerendo a falência (ou dando início a qualquer procedimento de insolvência) do <b>EMITENTE</b>." },
              { text: "Antes de contratar o crédito objeto desta <b>CCB</b>, tomou ciência dos custos e despesas que compõem o CET – Custo Efetivo Total (subitem 3.14 do <b>QUADRO III</b>)." },
              { text: "Está ciente de que os juros acordados serão capitalizados diariamente." },
              { text: "Todas as informações constantes do preâmbulo desta <b>CCB</b> são corretas, completas e verdadeiras e que a renda e patrimônio declarados são/foram obtidos de forma lícita, estando ciente do artigo 11, inciso II da Lei 9.613/98, com as alterações introduzidas pela Lei 12.863/12, bem como de que a falsidade ou incompletude das informações ensejará aplicação das penalidades legais, especialmente criminais conforme o artigo 19 da Lei 7.492/86,Artigos 297, 298 e 299 do Código Penal, civis e administrativas, nos termos do artigo 2º da Lei 7.115/83." },
            ]
          }
        ]
      }
    ])
    this.pageClauses.push([
      {
        children: [
          {
            children: [
              { text: "A liberação dos recursos pelo <b>CREDOR</b> à(s) conta(s) determinada(s) no <b>QUADRO IV</b> pelo <b>EMITENTE</b> será feita na forma expressamente indicada pelo <b>EMITENTE</b> ao <b>CREDOR</b>, conforme o <b>QUADRO IV</b> acima." },
              { text: "Mesmo que parte ou totalidade do valor da dívida representado por esta <b>CCB</b> venha a ser utilizado, por conta e ordem do <b>EMITENTE</b>, para a aquisição total ou parcial dos bens indicados no <b>QUADRO V</b>, eventuais problemas relacionados ao funcionamento, à instalação, garantia ou à assistência técnica de referidos bens são de responsabilidade exclusiva do Fornecedor que a <b>EMITENTE</b> livremente vier a contratar por sua própria conta e risco." },
              { text: "Reconhece expressamente que problemas associados aos bens e serviços por ela contratados não servirão de excusa para o não cumprimento das obrigações pecuniárias assumidas pela <b>EMITENTE</b> por meio da presente <b>CCB</b>." },
              { text: "Está ciente de que a <b>CCB</b> poderá ser endossada em favor de quaisquer terceiros, incluindo, sem limitação, qualquer pessoa jurídica, instituições financeiras, fundos de investimento em direitos creditórios, e outros, de modo que todas as obrigações do <b>EMITENTE</b> perante o <b>CREDOR</b> em decorrência da <b>CCB</b> passarão a ser devidas ao endossatário, que passará a ser responsável por quaisquer negociações e/ou cobranças em decorrência de eventual inadimplência." },
              { text: "Que o imóvel no qual serão instalados os Equipamentos adquiridos com os recursos da <b>CCB</b> é de propriedade do <b>EMITENTE</b> ou de seus familiares, sendo que o <b>EMITENTE</b> detém o direito de uso e/ou posse do referido imóvel." },
              { text: "Confirma e ratifica que autoriza a instalação e manutenção, pelo prazo de vigência desta <b>CCB</b>, do Dispositivo nos Equipamentos, o qual poderá ser utilizado para captação dos dados de funcionamento do." },
            ]
          }
        ]
      },
      {
        text: "PROTEÇÃO AOS DADOS PESSOAIS",
        children: [
          { text: "<b>Conformidade Legislativa</b>. O <b>CREDOR</b>, o Correspondente Bancário e eventuais novos credores, no que diz respeito ao tratamento dos Dados Pessoais do <b>EMITENTE</b> em virtude da assinatura desta <b>CCB</b>, comprometem-se, no limite das respectivas responsabilidades, a atua em conformidade com a legislação relativa à proteção de dados aplicável no Brasil, incluindo, mas não se limitando à Lei nº 13.709, de 14 de agosto de 2018 (“Lei Geral de Proteção de Dados Pessoais” ou “LGPD”), além das demais leis, regulamentos, decisões judiciais ou de autoridades administrativas, como também se comprometem a garantir que os respectivos empregados, agentes, prepostos, representantes legais, contratados, subcontratados, terceiros relacionados observem seus dispositivos." },
          { text: "Os termos utilizados nesta cláusula, iniciados em letra maiúscula que não sejam aqui definidos de outra forma, terão o significado que lhes é atribuído pela LGPD ou pela Autoridade Nacional de Proteção de Dados (“ANPD”)." },
          { text: "O <b>CREDOR</b> e o Correspondente Bancário declaram que adotam as medidas de segurança, técnica e administrativas aptas a proteger os Dados Pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito." },
          { text: "Os Dados Pessoais fornecidos pelo <b>EMITENTE</b> serão tratados pelo <b>CREDOR</b>, Correspondente Bancário e eventuais sucessores e cessionários para a execução de contrato (art. 7º, V, da LGPD), proteção ao crédito (art. 7º, X, da LGPD), incluindo-se na proteção ao crédito atividades inerentes à concessão de crédito, assim como atividades de apoio, oferecimento de produtos e serviços de crédito, bem como o gerenciamento de riscos dessas operações e para atendimento ao legítimo interesse do controlador ou de terceiro (art. 7º, IX, da LGPD), com fins de estudos de mercados e promoção e prestação de serviços que beneficiem o Titular dos Dados Pessoais. Adicionalmente, caso seja necessário, o <b>CREDOR</b>, Correspondente Bancário e eventuais sucessores e cessionários poderão tratar os Dados Pessoais para cumprir obrigações legais ou regulatórias (art. 7º, II, da LGPD) e exercer direitos do em processo judicial, administrativo ou arbitral (art. 7º, VI, da LGPD)." },
          { text: "O <b>CREDOR</b> poderá compartilhar os Dados Pessoais do <b>EMITENTE</b> com outros agentes de tratamento de dados, inclusive suas afiliadas, caso seja necessário para as finalidades listadas na presente <b>CCB</b>, observados os princípios e as garantias estabelecidas pela Lei nº 13.709, de 2018." },
        ]
      },
    ]);
    this.pageClauses.push([
      {
        children: [
          {
            text: "Nos termos da LGPD, o <b>EMITENTE</b> tem direito à:",
            children: [
              { text: "Confirmação de que é realizado o tratamento dos seus dados pessoais." },
              { text: "Acesso aos seus dados pessoais." },
              { text: "Correção de dados pessoais que estejam incompletos, inexatos ou desatualizados." },
              { text: "Anonimização, bloqueio ou eliminação de dados pessoais desnecessários, excessivos ou tratados em desconformidade com o disposto na LGPD." },
              { text: "Portabilidade dos seus dados pessoais a outro Fornecedor de serviço ou produto, observados os segredos comerciais e industriais, após a regulamentação pela Autoridade Nacional de Proteção de Dados, quando aplicável." },
              { text: "Eliminação dos dados pessoais tratados com base no seu consentimento, exceto nas hipóteses de conservação de dados pessoais previstas na LGPD." },
              { text: "Informação sobre com quem são compartilhados os dados pessoais." },
              { text: "Informação sobre a possibilidade de não fornecer o seu consentimento e as consequências." },
              { text: "Revogação do seu consentimento para o tratamento dos seus dados pessoais, quando os Dados Pessoais forem tratados com base no seu consentimento; e " },
              { text: "Oposição a tratamento que viole a LGPD." },
            ]
          },
          { text: "O <b>EMITENTE</b> poderá exercer os direitos vias os canais de atendimento indicados abaixo nesta <b>CCB</b>." },
          { text: "Após o término do tratamento, caso e conforme aplicável, o <b>CREDOR</b>, o Correspondente Bancário e eventuais novos credores, se comprometem a eliminar, corrigir, anonimizar, armazenar e/ou bloquear o acesso às informações que tiverem sido recebidas em decorrência da presente operação, salvo aquelas informações necessárias para o cumprimento de obrigação legal ou regulatória, ou para o exercício regular de direito." },
        ]
      },
      {
        text: "DISPOSIÇÕES FINAIS",
        children: [
          { text: "Sempre que for necessário, a apuração do saldo <b>EMITENTE</b> do <b>EMITENTE</b> será realizada pelo <b>CREDOR</b> mediante planilha de cálculo detalhada e atualizada." },
          { text: "A abstenção ou tolerância, por parte do <b>CREDOR</b>, de quaisquer direitos outorgados nesta CBB ou pela lei, ao cumprimento de obrigações pelo <b>EMITENTE</b>, não significará renúncia, perdão, novação ou alteração do que foi aqui pactuado." },
          { text: "O <b>EMITENTE</b> por este instrumento constituem-se mutuamente procuradores, com recíprocos e especiais poderes irrevogáveis, e irretratáveis, para receber citação inicial, em caso de eventual ação oriunda deste titulo, podendo, ainda, receber intimação de penhora, bem como de todos e quaisquer atos processuais que se tornem necessários para o normal andamento do processo." },
          { text: "Além do Imposto sobre Operações de Crédito, Câmbio e Seguro, e sobre Operações Relativas a Títulos e Valores Mobiliários (IOF) constante do item 3.2 no Quadro III e embutido nesta <b>CCB</b>, o <b>EMITENTE</b> pagará todos os outros impostos direta ou indiretamente incidentes sobre a operação aqui contemplada, e pelos quais o Emitente seja responsável, na qualidade de contribuinte, nos termos das leis brasileiras, assim como quaisquer custos resultantes de aumentos das alíquotas dos referidos impostos." },
          { text: "O <b>EMITENTE</b> se responsabiliza por taxas ou reembolso de despesas aqui previstos, em reais e livre de quaisquer impostos ou taxas incidentes, direta ou indiretamente, tais como PIS, COFINS e ISS. O <b>EMITENTE</b> compromete-se a pagar as quantias adicionais que sejam necessárias para que o <b>CREDOR</b> receba quantia equivalente à que teria sido recebida se tais deduções, recolhimentos ou pagamentos não fossem necessários." },
          { text: "A tolerância não implica perdão, renúncia, novação ou alteração da dívida ou das condições aqui previstas e o pagamento do principaL mesmo sem ressalvas, não será considerado ou presumido a quitação dos encargos. Dessa forma, as Partes acordam que qualquer prática diversa da aqui pactuada, mesmo que reiterada, não poderá ser interpretada como novação." },
        ]
      }
    ]);

    this.pageClauses.push([
      {
        children: [
          { text: "Na hipótese de ocorrer descumprimento de qualquer obrigação ou atraso no pagamento, o <b>CREDOR</b> ou a quem este vier a indicar poderá comunicar o fato a qualquer serviço de proteção ao crédito, como Serasa Experian ou qualquer outro órgão encarregado de cadastrar atraso nos pagamentos e o descumprimento de obrigações contratuais, informando o nome do <b>EMITENTE</b>." },
          { text: "O <b>CREDOR</b> poderá, a qualquer tempo, exigir reforço de garantias, ou substituição da mesma nos casos em que determinadas características do bem impeça o registro efetivo do ônus, ficando estipulado o prazo de 5 (cinco) dias úteis contados da data de sua solicitação, pelo <b>CREDOR</b>, por carta sob protocolo ou registro postal, a critério do <b>CREDOR</b>, para que o <b>EMITENTE</b> providencie o respectivo reforço ou substituição, sob pena do imediato vencimento da presente <b>CCB</b>, independentemente de interpelação judicial ou notificação judicial ou extrajudicial." },
          { text: "O <b>EMITENTE</b> autoriza e ratifica o <b>CREDOR</b> ou a quem este indicar, a qualquer tempo a:",
            children: [
              { text: "Efetuar consultas ao Sistema de Informações de Crédito – SCR – do Banco Central do Brasil (“SCR”), nos termos da Resolução nº 3.658, do Conselho Monetário Nacional, de 17.12.2008, conforme alterada e os serviços de proteção ao crédito SPC, Serasa e outras em que o <b>CREDOR</b> seja cadastrado." },
              { text: "Fornecer ao Banco Central do Brasil informações sobre esta <b>CCB</b>, para integrar o SCR." },
              { text: "Proceder conforme disposições que advierem de novas exigências/regulamentações feitas pelo Banco Central do Brasil ou autoridades. O <b>EMITENTE</b>, por meio desta <b>CCB</b>, autoriza e ratifica de forma irrevogável e irretratável o <b>CREDOR</b> a compartilhar informações obtidas a partir de consultas ao SCR a eventuais sucessores ou cessionários desta <b>CCB</b> e a membros de seu grupo econômico." },
            ]
          },
          { text: "Aplica-se à presente <b>CCB</b>, as disposições da Lei 10.931, de 02 de agosto de 2004, e posteriores alterações (“Lei 10.931”), declarando o <b>EMITENTE</b> ter conhecimento que a presente <b>CCB</b> é um titulo executivo extrajudicial e representa dívida em dinheiro, certa, líquida e exigível, seja pela soma nela indicada, seja pelo saldo <b>EMITENTE</b> demonstrado em planilha de cálculo no <b>ANEXO I</b> desta <b>CCB</b> ou nos extratos de conta-corrente, a serem emitidos consoante o que preceitua a aludida Lei 10.931." },
          {
            text: "O <b>EMITENTE</b> declara ter ciência que: ",
            children: [
              { text: "O <b>CREDOR</b> integra o Sistema Financeiro Nacional, submetendo-se à disciplina e regras pelo Conselho Monetário Nacional e Banco Central do Brasil." },
              { text: "As taxas de juros cobradas nas operações financeiras realizadas pelo <b>CREDOR</b>, incluindo a presente <b>CCB</b>, não estão submetidas ao limite de 12% (doze por cento) ao ano, sendo legítima a cobrança de juros e encargos superiores a esse percentual, mesmo em caso de endosso ou cessão do titulo para terceiros." },
            ]
          },
          { text: "Na hipótese de cessão ou transferência desta <b>CCB</b>, o seu novo titular ficará automaticamente subrogado em todos os deveres, direitos e garantias que cabiam originalmente ao <b>CREDOR</b>, independentemente de qualquer formalidade, passando a ter acesso livre e direto, por si ou por meio de mandatários devidamente constituídos, incluindo o próprio <b>CREDOR</b>, a todas as informações relacionadas à <b>CCB</b> e suas respectivas garantias, através de relatórios e/ou extratos de movimentação de conta corrente de titularidade do <b>EMITENTE</b>, reconhecendo a <b>EMITENTE</b> que o novo titular da <b>CCB</b> possui o inequívoco direito de acompanhar detidamente todo o andamento da <b>CCB</b> e suas respectivas garantias." },
          { text: "O <b>EMITENTE</b> poderá, desde que adimplente, liquidar antecipadamente, total ou parcialmente, suas obrigações decorrentes desta <b>CCB</b>, mediante requerimento enviado ao <b>CREDOR</b> com antecedência de 05 (cinco) dias." },
          { text: "Para mais informações sobre esta <b>CCB</b> e demais produtos contratados, o <b>CREDOR</b> poderá ser contatado por meio dos canais indicados no item 1.5 do <b>QUADRO I</b> acima, ou a outro que o <b>CREDOR</b> venha a indicar, os quais poderão ser alterados a qualquer momento sem necessidade de aviso prévio." },
        ]
      }
    ]);

    this.pageClauses.push([
      {
        children: [
          { text: "Assinatura Eletrônica: Em caso de contratação eletrônica, o <b>EMITENTE</b> ratifica que admite como válido, para fins de comprovação de autoria e integridade, a assinatura e informações constantes no presente documento, as quais foram capturadas de forma eletrônica e utilizadas nesta <b>CCB</b>, constituindo titulo executivo extrajudicial nos termos do artigo 28 da Lei nº 10.931 2004 e para todos os fins de direito, ainda que seja estabelecida com assinatura eletrônica ou certificação fora dos padrões ICP-BRASIL, conforme disposto pelo art. 1 da Medida Provisória nº 2.200/2001. As Partes declaram a veracidade de seus endereços eletrônicos e informações cadastrais constantesdo quadro- resumo, bem como autorizam o uso para todos os atos diretamente relacionados a este titulo, conforme a regulamentação aplicável. Ao assinarem por meio de assinaturas eletrônicas, as partes declaram a integridade, autenticidade e regularidade deste titulo." },
          { text: "Esta <b>CCB</b> é emitida em caráter irrevogável e irretratável, e obriga todos os seus signatários e seus eventuais, herdeiros, sucessores e cessionários a qualquer titulo, e somente poderá ser alterada, retificada e ratificada mediante termo de aditamento escrito e assinado pelas partes, que passará a integrar esta <b>CCB</b> ." },
          { text: "As Partes elegem o Foro da Comarca da Capital de São Paulo como o único competente para dirimir dúvidas e litigios decorrentes desta <b>CCB</b> , ficando ainda facultado à parte que se sentir prejudicada optar pelo Foro da Comarca da residência da parte infratora e/o de onde estiverem localizadas garantias e/ou bens da parte infratora." },
          { text: "A presente <b>CCB</b> é emitida e firmada em 1 (uma) via denominada “Via Negociável” e assinada eletronicamente." }
        ]
      }
    ]);
  }

  setQuadroCCB() {
    const dados = this.financingOption?.resposta?.Proposta?.Parcelas ?? [];
    dados.map((a: any) => {
      a['Data de vencimento'] = genericFormatter(a['DtVencimento'], 'date'),
      a['Amortização'] = genericFormatter(a['VlrAmortizacao'] ?? 0, 'money'),
      a['Juros'] = genericFormatter(a['VlrJuros'] ?? 0, 'money'),
      a['Valor Parcela'] = genericFormatter(a['VlrPrestacaoTotal'] ?? 0, 'money'),

      a['Saldo devedor'] = genericFormatter(a['VlrSaldoDevedor'] ?? 0, 'money');
    });

    this.ccbFlux = dados;
  }

  get countCcbFluxPages() {
    let pages = 1;
    if (this.ccbFlux.length > 12) {
      pages++;
      if (this.ccbFlux.length > 30) {
        pages++;
        if (this.ccbFlux.length > 48) {
          pages++;
          if (this.ccbFlux.length > 60) {
            pages++;
          }
        }
      }
    }
    return pages;
  }

  getFullEquipamentAddress(): string {
    let retorno = (this.formGroup?.controls['logradouroEquipamento']?.value ?? this.proposal?.endereco?.logradouro) ?? '';
    if (this.formGroup?.controls['numeroEquipamento']?.value || this.proposal?.endereco?.numero) {
      retorno += ', '+(this.formGroup?.controls['numeroEquipamento']?.value
        ?? this.proposal?.endereco?.numero);
    }
    if (this.formGroup?.controls['complementoEquipamento']?.value || this.proposal?.endereco?.complemento) {
      retorno += ', '+(this.formGroup?.controls['complementoEquipamento']?.value
        ?? this.proposal?.endereco?.complemento);
    }
    if (this.formGroup?.controls['bairroEquipamento']?.value || this.proposal?.endereco?.bairro) {
      retorno += ' - '+(this.formGroup?.controls['bairroEquipamento']?.value
        ?? this.proposal?.endereco?.bairro);
    }
    if (this.formGroup?.controls['cidadeEquipamento']?.value || this.proposal?.endereco?.cidade) {
      retorno += ', '+(this.formGroup?.controls['cidadeEquipamento']?.value
        ?? this.proposal?.endereco?.cidade); }
    if (this.formGroup?.controls['estadoEquipamento']?.value || this.proposal?.endereco?.estado) {
      retorno += ' - '+(this.stateList.find(e => e.id.toString() == (this.formGroup?.controls['estadoEquipamento']?.value
        ?? this.proposal?.endereco?.estadoId)?.toString()))?.sigla ?? ''; }
    if (this.formGroup?.controls['cepEquipamento']?.value || this.proposal?.endereco?.cep) {
      retorno += ', CEP: '+(this.formGroup?.controls['cepEquipamento']?.value
        ?? this.proposal?.endereco?.cep);
    }
    return retorno;
  }

  countCellNumber(table: any, row: number, column: number) {
    let numbersBefore = 0;
    if (row != 0) {
      for (let i = 0; i < row; i++) {
        numbersBefore += table.rows[i].length;
      }
    }
    numbersBefore += column + 1;
    return numbersBefore;
  }

  get getCurdate() {
    return genericFormatter(new Date(), 'date');
  }

  get getEmissionDate() {
    return this.formGroup?.controls['dataEmissaoPagamento'].value ?? this.emissionDate ?? '';
  }

  get hasSplit(): boolean {
    return this.proposal?.splitGuid != undefined
      && this.proposal?.splitGuid != null
      && this.proposal?.splitGuid != "";
  }

  genericFormatter(value: any, format: "date" | "money" | "decimal", decimalPoints = 0) {
    return genericFormatter(value, format, decimalPoints);
  }
}
