export const bacenBankList: { value: number, name: string }[] = [
  { value: 1, name: "1 - BANCO DO BRASIL S.A." },
  { value: 3, name: "3 - BANCO DA AMAZONIA S.A." },
  { value: 4, name: "4 - BANCO DO NORDESTE DO BRASIL S.A." },
  { value: 7, name: "7 - BANCO NACIONAL DE DESENVOLVIMENTO ECONÔMICO E SOCIAL – BNDES" },
  { value: 12, name: "12 - BANCO STANDARD DE INVESTIMENTOS S.A." },
  { value: 14, name: "14 - NATIXIS BRASIL S.A. BANCO MÚLTIPLO" },
  { value: 19, name: "19 - BANCO AZTECA DO BRASIL S.A." },
  { value: 21, name: "21 - BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO" },
  { value: 24, name: "24 - BANCO DE PERNAMBUCO S.A. - BANDEPE" },
  { value: 25, name: "25 - BANCO ALFA S.A." },
  { value: 29, name: "29 - BANCO BANERJ S.A." },
  { value: 31, name: "31 - BANCO BEG S.A." },
  { value: 33, name: "33 - BANCO SANTANDER (BRASIL) S.A." },
  { value: 36, name: "36 - BANCO BRADESCO BBI S.A." },
  { value: 37, name: "37 - BANCO DO ESTADO DO PARÁ S.A." },
  { value: 40, name: "40 - BANCO CARGILL S.A." },
  { value: 41, name: "41 - BANCO DO ESTADO DO RIO GRANDE DO SUL S.A." },
  { value: 44, name: "44 - BANCO BVA S.A." },
  { value: 45, name: "45 - BANCO OPPORTUNITY S.A." },
  { value: 47, name: "47 - BANCO DO ESTADO DE SERGIPE S.A." },
  { value: 51, name: "51 - BANCO DE DESENVOLVIMENTO DO ESPÍRITO SANTO S.A." },
  { value: 62, name: "62 - HIPERCARD BANCO MÚLTIPLO S.A." },
  { value: 63, name: "63 - BANCO IBI S.A. - BANCO MÚLTIPLO" },
  { value: 65, name: "65 - BANCO LEMON S.A." },
  { value: 66, name: "66 - BANCO MORGAN STANLEY S.A." },
  { value: 69, name: "69 - BPN BRASIL BANCO MÚLTIPLO S.A." },
  { value: 70, name: "70 - BRB - BANCO DE BRASILIA S.A." },
  { value: 72, name: "72 - BANCO RURAL MAIS S.A." },
  { value: 73, name: "73 - BB BANCO POPULAR DO BRASIL S.A." },
  { value: 74, name: "74 - BANCO J. SAFRA S.A." },
  { value: 75, name: "75 - BANCO CR2 S/A" },
  { value: 76, name: "76 - BANCO KDB DO BRASIL S.A." },
  { value: 77, name: "77 - BANCO INTERMEDIUM S/A" },
  { value: 78, name: "78 - BES INVESTIMENTO DO BRASIL S.A. - BANCO DE INVESTIMENTO" },
  { value: 79, name: "79 - JBS BANCO S/A" },
  { value: 80, name: "80 - B&T CC LTDA" },
  { value: 81, name: "81 - CONCÓRDIA BANCO S.A." },
  { value: 82, name: "82 - BANCO TOPÁZIO S.A." },
  { value: 83, name: "83 - BANCO DA CHINA BRASIL S.A" },
  { value: 94, name: "94 - BANCO FINAXIS S.A." },
  { value: 96, name: "96 - BANCO BM&F DE SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA S.A." },
  { value: 104, name: "104 - CAIXA ECONOMICA FEDERAL" },
  { value: 107, name: "107 - BANCO BBM S/A" },
  { value: 117, name: "117 - ADVANCED CC LTDA" },
  { value: 121, name: "121 - BANCO AGIBANK S.A." },
  { value: 122, name: "122 - BANCO BRADESCO BERJ S.A" },
  { value: 151, name: "151 - BANCO NOSSA CAIXA S.A." },
  { value: 172, name: "172 - ALBATROSS CCV S.A." },
  { value: 184, name: "184 - BANCO ITAÚ BBA S.A." },
  { value: 188, name: "188 - ATIVA INVESTIMENTOS S.A." },
  { value: 196, name: "196 - BANCO FAIR CORRETORA DE CÂMBIO S.A." },
  { value: 197, name: "197 - STONE PAGAMENTOS" },
  { value: 204, name: "204 - BANCO BRADESCO CARTÕES S.A." },
  { value: 208, name: "208 - BANCO UBS PACTUAL S.A." },
  { value: 212, name: "212 - BANCO MATONE S.A." },
  { value: 213, name: "213 - BANCO ARBI S.A." },
  { value: 214, name: "214 - BANCO DIBENS S.A." },
  { value: 215, name: "215 - E DE INVESTIMENTO SUDAMERIS S.A." },
  { value: 217, name: "217 - BANCO JOHN DEERE S.A." },
  { value: 218, name: "218 - BANCO BONSUCESSO S.A." },
  { value: 222, name: "222 - BANCO CALYON BRASIL S.A." },
  { value: 224, name: "224 - BANCO FIBRA S.A." },
  { value: 225, name: "225 - BANCO BRASCAN S.A." },
  { value: 229, name: "229 - BANCO CRUZEIRO DO SUL S.A." },
  { value: 230, name: "230 - UNICARD BANCO MÚLTIPLO S.A." },
  { value: 233, name: "233 - BANCO GE CAPITAL S.A." },
  { value: 237, name: "237 - BANCO BRADESCO S.A." },
  { value: 241, name: "241 - BANCO CLASSICO S.A." },
  { value: 243, name: "243 - BANCO MÁXIMA S.A." },
  { value: 246, name: "246 - BANCO ABC BRASIL S.A." },
  { value: 248, name: "248 - BANCO BOAVISTA INTERATLANTICO S.A." },
  { value: 249, name: "249 - BANCO INVESTCRED UNIBANCO S.A." },
  { value: 250, name: "250 - BANCO SCHAHIN S.A." },
  { value: 254, name: "254 - PARANÁ BANCO S.A." },
  { value: 260, name: "260 - NUBANK S.A." },
  { value: 263, name: "263 - BANCO CACIQUE S.A." },
  { value: 265, name: "265 - BANCO FATOR S.A." },
  { value: 266, name: "266 - BANCO CEDULA S.A." },
  { value: 290, name: "290 - PAGBANK" },
  { value: 300, name: "300 - BANCO DE LA NACION ARGENTINA" },
  { value: 318, name: "318 - BANCO BMG S.A." },
  { value: 320, name: "320 - BANCO INDUSTRIAL E COMERCIAL S.A." },
  { value: 323, name: "323 - MERCADO PAGO" },
  { value: 335, name: "335 - BANCO DIGIO S.A." },
  { value: 336, name: "336 - BANCO C6 S.A – C6 BANK" },
  { value: 340, name: "340 - SUPERDIGITAL" },
  { value: 341, name: "341 - BANCO ITAÚ S.A." },
  { value: 366, name: "366 - BANCO SOCIETE GENERALE BRASIL S.A." },
  { value: 368, name: "368 - BANCO CARREFOUR" },
  { value: 370, name: "370 - BANCO WESTLB DO BRASIL S.A." },
  { value: 376, name: "376 - BANCO J.P. MORGAN S.A." },
  { value: 380, name: "380 - PICPAY" },
  { value: 389, name: "389 - BANCO MERCANTIL DO BRASIL S.A." },
  { value: 394, name: "394 - BANCO FINASA BMC S.A." },
  { value: 399, name: "399 - HSBC BANK BRASIL S.A. - BANCO MULTIPLO" },
  { value: 409, name: "409 - UNIBANCO-UNIAO DE BANCOS BRASILEIROS S.A." },
  { value: 412, name: "412 - BANCO CAPITAL S.A." },
  { value: 422, name: "422 - BANCO SAFRA S.A." },
  { value: 453, name: "453 - BANCO RURAL S.A." },
  { value: 456, name: "456 - BANCO DE TOKYO-MITSUBISHI UFJ BRASIL S/A" },
  { value: 464, name: "464 - BANCO SUMITOMO MITSUI BRASILEIRO S.A." },
  { value: 473, name: "473 - BANCO CAIXA GERAL - BRASIL S.A." },
  { value: 477, name: "477 - CITIBANK N.A." },
  { value: 479, name: "479 - BANCO ITAUBANK S.A." },
  { value: 487, name: "487 - DEUTSCHE BANK S.A. - BANCO ALEMAO" },
  { value: 488, name: "488 - JPMORGAN CHASE BANK, NATIONAL ASSOCIATION" },
  { value: 492, name: "492 - ING BANK N.V." },
  { value: 494, name: "494 - BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY" },
  { value: 495, name: "495 - BANCO DE LA PROVINCIA DE BUENOS AIRES" },
  { value: 505, name: "505 - BANCO CREDIT SUISSE (BRASIL) S.A." },
  { value: 600, name: "600 - BANCO LUSO BRASILEIRO S.A." },
  { value: 604, name: "604 - BANCO INDUSTRIAL DO BRASIL S.A." },
  { value: 610, name: "610 - BANCO VR S.A." },
  { value: 611, name: "611 - BANCO PAULISTA S.A." },
  { value: 612, name: "612 - BANCO GUANABARA S.A." },
  { value: 613, name: "613 - BANCO PECUNIA S.A." },
  { value: 623, name: "623 - BANCO PANAMERICANO S.A." },
  { value: 626, name: "626 - BANCO FICSA S.A." },
  { value: 630, name: "630 - BANCO INTERCAP S.A." },
  { value: 633, name: "633 - BANCO RENDIMENTO S.A." },
  { value: 634, name: "634 - BANCO TRIANGULO S.A." },
  { value: 637, name: "637 - BANCO SOFISA S.A." },
  { value: 638, name: "638 - BANCO PROSPER S.A." },
  { value: 641, name: "641 - BANCO ALVORADA S.A." },
  { value: 643, name: "643 - BANCO PINE S.A." },
  { value: 652, name: "652 - ITAÚ UNIBANCO BANCO MÚLTIPLO S.A." },
  { value: 653, name: "653 - BANCO INDUSVAL S.A." },
  { value: 654, name: "654 - BANCO A.J. RENNER S.A." },
  { value: 655, name: "655 - BANCO VOTORANTIM S.A." },
  { value: 707, name: "707 - BANCO DAYCOVAL S.A." },
  { value: 719, name: "719 - BANIF - BANCO INTERNACIONAL DO FUNCHAL (BRASIL), S.A." },
  { value: 720, name: "720 - BANCO MAXINVEST S.A." },
  { value: 721, name: "721 - BANCO CREDIBEL S.A." },
  { value: 734, name: "734 - BANCO GERDAU S.A" },
  { value: 735, name: "735 - BANCO POTTENCIAL S.A." },
  { value: 738, name: "738 - BANCO MORADA S.A" },
  { value: 739, name: "739 - BANCO BGN S.A." },
  { value: 740, name: "740 - BANCO BARCLAYS S.A." },
  { value: 741, name: "741 - BANCO RIBEIRAO PRETO S.A." },
  { value: 743, name: "743 - BANCO SEMEAR S.A." },
  { value: 745, name: "745 - BANCO CITIBANK S.A." },
  { value: 746, name: "746 - BANCO MODAL S.A." },
  { value: 747, name: "747 - BANCO RABOBANK INTERNATIONAL BRASIL S.A." },
  { value: 748, name: "748 - BANCO COOPERATIVO SICREDI S.A." },
  { value: 749, name: "749 - BANCO SIMPLES S.A." },
  { value: 751, name: "751 - DRESDNER BANK BRASIL S.A. BANCO MULTIPLO" },
  { value: 752, name: "752 - BANCO BNP PARIBAS BRASIL S.A." },
  { value: 753, name: "753 - NBC BANK BRASIL S. A. - BANCO MÚLTIPLO" },
  { value: 756, name: "756 - BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB" },
  { value: 757, name: "757 - BANCO KEB DO BRASIL S.A." },
];
