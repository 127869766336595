<div class="clause" *ngFor="let clause of clauses; let i = index">
  <div class="subtitle pt-1 font-weight-500" *ngIf="clause.text">
    <span class="pe-3">
      {{ i + (startClause ?? 1) }}.
    </span>
    <span [innerHTML]="changeBold(clause.text)"></span>
  </div>
  <div class="subclause" *ngFor="let subclause of (clause.children ?? []); let j = index">
    <div class="pt-1" *ngIf="subclause.text">
      <span class="pe-4">
        {{ i + (startClause ?? 1) }}.
        {{ j + (i == 0 ? (startSubclause ?? 1) : 1) }}
      </span>
      <span [innerHTML]="changeBold(subclause.text)"></span>
    </div>
    <div class="subsubclause" *ngFor="let subsubclause of (subclause.children ?? []); let k = index">
      <div *ngIf="subsubclause.text">
        <span class="pe-4">
          {{ i + (startClause ?? 1) }}.
          {{ j + (i == 0 ? (startSubclause ?? 1) : 1) }}.
          {{ k + (j == 0 ? (startSubSubclause ?? 1) : 1) }}
        </span>
        <span [innerHTML]="changeBold(subsubclause.text)"></span>
      </div>
      <div class="subsubsubclause" *ngFor="let subsubsubclause of (subsubclause.children ?? []); let m = index">
        <div *ngIf="subsubsubclause">
          <span class="pe-3">
            {{ i + (startClause ?? 1) }}.
            {{ j + (i == 0 ? (startSubclause ?? 1) : 1) }}.
            {{ k + (j == 0 ? (startSubSubclause ?? 1) : 1) }}.
            {{ m + 1 }}
          </span>
          <span [innerHTML]="changeBold(subsubsubclause)"></span>
        </div>
      </div>
    </div>
  </div>
</div>
