import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, retry, tap } from "rxjs/operators";
import { Observable, ObservableInput, throwError } from "rxjs";
import { LoadingService } from "../shared/services/loading.service";
import { environment } from "src/environments/environment";
import { SessionService } from "../shared/services/session.service";
import { CacheService } from "../shared/services/cache.service";

@Injectable({ providedIn: 'root' })
export class HttpDataSource {
  constructor(
    private http: HttpClient,
    private loading: LoadingService,
    private cacheService: CacheService,
    private sessionService: SessionService,
  ) {}

  /**
   * Constructs a `GET` request that interprets the payload as a JSON object and returns
   * the response payload in a given type.
   *
   * @param url     The endpoint URL.
   * @param options The HTTP options to send with the request.
   *
   * @return An `Observable` of the `HTTPResponse`, with a response payload in the requested type.
  */
  get<T>({url, params, authenticate = false, options, retryCount, cache = false, cacheSeconds = 3,}: PayloadlessParameters): Observable<T> {
    try {
      options ??= { headers: {} };
      options.headers ??= {};
      if (authenticate) {
        options.headers = { 'Authorization': `Bearer ${this.sessionService.getToken()}` };
      }
      if (params) {
        options.params = params;
      }

      if (cache) {
        const cachedData = this.cacheService.get(url);
        if (cachedData) {
          return cachedData;
        }
      }
      return this.http.get<T>(environment.apiUrl+url, options).pipe(
        tap((data) => {
          if (cache) {
            this.cacheService.put(url, data, cacheSeconds * 1000);
          }
        })
      ).pipe(catchError(this.errorHandling),retry(retryCount ?? 3));
    } catch (error) {
      this.errorHandling(error);
      return new Observable<T>;
    }
  }

  /**
   * Constructs a `POST` request that interprets the payload as a JSON object
   * and returns an observable of the response.
   *
   * @param url The endpoint URL.
   * @param payload The content to replace with.
   * @param options HTTP options
   *
   * @return  An `Observable` of the `HTTPResponse` for the request, with a response payload in the
   *     requested type.
  */
  post<T>({url, params, payload, authenticate = false, options, retryCount, cache = false, cacheSeconds = 3}: PayloadnessParameters): Observable<T> {
    try {
      options ??= { headers: {} };
      options.headers ??= {};
      if (authenticate) {
        options.headers = { 'Authorization': `Bearer ${this.sessionService.getToken()}` };
      }
      if (params) {
        options.params = params;
      }

      if (cache) {
        const cachedData = this.cacheService.get(url);
        if (cachedData) {
          return cachedData;
        }
      }
      return this.http.post<T>(environment.apiUrl+url, payload, options).pipe(
        tap((data) => {
          if (cache) {
            this.cacheService.put(url, data, cacheSeconds * 1000);
          }
        })
      ).pipe(catchError(this.errorHandling),retry(retryCount ?? 3));
    } catch (error) {
      this.errorHandling(error);
      return new Observable<T>;
    }
  }

  /**
   * Constructs a `PUT` request that interprets the payload as an instance of the requested type
   * and returns an observable of the requested type.
   *
   * @param url The endpoint URL.
   * @param payload The resources to add/update.
   * @param options HTTP options
   *
   * @return An `Observable` of the requested type.
  */
  put<T>({url, params, payload, authenticate = false, options, retryCount}: PayloadnessParameters): Observable<T> {
    try {
      options ??= { headers: {} };
      options.headers ??= {};
      if (authenticate) {
        options.headers = { 'Authorization': `Bearer ${this.sessionService.getToken()}` };
      }
      if (params) {
        options.params = params;
      }

      return this.http.put<T>(environment.apiUrl+url, payload, options)
        .pipe(catchError(this.errorHandling),retry(retryCount ?? 3));
    } catch (error) {
      this.errorHandling(error);
      return new Observable<T>;
    }
  }

  /**
   * Constructs a DELETE request that interprets the payload as a JSON object and returns
   * the response in a given type.
   *
   * @param url     The endpoint URL.
   * @param options The HTTP options to send with the request.
   *
   * @return An `Observable` of the `HTTPResponse`, with response payload in the requested type.
  */
  delete<T>({url, params, authenticate = false, options, retryCount}: PayloadlessParameters): Observable<T> {
    try {
      options ??= { headers: {} };
      options.headers ??= {};
      if (authenticate) {
        options.headers = { 'Authorization': `Bearer ${this.sessionService.getToken()}` };
      }
      if (params) {
        options.params = params;
      }

      return this.http.delete<T>(environment.apiUrl+url, options)
        .pipe(catchError((err, caught) => this.errorHandling(err)),retry(retryCount ?? 3));
    } catch (error) {
      this.errorHandling(error);
      return new Observable<T>;
    }
  }

  private errorHandling(error: any): ObservableInput<any> {
    console.log('Erro');
    console.log(error);
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 || error.status === 403) {
            localStorage.clear();
            window.location.href = '/login';
            this.loading.showLoading(false);
            return throwError(() => new Error('Acesso negado'));
          }
        } else {
          console.log("================ Erro desconhecido ================");
          console.log(error);
        }
      }
    }
    return throwError(() => { return new CustomError(error.error, error); });
  }
}

enum HttpMethod {
  get, post, put, delete
}

interface PayloadlessParameters {
  url: string,
  authenticate?: boolean,
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  },
  options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  },
  retryCount?: number,
  cache?: boolean,
  cacheSeconds?: number,
}
interface PayloadnessParameters {
  url: string,
  payload: any | null,
  authenticate?: boolean,
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  },
  options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  },
  retryCount?: number,
  cache?: boolean,
  cacheSeconds?: number,
}

class CustomError {
  code: number;
  message: string;
  fullError: any;
  constructor(message: any, fullError: any) {
    if (typeof message == 'object') {
      if (message.hasOwnProperty('errors') && typeof message.errors == 'object') {
        message = Object.keys(message.errors).map(key => `${key}: ${message.errors[key]}`).join(', ');
      } else {
        message = Object.values(message).join(', ');
      }
    }
    this.message = message;
    this.fullError = fullError;
    this.code = fullError.status ?? 500;
  }

  public toString = () : string => {
    return this.message;
  }
}
