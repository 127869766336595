import { BureauType } from "../enums/bureau-type";
import { Deserializable } from "./deserializable";
import { State } from "./state.model";
import { User } from "./user.model";

export class CreditAnalisis implements Deserializable {
  uid?: string;
  usuario?: User;
  criadoEm?: string;
  resposta?: any;
  criadoPor?: string;
  requisição?: any;
  clienteGuid!: string;
  tipoBureau!: BureauType;
  possuiErro: boolean = false;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.resposta = JSON.parse(input.resposta ?? '');
    this.requisição = JSON.parse(input.requisição ?? '');
    return this;
  }

  toSave() {
    return this;
  }
}
