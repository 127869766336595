import { Deserializable } from "../deserializable";

export class SignDocumentRequest implements Deserializable {
  documentoCcb!: File;
  dacaoEmPagamento!: File;
  alienacaoFiduciaria!: File;
  propostaGuid!: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  toSave(): FormData {
    const formData: FormData = new FormData();
    formData.append("PropostaGuid", this.propostaGuid);

    if (this.documentoCcb) {
      formData.append("documentoCcb", this.documentoCcb);
    }
    if (this.dacaoEmPagamento) {
      formData.append("dacaoEmPagamento", this.dacaoEmPagamento);
    }
    if (this.alienacaoFiduciaria) {
      formData.append("alienacaoFiduciaria", this.alienacaoFiduciaria);
    }

    return formData;
  }
}
