<app-collapsable-form id="registerForm" title="Cadastro" [mainForm]="main" [startCollapsed]="!main" [disabled]="disabled" [formGroup]="formGroup">
  <div class="px-3 mx-auto my-3 w-90">
    <div class="d-flex">
      <div class="form-group mb-3 col-12">
        <label class="h3 mb-0" for="nome">Nome completo do cliente <span class="text-danger">{{hasErrors('nome', true) && '*'}}</span></label>
        <input matInput formControlName="nome" [class]="hasErrors('nome') + ' form-control'"
          placeholder="Escreva o nome aqui">
        <div class="invalid-feedback">{{inputErrors('nome')}}</div>
      </div>
    </div>
    <div class="d-flex">
      <div class="form-group mb-3 col-6 pe-3">
        <label class="h3 mb-0" for="dataNascimento">Data de nascimento <span class="text-danger">{{hasErrors('dataNascimento', true) && '*'}}</span></label>
        <input matInput formControlName="dataNascimento" type="date" max="9999-12-31" [class]="hasErrors('dataNascimento') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('dataNascimento')}}</div>
      </div>
      <div class="form-group mb-3 col-6">
        <label class="h3 mb-0" for="cpf">CPF do cliente <span class="text-danger">{{hasErrors('cpf', true) && '*'}}</span></label>
        <input matInput formControlName="cpf" mask="000.000.000-00" [class]="hasErrors('cpf') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('cpf')}}</div>
      </div>
    </div>
    <div class="d-flex">
      <div class="form-group mb-3 col-6 pe-3">
        <label class="h3 mb-0" for="valorFinanciarCliente">Valor total da instalação <span class="text-danger">{{hasErrors('valorFinanciarCliente', true) && '*'}}</span></label>
        <input matInput formControlName="valorFinanciarCliente" (change)="calculateFinancingTotal()" prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="." [class]="hasErrors('valorFinanciarCliente') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('valorFinanciarCliente')}}</div>
      </div>
      <div class="form-group mb-3 col-6">
        <label class="h3 mb-0" for="valorEntradaCliente">Valor da entrada <span class="text-danger">{{hasErrors('valorEntradaCliente', true) && '*'}}</span></label>
        <input matInput formControlName="valorEntradaCliente" (change)="calculateFinancingTotal()" prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="." [class]="hasErrors('valorEntradaCliente') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('valorEntradaCliente')}}</div>
      </div>
    </div>
    <div class="d-flex">
      <div class="form-group mb-3 col-6 pe-3">
        <label class="h3 mb-0" for="carenciaPretendidaCliente">Carência pretendida <span class="text-danger">{{hasErrors('carenciaPretendidaCliente', true) && '*'}}</span></label>
        <mat-select formControlName="carenciaPretendidaCliente"  [class]="hasErrors('carenciaPretendidaCliente') + ' form-control'">
          <mat-option *ngFor="let value of carenciaPretendidaOptions" [value]="value.value">
            {{value.name}}
          </mat-option>
        </mat-select>
        <div class="invalid-feedback">{{inputErrors('carenciaPretendidaCliente')}}</div>
      </div>
      <div class="form-group mb-3 col-6">
        <label class="h3 mb-0" for="financingTotal">Valor total do financiamento <span class="text-danger">{{hasErrors('financingTotal', true) && '*'}}</span></label>
        <input matInput formControlName="financingTotal" prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="." [class]="hasErrors('financingTotal') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('financingTotal')}}</div>
      </div>
    </div>
  </div>
  <app-collapsable-form id="registerFormComment" title="Comentário" [startCollapsed]="false">
    <div class="px-5 py-2">
      <app-proposal-comments [canComment]="canComment" [comments]="comments" (onChange)="this.comment = $event"></app-proposal-comments>
    </div>
  </app-collapsable-form>
  <div [class]="'d-flex pt-4 pb-3 px-3 ' + (edit ? 'justify-content-end' : (canEdit ? 'justify-content-start' : 'justify-content-end'))">
    <!-- <button [hidden]="!sessionService.isAdmin" class="btn btn-outline-secondary w-40 pe-2" (click)="devolve()" type="button">Devolver</button> -->
    <button [hidden]="!canEdit || edit" class="btn btn-outline-secondary w-40" (click)="startEdit()" type="submit">Editar</button>
    <!-- <button [hidden]="!edit" class="btn btn-danger w-40" (click)="cancelEdit()" type="submit">Cancelar</button> -->
    <button [hidden]="disabledForm" [disabled]="!formValid" class="btn btn-primary w-40" (click)="onSubmit()" type="submit">Concluir</button>
    <button [hidden]="!devolving" class="btn btn-primary w-40" (click)="commentAndReturn()" type="submit">Comentar</button>
  </div>
</app-collapsable-form>
