export enum BureauType {
  SERASA = 10,
  BACEN = 20,
  DATABUSCA = 30,
  GRIDPARCELAS = 40,
  CREATEPESSOA = 50,
  UPDATEENDERECO = 60,
  UPDATEBANCO = 70,
  INCLUIRPROPOSTA = 80,
  GARANTIA = 90,
  UPLOADCCB = 100,
  FINALIZACCB = 110,
  LIBERACCB = 120
}
