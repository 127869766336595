import { Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { genericFormatter } from 'src/app/core/helpers/masks';
import { BureauService } from 'src/app/services/bureau.service';
import { CommentsService } from 'src/app/services/comments.service';
import { ProposalsService } from 'src/app/services/proposals.service';
import { BureauType } from 'src/app/shared/enums/bureau-type';
import { FileType } from 'src/app/shared/enums/file-type';
import { ProposalStage } from 'src/app/shared/enums/proposal-stage';
import { SigningStatus } from 'src/app/shared/enums/signing-status';
import { Comment } from 'src/app/shared/models/comment.model';
import { CreditAnalisis } from 'src/app/shared/models/credit-analisis.model';
import { Document } from 'src/app/shared/models/document.model';
import { Proposal } from 'src/app/shared/models/proposal.model';
import { LiquidationRequest } from 'src/app/shared/models/requests/liquidation.request';
import { State } from 'src/app/shared/models/state.model';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'client-liquidation',
  templateUrl: './liquidation.component.html',
  styleUrls: ['./liquidation.component.scss']
})
export class LiquidationComponent implements OnChanges {
  FileType = FileType;
  SigningStatus = SigningStatus;

  @Input() proposal?: Proposal;
  @Input() main: boolean = false;
  @Input() disabled: boolean = false;
  @Input() comments?: Comment[];
  @Input() stateList: State[] = [];
  @Input() latestBureauProposal?: CreditAnalisis;
  @Input() fixedParameters: any;

  @Output() devolveStage = new EventEmitter();

  comment?: Comment;
  gridInstallments: any;
  protected financingOption?: CreditAnalisis;
  dueOptions: Options = { floor: 12, ceil: 72, noSwitching: true, pushRange: true,
    stepsArray: [12,24,36,48,60,72].map(e => { return { value: e }; }) };
  carencyOptions: Options = { floor: 30, ceil: 120, noSwitching: true, pushRange: true,
    stepsArray: [30,60,90,120].map(e => { return { value: e }; }) };

  docs: {
    ccb?: File | Document,
    alienacaoFiduciaria?: File | Document,
    dacaoEmPagamento?: File | Document,
  } = {};

  // protected loadingCCBDoc = true;
  // protected loadingAlienacao = true;
  // protected loadingDacao = true;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private loading: LoadingService,
    private service: ProposalsService,
    protected formBuilder: FormBuilder,
    private bureauService: BureauService,
    private commentService: CommentsService,
    protected sessionService: SessionService,
    private notification: NotificationService,
    private clipboardService: ClipboardService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    /// Set data
    if (this.proposal && changes['proposal']) {
      if (!changes['proposal'].previousValue?.uid) {
        this.setDocs();
        this.getGridInstallments();
      }
    }
  }

  get disabledForm(): boolean {
    return this.proposal?.propostaEtapa != ProposalStage.PROCESSODELIQUIDACAO
      || !this.sessionService.isAdmin;
  }
  get canComment(): boolean {
    return this.proposal?.propostaEtapa === ProposalStage.PROCESSODELIQUIDACAO && this.sessionService.isAdmin;
  }

  onSubmit() {
    if (!this.docs.ccb || !this.docs.alienacaoFiduciaria || !this.docs.dacaoEmPagamento) {
      this.notification.error('Insira todos os documentos para liquidar.');
      return;
    }

    let hasManualSigning = (this.docs.ccb instanceof File
      || this.docs.dacaoEmPagamento instanceof File
      || this.docs.alienacaoFiduciaria instanceof File);
    let payload: LiquidationRequest = new LiquidationRequest().deserialize({
      documentoCcb: this.docs.ccb instanceof File ? this.docs.ccb! : undefined,
      dacaoEmPagamento: this.docs.dacaoEmPagamento instanceof File ? this.docs.dacaoEmPagamento! : undefined,
      alienacaoFiduciaria: this.docs.alienacaoFiduciaria instanceof File ? this.docs.alienacaoFiduciaria! : undefined,
      propostaGuid: this.proposal!.uid!,
      assinarManual: hasManualSigning,
      assinaturaGuid: hasManualSigning ? undefined : (this.docs.ccb as Document).assinaturaGuid,
    });

    this.loading.showLoading(true);
    this.service.liquidation(this.proposal!.uid!, payload).subscribe({
      next: (res: any) => {
        if (res.sucesso == false) {
          this.notification.error('Erro ao enviar documento CCB');
        } else {
          if (this.comment) {
            this.comment = new Comment().deserialize(this.comment);
            this.comment.propostaEtapa = ProposalStage.PROCESSODELIQUIDACAO;
            this.comment.propostaNumero = this.proposal!.numero!;

            this.commentService.store(this.comment).subscribe();
          }
          this.router.navigateByUrl('/proposals');
        }
      },
      error: (err) => {
        this.notification.error('Erro ao fazer liquidação ' + err);
      },
      complete: () => {
        this.loading.showLoading(false);
      }
    })
  }

  devolve() {
    this.devolveStage.emit(ProposalStage.FORMALIZACAO)
  }

  getGridInstallments() {
    if (this.proposal?.cliente?.uid) {
      this.loading.showLoading(true);
      this.bureauService.getBureau(this.proposal!.cliente.uid, BureauType.GRIDPARCELAS).subscribe({
        next: (res) => {
          if (res.length > 0) {
            this.gridInstallments = {};
            let data = res.map(e => new CreditAnalisis().deserialize(e));

            this.carencyOptions.stepsArray?.forEach(carency => {
              let filterCarency = [this.proposal?.carenciaMinima ?? 30, this.proposal?.carenciaMaxima ?? 120];
              if (carency.value >= filterCarency[0] && carency.value <= filterCarency[1]) {
                let filter = data.filter(e => (e.requisição?.dto.NroDiasAcrescimo + 30) == carency.value);
                if (filter.length > 0) {
                  this.gridInstallments[carency.value] = {};
                  this.dueOptions.stepsArray?.forEach(due => {
                    let filterDue = [this.proposal?.parcelaMinima ?? 12, this.proposal?.parcelaMaxima ?? 72];
                    if (due.value >= filterDue[0] && due.value <= filterDue[1]) {
                      let dueFilter = filter.filter(e => e.requisição?.dto.Prazo == due.value);
                      if (dueFilter.length > 0) {
                        this.gridInstallments[carency.value][due.value]
                          = dueFilter[dueFilter.length - 1];
                      }
                    }
                  })
                }
              }
            });

            if (this.gridInstallments && this.proposal?.carenciaSelecionada && this.proposal?.parcelaSelecionada && this.gridInstallments[this.proposal.carenciaSelecionada][this.proposal.parcelaSelecionada]) {
              this.financingOption = this.gridInstallments[this.proposal!.carenciaSelecionada!][this.proposal!.parcelaSelecionada!];
            }
          }
        },
        error: (err) => {
          this.notification.error('Erro ao trazer tabela de parcelas: ' + err)
        },
        complete: () => {
          this.loading.showLoading(false);
        }
      });
    }
  }

  get canSubmit(): boolean {
    let ccbValid = (this.docs.ccb instanceof File
      || (this.docs.ccb instanceof Document
        && (this.docs.ccb.statusAssinatura == SigningStatus.ASSINADO
          || this.docs.ccb.statusAssinatura == SigningStatus.CONCLUIDO
          || this.docs.ccb.statusAssinatura == SigningStatus.APROVADO
        )
      )
    );
    let alienacaoValid = (this.docs.alienacaoFiduciaria instanceof File
      || (this.docs.alienacaoFiduciaria instanceof Document
        && (this.docs.alienacaoFiduciaria.statusAssinatura == SigningStatus.ASSINADO
          || this.docs.alienacaoFiduciaria.statusAssinatura == SigningStatus.CONCLUIDO
          || this.docs.alienacaoFiduciaria.statusAssinatura == SigningStatus.APROVADO
        )
      )
    );
    let dacaoValid = (this.docs.dacaoEmPagamento instanceof File
      || (this.docs.dacaoEmPagamento instanceof Document
        && (this.docs.dacaoEmPagamento.statusAssinatura == SigningStatus.ASSINADO
          || this.docs.dacaoEmPagamento.statusAssinatura == SigningStatus.CONCLUIDO
          || this.docs.dacaoEmPagamento.statusAssinatura == SigningStatus.APROVADO
        )
      )
    );

    return (ccbValid && alienacaoValid && dacaoValid);
  }

  uploadDoc(value: any, docType: 'ccb' | 'alienacaoFiduciaria' | 'dacaoEmPagamento') {
    if (value?.target?.files) {
      let files = value.target.files;
      if (files.length > 1) {
        this.docs[docType] = files;
      } else {
        let fileSize = (files[0] as File).size;
        let fileType = (files[0] as File).type;
        if (!fileType.includes('image/') && fileType != 'application/pdf') {
          this.notification.error('Formato do arquivo não suportado.');
        } else if ((fileSize / (1024 * 1024)) > 30) {
          this.notification.error('Tamanho do arquivo não suportado. (max: 30MB)');
        } else {
          this.docs[docType] = files[0];
        }
      }
    } else {
      this.docs[docType] = undefined;
    }
  }

  setDocs() {
    console.log('Aqui setDocs', this.getSavedDoc(FileType.DOCUMENTOCCB));
    this.docs['ccb'] = this.getSavedDoc(FileType.DOCUMENTOCCB);
    this.docs['alienacaoFiduciaria'] = this.getSavedDoc(FileType.ALIENACAOFIDUCIARIA);
    this.docs['dacaoEmPagamento'] = this.getSavedDoc(FileType.DACAOEMPAGAMENTO);
  }

  getDocLink(docType: 'ccb' | 'alienacaoFiduciaria' | 'dacaoEmPagamento'): SafeResourceUrl | undefined {
    if (this.docs[docType]) {
      if (this.docs[docType] instanceof File) {
        return undefined;
      } else if ((this.docs[docType]! as Document).caminhoArquivo) {
        return this.sanitizer.bypassSecurityTrustResourceUrl((this.docs[docType]! as Document).caminhoArquivo ?? '');
        // return 'https://cors-anywhere.herokuapp.com/' + (this.docs[docType]! as Document).caminhoArquivo;
      }
    }
    return undefined;
  }

  getDoc(docType: 'ccb' | 'alienacaoFiduciaria' | 'dacaoEmPagamento'): Document | undefined {
    if (this.docs[docType] && this.docs[docType] instanceof Document) {
      return this.docs[docType] as Document;
    }
    return undefined;
  }

  getFile(docType: 'ccb' | 'alienacaoFiduciaria' | 'dacaoEmPagamento'): File | undefined {
    if (this.docs[docType] && this.docs[docType] instanceof File) {
      return this.docs[docType] as File;
    }
    return undefined;
  }

  getDocLabel(docType: 'ccb' | 'alienacaoFiduciaria' | 'dacaoEmPagamento'): 'Assinado' | 'Aguardando assinatura' | 'Recusado' | 'Desconhecido' | undefined {
    let doc = this.getDoc(docType);
    if (doc) {
      return doc.statusAssinatura == SigningStatus.ASSINADO || doc.statusAssinatura == SigningStatus.CONCLUIDO
        ? 'Assinado'
        : doc.statusAssinatura == SigningStatus.AGUARDANDO_ASSINATURA
          ? 'Aguardando assinatura'
          : doc.statusAssinatura == SigningStatus.RECUSADO
            ? 'Recusado' : 'Desconhecido';
    }
    return undefined;
  }

  async copyDocLink(docType: 'ccb' | 'alienacaoFiduciaria' | 'dacaoEmPagamento') {
    let value = docType == 'ccb' ? FileType.DOCUMENTOCCB
      : docType == 'alienacaoFiduciaria' ? FileType.ALIENACAOFIDUCIARIA
        : FileType.DACAOEMPAGAMENTO;
    let doc = this.getSavedDoc(value);
    if (doc && doc instanceof Document) {
      if (doc.urlAssinatura) {
        this.clipboardService.copy(doc.urlAssinatura);
        this.notification.info('Link copiado para área de transferência.');
      } else if (doc.caminhoArquivo) {
        this.clipboardService.copy(doc.caminhoArquivo);
        this.notification.info('Link copiado para área de transferência.');
      }
    }
  }

  getSavedDoc(value: FileType): Document | undefined {
    let files = this.proposal?.cliente?.documentos.filter(e => e.tipoArquivo == value) ?? [];
    if (files && files.length > 0) {
      return files[files?.length - 1];
    } else {
      return undefined;
    }
  }

  printDocument(value: 'alienacao-fiduciaria' | 'dacao-em-pagamento' | 'ccb'): void {
    const div = document.getElementById("printable-"+value);
    if (div) {
      document.title = value;
      const conteudoDiv = div.innerHTML;
      const conteudoOriginal = document.body.innerHTML;

      document.body.innerHTML = conteudoDiv;
      window.print();
      document.body.innerHTML = conteudoOriginal;
      document.title = 'BSF Crédito'
      location.reload();
    }
  }

  genericFormatter(value: any, format: "date" | "money" | "decimal", decimalPoints = 0) {
    return genericFormatter(value, format, decimalPoints);
  }
}
