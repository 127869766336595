import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProposalStepperComponent } from './client-stepper.component';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
  ],
  declarations: [ProposalStepperComponent],
  exports: [ProposalStepperComponent],
})
export class ProposalStepperModule { }
