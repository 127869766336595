import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Proposal } from 'src/app/shared/models/proposal.model';
import { ProposalStage } from 'src/app/shared/enums/proposal-stage';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { ProposalsService } from 'src/app/services/proposals.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CommentsService } from 'src/app/services/comments.service';
import { Comment } from 'src/app/shared/models/comment.model';
import { SessionService } from 'src/app/shared/services/session.service';
import { StateService } from 'src/app/services/state.service';
import { State } from 'src/app/shared/models/state.model';
import { BureauType } from 'src/app/shared/enums/bureau-type';
import { BureauService } from 'src/app/services/bureau.service';
import { formatDate } from '@angular/common';
import { CreditAnalisis } from 'src/app/shared/models/credit-analisis.model';
import { ParameterService } from 'src/app/services/parameter.service';
import { SplitData } from 'src/app/shared/models/split-data.model';

@Component({
  selector: 'app-offer-proposals',
  templateUrl: './offer-proposals.component.html',
  styleUrls: ['./offer-proposals.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OfferProposalsComponent implements OnInit {
  ProposalStage = ProposalStage;

  proposalUid?;
  proposal?: Proposal;
  comments?: Comment[];
  stateList: State[] = [];
  fixedParameters: any = {};
  devolving?: ProposalStage;
  main: ProposalStage = ProposalStage.CADASTRO;
  bureauiIncludeProposals: CreditAnalisis[] = [];

  constructor(
    private router: Router,
    private loading: LoadingService,
    private service: ProposalsService,
    private stateService: StateService,
    private parameterService: ParameterService,
    protected formBuilder: FormBuilder,
    private bureauService: BureauService,
    private activatedRoute: ActivatedRoute,
    private commentService: CommentsService,
    protected sessionService: SessionService,
    private notification: NotificationService,
  ) {
    this.proposalUid = this.activatedRoute.snapshot.params['id'];
  }

  ngOnInit(): void {
    if (this.proposalUid) {
      this.loading.showLoading(true);
      this.getParameters();
      this.getComments();
    }
  }

  getStateList() {
    this.stateService.getAll().subscribe({
      next: (res) => {
        this.stateList = res.estados;
      },
      complete: () => {
        this.getProposalData();
      }
    })
  }

  getParameters() {
    this.parameterService.get('tac').subscribe({
      next: (res) => {
        this.fixedParameters = {
          tac: parseFloat(res.valor ?? '0')
        };
      },
      complete: () => {
        this.getStateList();
      }
    })
  }

  getComments() {
    this.commentService.getAll({ propostaGuid: this.proposalUid}).subscribe({
      next: (res) => {
        let data: any[] = res.map(e => new Comment().deserialize(e))
          .sort((a, b) => (a.criadoEm ?? '') < (b.criadoEm ?? '') ? 1 : -1);
        this.comments = data;
      },
      error: (err) => {
        this.notification.error('Erro ao trazer comentários: '+ err);
      },
    });
  }

  getProposalData() {
    this.service.get(this.proposalUid).subscribe({
      next: (res) => {
        this.proposal = new Proposal().deserialize(res);
        this.getMain();
        this.getIncludeProposal();
        if (this.proposal.splitGuid) {
          this.getSplitData();
        }
      },
      error: (err) => {
        if (err.code && err.code == 400) {
          this.notification.error('Cliente não encontrado.');
        } else {
          this.notification.error('Erro ao buscar cliente: '+ err);
        }
        this.router.navigateByUrl('/proposals');
      },
      complete: () => {
        this.loading.showLoading(false);
      }
    })
  }

  getSplitData() {
    this.service.splitData(this.proposal!.splitGuid!).subscribe({
      next: (res) => {
        this.proposal!.dadosSplit = new SplitData().deserialize(res);
      }
    })
  }

  getIncludeProposal() {
    if (this.proposal?.cliente?.uid) {
      this.loading.showLoading(true);
      this.bureauService.getBureau(this.proposal!.cliente.uid, BureauType.INCLUIRPROPOSTA).subscribe({
        next: (res) => {
          this.bureauiIncludeProposals = res ?? [];
        },
        error: (err) => {
          this.notification.error('Erro ao trazer inclusão da proposta: ' + err)
        },
        complete: () => {
          this.loading.showLoading(false);
        }
      });
    }
  }

  filtereComments(value: ProposalStage): Comment[] | undefined {
    let commentStages: ProposalStage[] = [];
    switch (value) {
      case ProposalStage.CADASTRO:
        commentStages.push(ProposalStage.CADASTRO);
      break;
      case ProposalStage.PREANALISEDECREDITO:
        commentStages.push(ProposalStage.PREANALISEDECREDITO);
      break;
      case ProposalStage.NEGOCIACAOCOMERCIAL:
        commentStages.push(ProposalStage.NEGOCIACAOCOMERCIAL);
      break;
      case ProposalStage.APROVACAODECREDITO:
        commentStages.push(ProposalStage.APROVACAODECREDITO);
      break;
      case ProposalStage.FORMALIZACAO:
        commentStages.push(ProposalStage.FORMALIZACAO);
      break;
      case ProposalStage.PROCESSODELIQUIDACAO:
        commentStages.push(ProposalStage.PROCESSODELIQUIDACAO);
      break;
    }
    return this.comments?.filter(e => commentStages.includes(e.propostaEtapa))
      .sort((a, b) => formatDate(a.criadoEm!, 'yyyy-MM-dd', 'pt-br') > formatDate(b.criadoEm!, 'yyyy-MM-dd', 'pt-br') ? 1 : -1);
  }

  isDisabled(value: ProposalStage) {
    let disabled = true;
    if (!this.proposal) {
      disabled = value !== ProposalStage.CADASTRO;
    } else {
      switch (value) {
        case ProposalStage.CADASTRO:
          disabled = false;
        break;
        case ProposalStage.PREANALISEDECREDITO:
        case ProposalStage.RECUSADOPREANALISE:
          if (this.proposal?.propostaEtapa != ProposalStage.CADASTRO) {
            if (this.proposal?.propostaEtapa == ProposalStage.PREANALISEDECREDITO) {
              disabled = !this.sessionService.isAdmin;
            } else {
              disabled = false;
            }
          }
        break;
        case ProposalStage.NEGOCIACAOCOMERCIAL:
        case ProposalStage.RECUSADONEGOCIACAO:
          if (this.proposal?.propostaEtapa != ProposalStage.CADASTRO
           && this.proposal?.propostaEtapa != ProposalStage.PREANALISEDECREDITO) {
              disabled = false;
             if (this.proposal?.propostaEtapa == ProposalStage.NEGOCIACAOCOMERCIAL) {
               disabled = this.sessionService.isAdmin;
             } else {
               disabled = false;
             }
           }
          break;
        case ProposalStage.APROVACAODECREDITO:
        case ProposalStage.RECUSADOAPROVACAO:
          if (this.proposal?.propostaEtapa != ProposalStage.CADASTRO
           && this.proposal?.propostaEtapa != ProposalStage.PREANALISEDECREDITO
           && this.proposal?.propostaEtapa != ProposalStage.NEGOCIACAOCOMERCIAL) {
              disabled = false;
             if (this.proposal?.propostaEtapa == ProposalStage.APROVACAODECREDITO) {
               disabled = !this.sessionService.isAdmin;
             } else {
               disabled = false;
             }
           }
          break;
        case ProposalStage.FORMALIZACAO:
          if (this.proposal?.propostaEtapa != ProposalStage.CADASTRO
           && this.proposal?.propostaEtapa != ProposalStage.PREANALISEDECREDITO
           && this.proposal?.propostaEtapa != ProposalStage.NEGOCIACAOCOMERCIAL
           && this.proposal?.propostaEtapa != ProposalStage.APROVACAODECREDITO) {
            disabled = false;
            if (this.proposal?.propostaEtapa == ProposalStage.FORMALIZACAO) {
              disabled = !this.sessionService.isAdmin;
            } else {
              disabled = false;
            }
          }
           break;
        case ProposalStage.PROCESSODELIQUIDACAO:
          if (this.proposal?.propostaEtapa == ProposalStage.PROCESSODELIQUIDACAO) {
            disabled = !this.sessionService.isAdmin;
          } else if (this.proposal?.propostaEtapa == ProposalStage.LIQUIDADO) {
            disabled = false;
          }
        break;
      }
    }
    return disabled;
  }

  isMain(value: ProposalStage): boolean {
    return this.main == value;
  }

  getMain() {
    if (!this.proposal) {
      this.main = ProposalStage.CADASTRO;
    } else {
      switch (this.proposal?.propostaEtapa) {
        case ProposalStage.PREANALISEDECREDITO:
        case ProposalStage.RECUSADOPREANALISE:
          if (this.sessionService.isAdmin) {
            this.main = ProposalStage.PREANALISEDECREDITO
          } else {
            this.main = ProposalStage.CADASTRO
          }
          break;
        case ProposalStage.NEGOCIACAOCOMERCIAL:
        case ProposalStage.RECUSADONEGOCIACAO:
          if (this.sessionService.isAdmin) {
            this.main = ProposalStage.PREANALISEDECREDITO
          } else {
            this.main = ProposalStage.NEGOCIACAOCOMERCIAL
          }
          break;
        case ProposalStage.APROVACAODECREDITO:
        case ProposalStage.RECUSADOAPROVACAO:
          if (this.sessionService.isAdmin) {
            this.main = ProposalStage.APROVACAODECREDITO
          } else {
            this.main = ProposalStage.NEGOCIACAOCOMERCIAL
          }
          break;
        case ProposalStage.FORMALIZACAO:
          if (this.sessionService.isAdmin) {
            this.main = ProposalStage.FORMALIZACAO
          } else {
            this.main = ProposalStage.APROVACAODECREDITO
          }
          break;
        case ProposalStage.PROCESSODELIQUIDACAO:
          if (this.sessionService.isAdmin) {
            this.main = ProposalStage.PROCESSODELIQUIDACAO
          } else {
            this.main = ProposalStage.FORMALIZACAO
          }
          break;
        default:
          this.main = this.proposal?.propostaEtapa;
          break;
      }
    }
  }

  devolveStage(previousStage: ProposalStage) {
    this.main = previousStage;
    this.devolving = previousStage;
  }

  get stageNumber() {
    let stage = this.proposal?.propostaEtapa ?? ProposalStage.CADASTRO;
    if (ProposalStage.isRefused(stage)) {
      return -1;
    }
    if (Object.values(ProposalStage).indexOf(stage) >= 0) {
      return Object.values(ProposalStage).indexOf(stage);
    } else {
      return 1;
    }
  }
}
