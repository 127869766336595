import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CepService {
  constructor(private http: HttpClient) {}

  buscar(cep: string){
    return this.http.get<Cep>(`https://viacep.com.br/ws/${cep}/json/`);
  }
}

interface Cep {
  cep?: string,
  logradouro?: string,
  complemento?: string,
  bairro?: string,
  localidade?: string,
  uf?: string,
  ibge?: string,
  gia?: string,
  ddd?: string,
  siafi?: string,
  erro?: boolean
}
