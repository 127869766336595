<app-collapsable-form id="liquidationForm" title="Liquidação" [mainForm]="main" [startCollapsed]="!main" [disabled]="disabled">
  <app-collapsable-form id="liquidationFormCCBUpload" [mainForm]="main" [startCollapsed]="!main" title="Status da assinatura dos documentos">
    <div class="pb-3">
      <div class="form-group col-6" *ngIf="!(docs['ccb'] || getDocLink('ccb'))">
        <label class="h3 mb-0" for="ccb">CCB <span class="text-danger">*</span></label>
        <input [hidden]="disabledForm" type="file" accept=".pdf" (change)="uploadDoc($event, 'ccb')" class="form-control">
      </div>
      <app-collapsable-form *ngIf="docs['ccb'] || getDocLink('ccb')"
        id="liquidationFormCCBUploadCCB"
        title="CCB"
        [styless]="true"
        [showCloseIcon]="false"
        [actionLinkLabel]="getDoc('ccb')?.nomeArquivo ?? getFile('ccb')?.name ?? ''"
        [actionLink]="getDocLink('ccb')"
        (onClickCloseIcon)="docs['ccb'] = undefined"
        [actionIcon]="getDoc('ccb') && 'content_copy'"
        (onClickActionIcon)="copyDocLink('ccb')"
        [actionButtonLabel]="getDocLabel('ccb')"
        [actionButtonClasses]="'badge badge-pill pe-none my-auto ' + (getDocLabel('ccb') == 'Assinado' ? 'border-success' : (getDocLabel('ccb') == 'Aguardando assinatura' ? 'border-secondary' : 'border-danger'))"
      >
        <!-- <ngx-extended-pdf-viewer id="file-ccb" [src]="getDocLink('ccb')!" [listenToURL]="true"
          [enablePrint]="false" *ngIf="getDocLink('ccb')"
          width="100%" height="1290px">
        </ngx-extended-pdf-viewer> -->
        <embed [src]="getDocLink('ccb')" type="application/pdf" width="100%" height="1290px" />
      </app-collapsable-form>
    </div>
    <div class="pb-3">
      <div class="form-group col-6" *ngIf="!(docs['alienacaoFiduciaria'] || getDocLink('alienacaoFiduciaria'))">
        <label class="h3 mb-0" for="alienacaoFiduciaria">Alienação fiduciária <span class="text-danger">*</span></label>
        <input [hidden]="disabledForm" type="file" accept=".pdf" (change)="uploadDoc($event, 'alienacaoFiduciaria')" class="form-control">
      </div>
      <app-collapsable-form *ngIf="docs['alienacaoFiduciaria'] || getDocLink('alienacaoFiduciaria')"
        id="liquidationFormCCBUploadAlienation"
        title="Alienação fiduciária"
        [styless]="true"
        [showCloseIcon]="false"
        [actionLinkLabel]="getDoc('alienacaoFiduciaria')?.nomeArquivo ?? getFile('alienacaoFiduciaria')?.name ?? ''"
        [actionLink]="getDocLink('alienacaoFiduciaria')"
        (onClickCloseIcon)="docs['alienacaoFiduciaria'] = undefined"
        [actionIcon]="getDoc('alienacaoFiduciaria') && 'content_copy'"
        (onClickActionIcon)="copyDocLink('alienacaoFiduciaria')"
        [actionButtonLabel]="getDocLabel('alienacaoFiduciaria')"
        [actionButtonClasses]="'badge badge-pill pe-none my-auto ' + (getDocLabel('alienacaoFiduciaria') == 'Assinado' ? 'border-success' : (getDocLabel('alienacaoFiduciaria') == 'Aguardando assinatura' ? 'border-secondary' : 'border-danger'))"
      >
        <!-- <ngx-extended-pdf-viewer id="file-alienacao-fiduciaria" [src]="getDocLink('alienacaoFiduciaria')!" [listenToURL]="true"
          [enablePrint]="false" *ngIf="getDocLink('alienacaoFiduciaria')"
          width="100%" height="1290px">
        </ngx-extended-pdf-viewer> -->
        <embed [src]="getDocLink('alienacaoFiduciaria')" type="application/pdf" width="100%" height="1290px" />
      </app-collapsable-form>
    </div>
    <div class="pb-3">
      <div class="form-group col-6" *ngIf="!(docs['dacaoEmPagamento'] || getDocLink('dacaoEmPagamento'))">
        <label class="h3 mb-0" for="dacaoEmPagamento">Dação em pagamento <span class="text-danger">*</span></label>
        <input [hidden]="disabledForm" type="file" accept=".pdf" (change)="uploadDoc($event, 'dacaoEmPagamento')" class="form-control">
      </div>
      <!-- [showCloseIcon]="!disabledForm" -->
      <app-collapsable-form *ngIf="docs['dacaoEmPagamento'] || getDocLink('dacaoEmPagamento')"
        id="liquidationFormCCBUploadDonation"
        title="Dação em pagamento"
        [styless]="true"
        [showCloseIcon]="false"
        [actionLinkLabel]="getDoc('dacaoEmPagamento')?.nomeArquivo ?? getFile('dacaoEmPagamento')?.name ?? ''"
        [actionLink]="getDocLink('dacaoEmPagamento')"
        (onClickCloseIcon)="docs['dacaoEmPagamento'] = undefined"
        [actionIcon]="getDoc('dacaoEmPagamento') && 'content_copy'"
        (onClickActionIcon)="copyDocLink('dacaoEmPagamento')"
        [actionButtonLabel]="getDocLabel('dacaoEmPagamento')"
        [actionButtonClasses]="'badge badge-pill pe-none my-auto ' + (getDocLabel('dacaoEmPagamento') == 'Assinado' ? 'border-success' : (getDocLabel('dacaoEmPagamento') == 'Aguardando assinatura' ? 'border-secondary' : 'border-danger'))"
      >
        <!-- <ngx-extended-pdf-viewer id="file-dacao-pagamento" [src]="getDocLink('dacaoEmPagamento')!" [listenToURL]="true"
          [enablePrint]="false" *ngIf="getDocLink('dacaoEmPagamento')"
          width="100%" height="1290px">
        </ngx-extended-pdf-viewer> -->
        <embed [src]="getDocLink('dacaoEmPagamento')" type="application/pdf" width="100%" height="1290px" />
      </app-collapsable-form>
    </div>
  </app-collapsable-form>

  <!-- <app-collapsable-form id="liquidationFormCCBDownload" [mainForm]="main" title="Download dos documentos nativos">
    <div>
      <app-collapsable-form id="liquidationFormCCBDownloadCCB"
        title="CCB"
        actionIcon="download"
        (onClickActionIcon)="printDocument('ccb')"
        [mainForm]="main"
        [styless]="true"
      >
      <app-doc-ccb [stateList]="stateList" [fixedParameters]="fixedParameters" [emissionDate]="genericFormatter(this.latestBureauProposal?.criadoEm, 'date')" [financingOption]="financingOption" [proposal]="proposal"></app-doc-ccb>
      </app-collapsable-form>
    </div>
    <div class="py-3">
      <app-collapsable-form id="liquidationFormCCBDownloadFiduciaryAlienation"
        title="Alienação fiduciária"
        actionIcon="download"
        (onClickActionIcon)="printDocument('alienacao-fiduciaria')"
        [mainForm]="main"
        [styless]="true"
      >
        <app-doc-alienacao-fiduciaria [fixedParameters]="fixedParameters" [emissionDate]="genericFormatter(this.latestBureauProposal?.criadoEm, 'date')" [stateList]="stateList" [financingOption]="financingOption" [proposal]="proposal"></app-doc-alienacao-fiduciaria>
      </app-collapsable-form>
    </div>
    <div>
      <app-collapsable-form id="liquidationFormCCBDownloadDonation"
        title="Dação em pagamento"
        actionIcon="download"
        (onClickActionIcon)="printDocument('dacao-em-pagamento')"
        [mainForm]="main"
        [styless]="true"
      >
        <app-doc-dacao-em-pagamento [fixedParameters]="fixedParameters" [emissionDate]="genericFormatter(this.latestBureauProposal?.criadoEm, 'date')" [stateList]="stateList" [financingOption]="financingOption" [proposal]="proposal"></app-doc-dacao-em-pagamento>
      </app-collapsable-form>
    </div>
  </app-collapsable-form> -->

  <app-collapsable-form id="liquidationFormComments" [mainForm]="main" title="Comentário">
    <app-proposal-comments [canComment]="canComment" [comments]="comments" (onChange)="this.comment = $event"></app-proposal-comments>
  </app-collapsable-form>
  <div class="d-flex justify-content-between pt-4 pb-3">
    <button [disabled]="disabledForm" class="btn btn-outline-secondary w-40 pe-2" (click)="devolve()" type="button">Devolver</button>
    <button [disabled]="disabledForm || !canSubmit" class="btn btn-primary w-40" (click)="onSubmit()" type="submit">Concluir</button>
  </div>
</app-collapsable-form>
