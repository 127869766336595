import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { hasErrors, inputErrors } from 'src/app/core/helpers/form-functions';
import { LoginService } from 'src/app/services/login.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  formGroup!: FormGroup;
  hide = true;

  constructor(
    private router: Router,
    private service: LoginService,
    private loading: LoadingService,
    private sessionService: SessionService,
    protected formBuilder: FormBuilder,
    private notification: NotificationService,
  ) {
    this.formGroup = formBuilder.group({
      username: ['', [Validators.compose([Validators.required])]],
      password: ['', Validators.compose([Validators.required])],
    });
  }

  get formValid(): any { return this.formGroup.valid; }
  hasErrors(control: string): string | null {
    return hasErrors(this.formGroup.controls[control]);
  }
  inputErrors(control: string): string {
    return inputErrors(this.formGroup.controls[control]);
  }

  onSubmit() {
    const payload = this.formGroup.value;

    this.loading.showLoading(true);
    this.service.login(payload).subscribe({
      next: (res) => {
        this.loading.showLoading(false);
        // this.setLanguage();
        this.sessionService.set(res);
        this.router.navigateByUrl('/proposals');
      },
      error: (err) => {
        this.loading.showLoading(false);
        this.notification.error('Erro ao efetuar login: '+err);
      }
    });
  }

  forgetPassword() {
    const payload = this.formGroup.value;
    if (!payload.username) {
      this.notification.error('Insira o username para realizar a redefinição da senha.');
      return;
    }

  }
}
