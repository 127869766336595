<div class="d-flex flex-column mt-5">
  <div class="card border-0 shadow-inner w-30 mt-5 py-4 text-center mx-auto">
    <div class="display-1 text-primary py-3">404</div>
  </div>
  <div>
    <div class="display-6 text-center text-primary mt-5 pt-3">Página não encontrada</div>
  </div>
  <div class="d-flex mx-auto w-30 justify-content-evenly mt-4">
    <button type="button" routerLink="/proposals" class="btn btn-primary px-4 py-2">Voltar</button>
    <button type="button" routerLink=".." class="btn btn-primary px-4 py-2">Início</button>
  </div>
</div>
