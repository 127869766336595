import { formatDate } from "@angular/common";

export function cpfMask(value: string): string {
  // retira os caracteres indesejados...
  value = value.replace(/[^\d]/g, "");

  // realizar a formatação...
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function cnpjMask(value: string): string {
  // retira os caracteres indesejados...
  value = value.replace(/[^\d]/g, "");

  // realizar a formatação...
  return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export function genericFormatter(value: any, format: "date" | "money" | "decimal", decimalPoints = 0) {
  if (format == 'date') {
    try {
      return formatDate(value, 'dd/MM/yyyy', 'pt-br');
    } catch (error) {
      return value;
    }
  } else if (format == 'money') {
    let formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
    return formatter.format(Number(value));
  } else {
    if (typeof value == 'number') {
      value = value.toFixed(decimalPoints);
    } else {
      value = Number(value.toString()).toFixed(decimalPoints);
    }
    return value.toString().replace('.', ',');
  }
}
