import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapsableFormComponent } from './collapsable-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    CollapsableFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    NgbCollapseModule,
  ],
  exports: [CollapsableFormComponent]
})
export class CollapsableFormModule { }
