import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Comment } from 'src/app/shared/models/comment.model';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-proposal-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent {
  @Input() comments?: Comment[];
  @Input() canComment?: boolean = true;
  attachmentFiles: File[] = [];

  @Output() onChange = new EventEmitter<Comment>();

  protected comment: Comment = new Comment();

  constructor(private notification: NotificationService) {}

  onAttachmentsChange(event: any) {
    let files = event.target.files;
    if ((files.length + this.attachmentFiles.length) > 10) {
      this.notification.error('Quantidade máxima de arquivos excedida. (max: 10)');
      return;
    }

    let totalFileSize = 0;
    this.attachmentFiles.forEach(file => {
      totalFileSize += file.size;
    })
    for (var i = 0; i < files.length; i++) {
      totalFileSize += files[i]?.size ?? 0;
    }
    if ((totalFileSize / (1024 * 1024)) > 180) {
      this.notification.error('Tamanho total dos arquivo excedido o permitido. (max: 180MB)');
      return;
    }

    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let fileSize = (file as File).size;
      let fileType = (file as File).type;
      totalFileSize += fileSize;
      if (!fileType.includes('image/') && fileType != 'application/pdf') {
        this.notification.error('Formato do arquivo não suportado.');
      } else if ((fileSize / (1024 * 1024)) > 30) {
        this.notification.error('Tamanho do arquivo não suportado. (max: 30MB)');
      } else {
        this.attachmentFiles.push(files[i]);
      }
    }
    this.comment.anexos = this.attachmentFiles;
    this.changeValue();
  }

  changeCommentText(event: any) {
    this.comment.descricao = event.target?.value;
    this.changeValue();
  }

  changeValue() {
    if (this.attachmentFiles.length > 0 || this.comment.descricao !== '') {
      this.onChange.emit(this.comment);
    } else {
      this.onChange.emit(undefined);
    }
  }
}
