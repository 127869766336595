<div [class]="{
  card: !styless,
  border: !styless,
  'd-flex': true,
  'flex-column': true,
  'collapsable-form': true,
  'border-primary': !styless,
  'middle': middle
}">
  <div [class]="{
    'd-flex': true,
    'justify-content-between': true,
    'border-bottom': !styless,
    'card-header': !styless,
    'border-primary': true
  }">
    <button
      type="button"
      [class]="{
        disabled,
        'col': true,
        'p-1': styless,
        'border-0': true,
        'bg-transparent': true
      }"
      (click)="collapse.toggle()"
      [disabled]="disabled"
      [attr.aria-expanded]="!isCollapsed"
    >
      <div [class]="{'d-flex': true, 'my-auto': true, mainForm }">
        <div class="h4 mb-0">{{ title }}</div>
        <mat-icon class="ps-2 text-primary">{{ isCollapsed ? 'arrow_right' : 'arrow_drop_down' }}</mat-icon>
      </div>
    </button>
    <div class="d-flex col-auto">
      <div *ngIf="actionLabel" class="actionLabel px-2 my-auto">{{ actionLabel }}</div>
      <a *ngIf="actionLink" class="my-auto mx-2" [href]="actionLink" target="_blank">{{ actionLinkLabel ?? actionLink }}</a>
      <mat-icon *ngIf="actionIcon" (click)="clickIcon()" class="my-auto mx-2 pointer">{{ actionIcon }}</mat-icon>
      <button *ngIf="actionButtonLabel" (click)="clickAction()" [class]="actionButtonClasses ?? 'btn btn-primary'" type="button">{{ actionButtonLabel }}</button>
      <mat-icon *ngIf="showCloseIcon" (click)="clickClose()" class="my-auto me-2 pointer text-danger">close</mat-icon>
    </div>
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="w-45 py-4 mx-auto">
    <ng-template #content><ng-content></ng-content></ng-template>
    <div *ngIf="formGroup">
      <form [formGroup]="formGroup">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </form>
    </div>
    <div *ngIf="!formGroup">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
</div>
