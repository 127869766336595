import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-collapsable-form',
  templateUrl: './collapsable-form.component.html',
  styleUrls: ['./collapsable-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CollapsableFormComponent implements OnInit, OnChanges {
  @Input() title!: string;
  @Input() middle: boolean = false;
  @Input() styless: boolean = false;
  @Input() disabled: boolean = false;
  @Input() mainForm: boolean = false;
  @Input() formGroup?: FormGroup;
  @Input() startCollapsed: boolean = true;
  @Input() actionIcon?: string;
  @Input() actionLabel?: string;
  @Input() actionButtonLabel?: string;
  @Input() actionButtonClasses?: string;
  @Input() showCloseIcon?: boolean = false;
  @Input() actionLinkLabel?: string;
  @Input() actionLink?: string | SafeResourceUrl;

  @Output() onSubmit = new EventEmitter();
  @Output() onClickCloseIcon = new EventEmitter();
  @Output() onClickActionIcon = new EventEmitter();
  @Output() onClickActionButton = new EventEmitter();

  protected isCollapsed: boolean = true;

  ngOnInit(): void {
    this.isCollapsed = this.startCollapsed;
  }

  ngOnChanges(): void {
    this.isCollapsed = this.startCollapsed;
  }

  clickIcon() {
    this.onClickActionIcon.emit();
  }
  clickAction() {
    this.onClickActionButton.emit();
  }
  clickClose() {
    this.onClickCloseIcon.emit();
  }
}
