import { FileType } from "../enums/file-type";
import { SigningStatus } from "../enums/signing-status";
import { Deserializable } from "./deserializable";

export class Document implements Deserializable {
  uid?: string;
  nomeArquivo?: string;
  caminhoArquivo?: string;
  tipoArquivo?: FileType;
  statusAssinatura?: SigningStatus;
  urlAssinatura?: string;
  assinaturaGuid?: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  toSave() {
    return this;
  }
}
