<app-collapsable-form id="creditApprovalForm" title="Aprovação de crédito" [middle]="true" [mainForm]="main" [startCollapsed]="!main" [disabled]="disabled" [formGroup]="formGroup">
  <!-- <app-collapsable-form *ngIf="sessionService.isAdmin" id="creditApprovalFormBureaus" [mainForm]="main" [startCollapsed]="!main || devolving" title="Bureaus de crédito">
    <app-collapsable-form id="creditApprovalFormBureausSerasa" [styless]="true" title="Serasa"></app-collapsable-form>
    <app-collapsable-form id="creditApprovalFormBureausBacen" [styless]="true" title="Bacen"></app-collapsable-form>
    <app-collapsable-form id="creditApprovalFormBureausDatabusca" [styless]="true" title="Databusca"></app-collapsable-form>
  </app-collapsable-form> -->
  <app-collapsable-form id="creditApprovalFormParams" [mainForm]="main" [startCollapsed]="!main || devolving" title="Parâmetros da oferta">
    <div class="d-flex w-70 mx-auto">
      <div class="d-flex">
        <div class="h3 title pe-2 my-auto">Valor total:</div>
        <div class="w-55">
          <input matInput formControlName="valorTotal"
            prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="."
            class="form-control">
        </div>
      </div>
      <div class="d-flex">
        <div class="h3 title pe-2 my-auto">Valor entrada:</div>
        <div class="w-55">
          <input matInput formControlName="valorEntrada"
            prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="."
            class="form-control me-auto">
        </div>
      </div>
    </div>
    <div class="d-flex flex-column w-55 mx-auto pt-4">
      <div class="h3 title mb-0">Taxa:</div>
      <div class="mx-auto w-95">
        <ngx-slider formControlName="taxaDeJurosSlider" [options]="taxOptions"></ngx-slider>
      </div>
    </div>
    <div class="d-flex flex-column w-55 mx-auto pt-2">
      <div class="h3 title mb-0">Prazo:</div>
      <div class="mx-auto w-95">
        <ngx-slider formControlName="prazoSlider" [options]="dueOptions"></ngx-slider>
      </div>
    </div>
    <div class="d-flex flex-column w-55 mx-auto pt-2">
      <div class="h3 title mb-0">Carência:</div>
      <div class="mx-auto w-95">
        <ngx-slider formControlName="carenciaSlider" [options]="carencyOptions"></ngx-slider>
      </div>
    </div>
  </app-collapsable-form>
  <app-collapsable-form id="creditApprovalFormOffer" [mainForm]="main" [startCollapsed]="!main || devolving" title="Oferta">
    <app-grid-offer [gridInstallments]="gridInstallments" [selected]="financingOption"></app-grid-offer>
    <div class="d-flex justify-content-between pt-4 pb-3 px-3">
      <button [hidden]="disabledForm" [class]="'btn w-40 pe-2 ' + (propostaEtapa == ProposalStage.RECUSADOAPROVACAO ? 'btn-secondary' : 'btn-outline-secondary')" (click)="onRefused()" type="button">Recusar</button>
      <button [hidden]="disabledForm" [class]="'btn w-40 ' + (propostaEtapa == ProposalStage.RECUSADOAPROVACAO ? 'btn-outline-primary' : 'btn-primary')" (click)="onApproved()" type="submit">Aprovar</button>
    </div>
  </app-collapsable-form>
  <app-collapsable-form id="creditApprovalFormAdditionalData" title="Informações complementares" [mainForm]="main" [startCollapsed]="!main || devolving">
    <div class="h2 title my-3">Dados pessoais</div>
    <div>
      <div class="d-flex pb-3">
        <div class="form-group col-3 pe-3">
          <label class="h3 mb-0" for="nome">Nome completo do cliente <span class="text-danger">{{hasErrors('nome', true) && '*'}}</span></label>
          <input matInput [value]="proposal?.cliente?.ultimoContato?.nome ?? ''" disabled class="form-control">
        </div>
        <div class="form-group col-3 pe-3">
          <label class="h3 mb-0" for="cpf">CPF do cliente <span class="text-danger">{{hasErrors('cpf', true) && '*'}}</span></label>
          <input matInput [value]="proposal?.cliente?.documentoFormatado ?? ''" disabled class="form-control">
        </div>
        <div class="form-group col-3 pe-3">
          <label class="h3 mb-0" for="nascimento">Data de nascimento <span class="text-danger">{{hasErrors('nascimento', true) && '*'}}</span></label>
          <input matInput [value]="formatDate(proposal?.cliente?.nascimento ?? '')" disabled class="form-control">
        </div>
        <div class="form-group col-3">
          <label class="h3 mb-0" for="estadoCivil">Estado civíl <span class="text-danger">{{hasErrors('estadoCivil', true) && '*'}}</span></label>
          <mat-select formControlName="estadoCivil"  class="form-control">
            <mat-option *ngFor="let value of estadoCivilOptions" [value]="value.value">
              {{value.name}}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="d-flex pb-3">
        <div class="form-group col-2 pe-3">
          <label class="h3 mb-0" for="documento">Nº de RG ou CNH <span class="text-danger">{{hasErrors('documento', true) && '*'}}</span></label>
          <input matInput formControlName="documento" class="form-control">
        </div>
        <div class="form-group col-3 pe-3">
          <label class="h3 mb-0" for="tipoDocumentoIdentificacao">Tipo de documento <span class="text-danger">{{hasErrors('tipoDocumentoIdentificacao', true) && '*'}}</span></label>
          <mat-select formControlName="tipoDocumentoIdentificacao"  class="form-control">
            <mat-option *ngFor="let value of tipoDocumentoIdentificacaoOptions" [value]="value.value">
              {{value.name}}
            </mat-option>
          </mat-select>
        </div>
        <div class="form-group col-3 pe-3">
          <label class="h3 mb-0" for="dataEmissao">Data da emissão <span class="text-danger">{{hasErrors('dataEmissao', true) && '*'}}</span></label>
          <input matInput formControlName="dataEmissao" type="date" max="9999-12-31" class="form-control">
        </div>
        <div class="form-group col-2 pe-3">
          <label class="h3 mb-0" for="orgaoExpedidor">Órgão expeditor <span class="text-danger">{{hasErrors('orgaoExpedidor', true) && '*'}}</span></label>
          <input matInput formControlName="orgaoExpedidor" class="form-control">
        </div>
        <div class="form-group col-2 pe-3">
          <label class="h3 mb-0" for="ufrg">Emissor/UF <span class="text-danger">{{hasErrors('ufrg', true) && '*'}}</span></label>
          <mat-select formControlName="ufrg" class="form-control">
            <mat-option *ngFor="let value of stateList" [value]="value.id">
              {{ value.nome }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="d-flex pb-3">
        <div class="form-group col-5 pe-3">
          <label class="h3 mb-0" for="documentoPessoal">Comprovante de documento <span class="text-danger">{{hasErrors('documentoPessoal', true) && '*'}}</span></label>
          <div *ngIf="getDoc(FileType.COMPROVANTEDOCUMENTOPESSOAL)">
            <a class="d-flex" [href]="getDoc(FileType.COMPROVANTEDOCUMENTOPESSOAL)?.caminhoArquivo" target="_blank">
              <mat-icon class="text-primary pointer d-flex mt-1 ms-2">download</mat-icon>
              <span class="ps-2 my-auto">{{getDoc(FileType.COMPROVANTEDOCUMENTOPESSOAL)?.nomeArquivo}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="h2 title my-3">Dados de contato</div>
    <div>
      <div class="d-flex pb-3">
        <div class="form-group col-4 pe-3">
          <label class="h3 mb-0" for="celular">Celular <span class="text-danger">{{hasErrors('celular', true) && '*'}}</span></label>
          <input matInput formControlName="celular" mask="(00) 0 0000-0000" class="form-control">
        </div>
        <div class="form-group col-4 pe-3">
          <label class="h3 mb-0" for="telefone">Telefone <span class="text-danger">{{hasErrors('telefone', true) && '*'}}</span></label>
          <input matInput formControlName="telefone" mask="(00) 0000-0000" class="form-control">
        </div>
        <div class="form-group col-4">
          <label class="h3 mb-0" for="email">Email <span class="text-danger">{{hasErrors('email', true) && '*'}}</span></label>
          <input matInput formControlName="email" type="email" class="form-control">
        </div>
      </div>
    </div>
    <div class="col h2 title my-3">Dados do endereço</div>
    <div>
      <div class="d-flex pb-3">
        <div class="form-group col-3 pe-3">
          <label class="h3 mb-0" for="cep">CEP <span class="text-danger">{{hasErrors('cep', true) && '*'}}</span></label>
          <input matInput formControlName="cep" mask="00000-000" class="form-control">
        </div>
        <div class="form-group col-3 pe-3">
          <label class="h3 mb-0" for="numero">Número <span class="text-danger">{{hasErrors('numero', true) && '*'}}</span></label>
          <input matInput formControlName="numero" mask="9999" class="form-control">
        </div>
        <div class="form-group col-6">
          <label class="h3 mb-0" for="complemento">Complemento <span class="text-danger">{{hasErrors('complemento', true) && '*'}}</span></label>
          <input matInput formControlName="complemento" class="form-control">
        </div>
      </div>
      <div class="d-flex pb-3">
        <div class="form-group col-5 pe-3">
          <label class="h3 mb-0" for="logradouro">Logradouro <span class="text-danger">{{hasErrors('logradouro', true) && '*'}}</span></label>
          <input matInput formControlName="logradouro" class="form-control">
        </div>
        <div class="form-group col-4 pe-3">
          <label class="h3 mb-0" for="bairro">Bairro <span class="text-danger">{{hasErrors('bairro', true) && '*'}}</span></label>
          <input matInput formControlName="bairro" class="form-control">
        </div>
        <div class="form-group col-3">
          <label class="h3 mb-0" for="cidade">Cidade <span class="text-danger">{{hasErrors('cidade', true) && '*'}}</span></label>
          <input matInput formControlName="cidade" class="form-control">
        </div>
      </div>
      <div class="d-flex pb-3">
        <div class="form-group col-4 pe-3">
          <label class="h3 mb-0" for="estado">Estado <span class="text-danger">{{hasErrors('estado', true) && '*'}}</span></label>
          <mat-select formControlName="estado" class="form-control">
            <mat-option *ngFor="let value of stateList" [value]="value.id">
              {{ value.nome }}
            </mat-option>
          </mat-select>
        </div>
        <div class="form-group col-4 ps-3">
          <label class="h3 mb-0" for="comprovanteResidencia">Comprovante de residência <span class="text-danger">{{hasErrors('comprovanteResidencia', true) && '*'}}</span></label>
          <div *ngIf="getDoc(FileType.COMPROVANTEENDERECO)">
            <a class="d-flex" [href]="getDoc(FileType.COMPROVANTEENDERECO)?.caminhoArquivo" target="_blank">
              <mat-icon class="text-primary pointer d-flex mt-1 ms-2">download</mat-icon>
              <span class="ps-2 my-auto">{{getDoc(FileType.COMPROVANTEENDERECO)?.nomeArquivo}}</span>
            </a>
          </div>
        </div>
        <div class="form-group col-4 ps-3 my-auto">
          <a class="d-flex no-style" [href]="'http://maps.google.com/maps?q=' + getFullAddress(proposal?.cliente?.ultimoEndereco ?? undefined)" target="_blank">
            <mat-icon class="my-auto ms-2">place</mat-icon>
            <span class="ps-2 my-auto">Visualizar localização</span>
          </a>
        </div>
      </div>
    </div>
    <div class="h2 title my-3">Endereço de instalação</div>
    <div>
      <div class="d-flex pb-3">
          <div class="form-group col-3 pe-3">
            <label class="h3 mb-0" for="cepEquipamento">CEP <span class="text-danger">*</span></label>
            <input matInput formControlName="cepEquipamento" mask="00000-000" class="form-control">
          </div>
          <div class="form-group col-3 pe-3">
            <label class="h3 mb-0" for="numeroEquipamento">Número <span class="text-danger">*</span></label>
            <input matInput formControlName="numeroEquipamento" mask="9999" class="form-control">
          </div>
          <div class="form-group col-6">
            <label class="h3 mb-0" for="complementoEquipamento">Complemento</label>
            <input matInput formControlName="complementoEquipamento" class="form-control">
          </div>
      </div>
      <div class="d-flex pb-3">
        <div class="form-group col-5 pe-3">
          <label class="h3 mb-0" for="logradouroEquipamento">Logradouro <span class="text-danger">*</span></label>
          <input matInput formControlName="logradouroEquipamento" class="form-control">
        </div>
        <div class="form-group col-4 pe-3">
          <label class="h3 mb-0" for="bairroEquipamento">Bairro <span class="text-danger">*</span></label>
          <input matInput formControlName="bairroEquipamento" class="form-control">
        </div>
        <div class="form-group col-3">
          <label class="h3 mb-0" for="cidadeEquipamento">Cidade <span class="text-danger">*</span></label>
          <input matInput formControlName="cidadeEquipamento" class="form-control">
        </div>
      </div>
      <div class="d-flex pb-3">
        <div class="form-group col-3 pe-3">
          <label class="h3 mb-0" for="estadoEquipamento">Estado <span class="text-danger">*</span></label>
          <mat-select formControlName="estadoEquipamento" class="form-control">
            <mat-option *ngFor="let value of stateList" [value]="value.id">
              {{ value.nome }}
            </mat-option>
          </mat-select>
        </div>
        <div class="form-group col mt-auto ps-3">
          <label class="h3 mb-0" for="comprovanteResidenciaEquipamento">Comprovante de endereço do equipamento <span class="text-danger">*</span> <span class="text-danger">{{hasErrors('comprovanteResidenciaEquipamento', true) && '*'}}</span></label>
          <div *ngIf="getDoc(FileType.ENDERECOEQUIPAMENTO)">
            <a class="d-flex" [href]="getDoc(FileType.ENDERECOEQUIPAMENTO)?.caminhoArquivo" target="_blank">
              <mat-icon class="text-primary pointer d-flex mt-1 ms-2">download</mat-icon>
              <span class="ps-2 my-auto">{{getDoc(FileType.ENDERECOEQUIPAMENTO)?.nomeArquivo}}</span>
            </a>
          </div>
        </div>
        <div class="form-group col-4 my-auto ps-3">
          <a class="d-flex no-style" [href]="'http://maps.google.com/maps?q=' + getFullAddress(proposal?.endereco)" target="_blank">
            <mat-icon class="my-auto ms-2">place</mat-icon>
            <span class="ps-2 my-auto">Visualizar localização</span>
          </a>
        </div>
      </div>
    </div>
    <div class="h2 title my-3">Conta de luz</div>
    <div>
      <div class="d-flex pb-3">
        <div class="form-group col-3 pe-3">
          <label class="h3 mb-0" for="valorContaLuz">Valor da conta de luz <span class="text-danger">{{hasErrors('valorContaLuz', true) && '*'}}</span></label>
          <input matInput formControlName="valorContaLuz" prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="." class="form-control">
        </div>
        <div class="form-group col-5">
          <label class="h3 mb-0" for="comprovanteContaDeLuz">Comprovante de conta de luz <span class="text-danger">{{hasErrors('comprovanteContaDeLuz', true) && '*'}}</span></label>
          <div *ngIf="getDoc(FileType.COMPROVANTELUZ)">
            <a class="d-flex" [href]="getDoc(FileType.COMPROVANTELUZ)?.caminhoArquivo" target="_blank">
              <mat-icon class="text-primary pointer d-flex mt-1 ms-2">download</mat-icon>
              <span class="ps-2 my-auto">{{getDoc(FileType.COMPROVANTELUZ)?.nomeArquivo}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="h2 title my-3">Renda</div>
    <div>
      <div class="d-flex pb-3">
        <div class="form-group col-3 pe-3">
          <label class="h3 mb-0" for="rendaIndividual">Renda individual <span class="text-danger">{{hasErrors('rendaIndividual', true) && '*'}}</span></label>
          <input matInput formControlName="rendaIndividual" prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="." class="form-control">
        </div>
        <div class="form-group col-3 pe-3">
          <label class="h3 mb-0" for="rendaFamiliar">Renda familiar <span class="text-danger">{{hasErrors('rendaFamiliar', true) && '*'}}</span></label>
          <input matInput formControlName="rendaFamiliar" prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="." class="form-control">
        </div>
        <div class="form-group col-6">
          <label class="h3 mb-0" for="comprovanteRenda">Comprovante de renda <span class="text-danger">{{hasErrors('comprovanteRenda', true) && '*'}}</span></label>
          <div *ngIf="getDoc(FileType.COMPROVANTERENDA)">
            <a class="d-flex" [href]="getDoc(FileType.COMPROVANTERENDA)?.caminhoArquivo" target="_blank">
              <mat-icon class="text-primary pointer d-flex mt-1 ms-2">download</mat-icon>
              <span class="ps-2 my-auto">{{getDoc(FileType.COMPROVANTERENDA)?.nomeArquivo}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="h2 title my-3">Dados do equipamento</div>
    <div>
      <div class="d-flex pb-3">
        <div class="form-group col-3 pe-3">
          <label class="h3 mb-0" for="potenciaInstalada">Potência instalada <span class="text-danger">{{hasErrors('potenciaInstalada', true) && '*'}}</span></label>
          <input matInput formControlName="potenciaInstalada" mask="99" suffix=" KW" class="form-control">
        </div>
        <div class="form-group col-4 pe-3">
          <label class="h3 mb-0" for="quantidadePaineis">Quantidade de painéis instalados <span class="text-danger">{{hasErrors('quantidadePaineis', true) && '*'}}</span></label>
          <input matInput formControlName="quantidadePaineis" mask="9999" class="form-control">
        </div>
        <div class="form-group col-5">
          <label class="h3 mb-0" for="descricaoPaineis">Descrição dos painéis instalados <span class="text-danger">{{hasErrors('descricaoPaineis', true) && '*'}}</span></label>
          <input matInput formControlName="descricaoPaineis" class="form-control">
        </div>
      </div>
      <div class="d-flex pb-3">
        <div class="form-group col-6 pe-3">
          <label class="h3 mb-0" for="quantidadeInversores">Quantidade de inversores instalados <span class="text-danger">{{hasErrors('quantidadeInversores', true) && '*'}}</span></label>
          <input matInput formControlName="quantidadeInversores" mask="9999" class="form-control">
        </div>
        <div class="form-group col-6 pe-3">
          <label class="h3 mb-0" for="descricaoInversores">Descrição dos inversores instalados <span class="text-danger">{{hasErrors('descricaoInversores', true) && '*'}}</span></label>
          <input matInput formControlName="descricaoInversores" class="form-control">
        </div>
        <!-- <div class="form-group col-4">
          <label class="h3 mb-0" for="demaisItensInstalados">Demais itens instalados <span class="text-danger">{{hasErrors('demaisItensInstalados', true) && '*'}}</span></label>
          <input matInput formControlName="demaisItensInstalados" class="form-control">
        </div> -->
      </div>
      <div class="d-flex pb-3">
        <div class="form-group col-4">
          <label class="h3 mb-0" for="comprovanteFinanciamento">Proposta comercial <span class="text-danger">{{hasErrors('comprovanteFinanciamento', true) && '*'}}</span></label>
          <div *ngIf="getDoc(FileType.FINANCIAMENTO)">
            <a class="d-flex" [href]="getDoc(FileType.FINANCIAMENTO)?.caminhoArquivo" target="_blank">
              <mat-icon class="text-primary pointer d-flex mt-1 ms-2">download</mat-icon>
              <span class="ps-2 my-auto">{{getDoc(FileType.FINANCIAMENTO)?.nomeArquivo}}</span>
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex pb-3">
        <div class="form-group col-3">
          <label class="h3 mb-0" for="comprovanteOutros">Outros comprovantes <span class="text-danger">{{hasErrors('comprovanteOutros', true) && '*'}}</span></label>
          <input [disabled]="disabledForm" type="file" multiple accept=".pdf,.png,.jpg,.jpeg" formControlName="comprovanteOutros" class="form-control">
        </div>
      </div> -->
    </div>
  </app-collapsable-form>
  <app-collapsable-form id="creditApprovalFormSplit" *ngIf="this.formGroup.controls['splitCheck'].value" title="Split" [mainForm]="main" [startCollapsed]="!main || devolving">
    <div class="d-flex pb-3">
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="splitValue">Valor do desembolso <span class="text-danger">{{hasErrors('splitValue', true) && '*'}}</span></label>
        <input matInput [disabled]="true" formControlName="splitValue" prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="." [class]="hasErrors('splitValue') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('splitValue')}}</div>
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="splitUid">Razão social do favorecido <span class="text-danger">{{hasErrors('splitUid', true) && '*'}}</span></label>
        <mat-select formControlName="splitUid" [disabled]="true" [class]="hasErrors('splitUid') + ' form-control'">
          <mat-option *ngFor="let value of splitIntegrators" [value]="value.value.uid">
            {{value.name}}
          </mat-option>
        </mat-select>
        <div class="invalid-feedback">{{inputErrors('splitUid')}}</div>
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0">CPF/CNPJ</label>
        <input matInput formControlName="splitDocumentoFederal" mask="00.000.000/0000-00" [class]="hasErrors('splitDocumentoFederal') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('splitDocumentoFederal')}}</div>
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0">Nº do banco</label>
        <!-- <input matInput formControlName="splitCodigoBanco" mask="000" [class]="hasErrors('splitCodigoBanco') + ' form-control'"> -->
        <mat-select formControlName="splitCodigoBanco" [class]="hasErrors('splitCodigoBanco') + ' form-control'">
          <mat-option *ngFor="let value of splitCodigoBancoOptions" [value]="value.value">
            {{value.name}}
          </mat-option>
        </mat-select>
        <div class="invalid-feedback">{{inputErrors('splitCodigoBanco')}}</div>
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0">Nº da agência</label>
        <input matInput formControlName="splitAgencia" mask="0000" [class]="hasErrors('splitAgencia') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('splitAgencia')}}</div>
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0">Nº da conta</label>
        <input matInput formControlName="splitContaEDigito" [class]="hasErrors('splitContaEDigito') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('splitContaEDigito')}}</div>
      </div>
    </div>
  </app-collapsable-form>
  <app-collapsable-form id="creditApprovalFormComments" [mainForm]="main" title="Comentário" [startCollapsed]="!devolving">
    <app-proposal-comments [canComment]="canComment" [comments]="comments" (onChange)="this.comment = $event"></app-proposal-comments>
  </app-collapsable-form>
  <div class="d-flex justify-content-between pt-4 pb-3">
    <button [hidden]="disabledForm" class="btn btn-outline-secondary w-40 pe-2" (click)="devolve()" type="button">Devolver</button>
    <button [hidden]="disabledForm" [class]="'btn btn-primary ' + (edit ? 'w-20' :'w-40')" (click)="onSubmit()" type="submit">Concluir</button>
    <button [hidden]="!devolving" class="btn btn-primary ms-auto w-40" (click)="commentAndReturn()" type="submit">Comentar</button>
  </div>
</app-collapsable-form>
