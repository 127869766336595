import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-proposal-stepper',
  templateUrl: './client-stepper.component.html',
  styleUrls: ['./client-stepper.component.scss']
})
export class ProposalStepperComponent {
  steps: string[] = [
    'Cadastro',
    'Pré-análise de crédito',
    'Negociação comercial',
    'Aprovação de crédito',
    'Formalização',
    'Liquidação'
  ];

  @Input() currentIndex: number = 0;

  get absCurrentIndex() {
    return Math.abs(this.currentIndex);
  }
}
