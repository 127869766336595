<nav class="navbar py-0" *ngIf="showHeader">
  <div class="d-flex justify-content-between w-100 h-100 px-3">
    <a class="navbar-brand py-2" href="/proposals">
      <img src="../assets/img/app-logo.png" height="50" />
    </a>
    <div *ngIf="showHeaderMenu && isAdmin" class="menu w-65">
      <div class="d-flex h-100">
        <div *ngFor="let item of adminMenuItems" [routerLink]="'/' + item.link"
          [class]="{col: true, 'menu-item': true, active: item.active}">
            <span class="h3">{{ item.label }}</span>
        </div>
        <div (click)="logOut()" class="col menu-item"><span class="h3">Sair</span></div>
      </div>
    </div>
    <div class="actions d-flex text-white">
      <div [hidden]="isAdmin" (click)="logOut()" class="mx-3 p-2 my-auto pointer">
        <mat-icon>exit_to_app</mat-icon>
      </div>
      <div [hidden]="!isAdmin" (click)="showHeaderMenu = !showHeaderMenu" class="mx-3 p-2 my-auto pointer">
        <mat-icon>{{showHeaderMenu ? 'close' : 'menu' }}</mat-icon>
      </div>
      <!-- <div (click)="logOut()" class="mx-3 p-2 my-auto pointer"><mat-icon>logout</mat-icon></div> -->
    </div>
  </div>
</nav>
