import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IntegratorGuard } from 'src/app/core/guards/integrator.guard';
import { OfferProposalsComponent } from './offer-proposals/offer-proposals.component';
import { ViewProposalsComponent } from './view-proposals/view-proposals.component';

const routes: Routes = [
  { path: '', component: ViewProposalsComponent },

  { path: 'new', component: OfferProposalsComponent, canActivate: [IntegratorGuard] },
  // { path: ':id/register', component: OfferProposalsComponent, canActivate: [IntegratorGuard] },
  // { path: ':id/register/edit', component: OfferProposalsComponent, canActivate: [IntegratorGuard] },
  { path: ':id', component: OfferProposalsComponent },
  { path: ':id/offer', component: OfferProposalsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProposalsRoutingModule { }
