import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './core/guards/admin.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { IntegratorGuard } from './core/guards/integrator.guard';
import { UnauthGuard } from './core/guards/unauth.guard';
import { ProposalsModule } from './pages/proposals/proposals.module';
import { Error404Component } from './pages/error404/error404.component';
import { LoginModule } from './pages/login/login.module';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => LoginModule, canActivate: [UnauthGuard] },
  { path: 'proposals', loadChildren: () => ProposalsModule, canActivate: [AuthGuard] },
  // { path: 'home-admin', loadChildren: () => AdminProposalsModule, canActivate: [AuthGuard, AdminGuard] },
  // { path: 'home-integrador', loadChildren: () => IntegratorProposalsModule, canActivate: [AuthGuard, IntegratorGuard] },
  { path: '**', component: Error404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
