import { formatDate } from '@angular/common';
import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { cpfMask, genericFormatter } from 'src/app/core/helpers/masks';
import { StateService } from 'src/app/services/state.service';
import { EstadoCivil } from 'src/app/shared/enums/estado-civil';
import { Address } from 'src/app/shared/models/address.model';
import { Contact } from 'src/app/shared/models/contact.model';
import { CreditAnalisis } from 'src/app/shared/models/credit-analisis.model';
import { Proposal } from 'src/app/shared/models/proposal.model';
import { State } from 'src/app/shared/models/state.model';
import { User } from 'src/app/shared/models/user.model';
import { LoadingService } from 'src/app/shared/services/loading.service';


@Component({
  selector: 'app-doc-alienacao-fiduciaria',
  templateUrl: './doc-alienacao-fiduciaria.component.html',
  styleUrls: ['./doc-alienacao-fiduciaria.component.scss']
})
export class DocAlienacaoFiduciariaComponent implements OnInit, OnChanges {
  @Input() proposal?: Proposal;
  @Input() formGroup?: FormGroup;
  @Input() financingOption?: CreditAnalisis;
  @Input() stateList: State[] = [];
  @Input() emissionDate?: any;
  @Input() fixedParameters: any;

  tables: {
    rows: {
      size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
      label: string;
      content?: any;
    }[][]
  }[] = [];
  pageClauses: {
    text?: string;
    children?: {
      text?: string;
      children?: {
        text: string;
        children?: string[]
      }[]
    }[]
  }[][] = [];

  constructor(private stateService: StateService) {
    this.makeClauses();
  }

  ngOnInit(): void {
    this.formGroup?.valueChanges.subscribe((val) => {
      if (this.proposal) {
        this.makeTables();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.proposal && (changes['proposal'] || changes['financingOption'] || changes['fixedParameters'])) {
      if (!changes['proposal']?.previousValue?.uid
       || (changes['financingOption'].previousValue == undefined
            && changes['financingOption'].currentValue != undefined)
      ) {
        this.makeTables();
      }
    }
  }

  makeTables() {
    let client = this.proposal?.cliente;
    let contact = client?.ultimoContato;
    let address = client?.ultimoEndereco;
    let installationAddress: Address | undefined = this.proposal?.endereco;

    let estado;
    let estadoUfrg;
    if (this.stateList) {
      if (address?.estadoId) {
        estado = this.stateList.find(e => e.id.toString() == address?.estadoId?.toString())?.sigla ?? '';
      }
      if (contact?.ufrg) {
        estadoUfrg = this.stateList.find(e => e.id.toString() == (this.formGroup?.controls['ufrg'].value ?? contact?.ufrg)?.toString()
        )?.sigla ?? '';
      }
    }

    let primeiraParcela = this.financingOption?.resposta?.Proposta?.Parcelas[0];
    let ultimaParcela = this.financingOption?.resposta?.Proposta?.Parcelas[this.financingOption?.resposta?.Proposta?.Parcelas.length - 1];
    let dueDay = primeiraParcela?.DtVencimento.toString().split('T')[0];
    dueDay = dueDay?.split('-')[dueDay.split('-').length - 1];
    let prazo = (Math.ceil(((new Date(ultimaParcela?.DtVencimento ?? new Date()) as any) - (new Date() as any))) / (1000 * 60 * 60 * 24)).toFixed(0)
    let totalDebt = parseFloat((this.proposal?.valorTotalInstalacao ?? 0).toString())
      + parseFloat((this.financingOption?.resposta?.Proposta?.VlrIOF ?? 0).toString())
      + (this.fixedParameters ? this.fixedParameters['tac'] : 0);
    totalDebt -= this.proposal?.valorEntrada ?? 0;

    this.tables = [];

    /// Emitente
    this.tables.push({
      rows: [
        [
          {
            label: 'Nome:',
            content: this.formGroup?.controls['nome'].value ?? contact?.nome,
            size: 9
          },
          {
            label: 'CPF:',
            content: cpfMask(this.formGroup?.controls['cpf'].value ?? client?.documento),
            size: 3
          }
        ],
        [
          {
            label: 'Dt Nasc:',
            content: genericFormatter(this.formGroup?.controls['nascimento'].value ?? client?.nascimento, 'date'),
            size: 2
          },
          {
            label: 'Estado Civil:',
            content: this.formGroup?.controls['estadoCivil'].value ?? contact?.estadoCivil ?? '',
            size: 2
          },
          {
            label: 'Doc. Identidade:',
            content: this.formGroup?.controls['documento'].value ?? contact?.documentoIdentificacao ?? '',
            size: 3
          },
          {
            label: 'Emissor/UF:',
            content: estadoUfrg ?? '',
            size: 2
          },
          {
            label: 'Data da Emissão:',
            content: genericFormatter(this.formGroup?.controls['dataEmissao'].value ?? contact?.dataEmissaoDocumento ?? '', 'date'),
            size: 3
          }
        ],
        [
          {
            label: 'Endereço:',
            content: (this.formGroup?.controls['logradouro'].value ?? address?.logradouro ?? '') + ', ' + (this.formGroup?.controls['numero'].value ?? address?.numero) + (this.formGroup?.controls['complemento'].value || address?.complemento ? (', ' + (this.formGroup?.controls['complemento'].value ?? address?.complemento)) : ''),
            size: 9
          },
          {
            label: 'Bairro:',
            content: this.formGroup?.controls['bairro'].value ?? address?.bairro ?? '',
            size: 3
          }
        ],
        [
          {
            label: 'Cidade:',
            content: this.formGroup?.controls['cidade'].value ?? address?.cidade ?? '',
            size: 7
          },
          {
            label: 'Estado:',
            content: estado,
            size: 2
          },
          {
            label: 'CEP:',
            content: (this.formGroup?.controls['cep'].value ?? address?.cep)?.replace(/^([\d]{2})\.*([\d]{3})-*([\d]{3})/, "$1$2-$3"),
            size: 3
          }
        ],
        [
          {
            label: 'E-mail:',
            content: this.formGroup?.controls['email'].value ?? contact?.email ?? '',
            size: 12
          }
        ]
      ]
    });

    /// Credor
    this.tables.push({
      rows: [
        [
          {
            label: "Nome:",
            content: "BMP SOCIEDADE DE CREDITO DIRETO S/A",
            size: 8
          },
          {
            label: "CNPJ:",
            content: "34.337.707/0001-00",
            size: 4
          }
        ],
        [
          {
            label: "Endereço:",
            content: "Paulista, 1765, 1º Andar",
            size: 8
          },
          {
            label: "Bairro:",
            content: "Cerqueira César",
            size: 4
          }
        ],
        [
          {
            label: "Cidade:",
            content: "São Paulo",
            size: 8
          },
          {
            label: "Estado:",
            content: "SP",
            size: 2
          },
          {
            label: "CEP:",
            content: "01311-200",
            size: 2
          }
        ],
        [
          {
            label: "E-mail:",
            content: "atendimento@moneyp.com.br",
            size: 12
          }
        ]
      ]
    });

    /// Dados da CCB
    this.tables.push({
      rows: [
        [
          {
            label: "Valor Líquido do Crédito:",
            content: genericFormatter(((this.proposal?.valorTotalInstalacao ?? 0) - (this.proposal?.valorEntrada ?? 0)) ?? '', 'money'),
            size: 4
          },
          {
            label: "Valor do IOF:",
            content: genericFormatter(this.financingOption?.resposta?.Proposta?.VlrIOF ?? '', 'money'),
            size: 4
          },
          {
            label: "Valor Total da Dívida:",
            content: genericFormatter(totalDebt, 'money'),
            size: 4
          }
        ],
        [
          {
            label: "Data de Desembolso:",
            content: this.formGroup?.controls['dataDesembolso'].value ?? this.emissionDate ?? this.getCurdate,
            size: 4
          },
          {
            label: "Prazo (dias):",
            content: prazo,
            size: 4
          },
          {
            label: "Quantidade de Parcelas:",
            content: (this.proposal?.parcelaSelecionada ?? '').toString(),
            size: 4
          }
        ],
        [
          {
            label: "Carência (dias):",
            content: (this.proposal?.carenciaSelecionada ?? '').toString(),
            size: 4
          },
          {
            label: "Forma de Pagamento das Parcelas:",
            content: "Boleto, TED ou PIX",
            size: 4
          },
          {
            label: "Vencimento das Parcelas:",
            content: "Todo dia " + dueDay + " ou dia útil subsequente.",
            size: 4
          }
        ],
        [
          {
            label: "Vencimento da Primeira Parcela:",
            content: genericFormatter(primeiraParcela?.DtVencimento ?? new Date(), 'date'),
            size: 4
          },
          {
            label: "Vencimento da Última Parcela:",
            content: genericFormatter(ultimaParcela?.DtVencimento ?? new Date(), 'date'),
            size: 4
          },
          {
            label: "Valor da Parcela:",
            content: genericFormatter(this.financingOption?.resposta?.Proposta?.VlrParcela ?? '', 'money'),
            size: 4
          }
        ],
        [
          {
            label: "Taxa de Juros (% a.a.):",
            content: genericFormatter(this.financingOption?.resposta?.Proposta?.PercJurosAnual ?? 0, 'decimal', 4).toString(4),
            size: 4
          },
          {
            label: "Taxa de Juros (% a.m):",
            content: genericFormatter(this.financingOption?.resposta?.Proposta?.PercJurosMensal ?? 0, 'decimal', 4).toString(4),
            size: 4
          },
          {
            label: "Custo Efetivo Total (% a.m.):",
            content: `${genericFormatter(this.financingOption?.resposta?.Proposta?.PercCETAnual ?? 0, 'decimal', 4).toString()}% a.a. / ${genericFormatter(this.financingOption?.resposta?.Proposta?.PercCETMensal ?? 0, 'decimal', 4).toString()}% a.m.`,
            size: 4
          }
        ]
      ]
    });

    /// Descrição e local
    this.tables.push({
      rows: [
        [
          {
            label: "Razão Social Fornecedor:",
            content: 'ENGEMETAL COMERCIO E MANUTENCAO LTDA',
            size: 4
          },
          {
            label: "CNPJ Fornecedor:",
            content: '10.383.997/0001-60',
            size: 4
          },
          {
            label: "Endereço de Instalação:",
            content: this.getFullEquipamentAddress(),
            size: 4
          }
        ],
        [
          {
            label: "Potência Instalada:",
            content: (this.formGroup?.controls['potenciaInstalada'].value ?? this.proposal?.potenciaInstalada ?? 0).toString() + ' kw',
            size: 4
          },
          {
            label: "Quantidade de Paineis Instalados:",
            content: (this.formGroup?.controls['quantidadePaineis'].value ?? this.proposal?.quantidadePaineisInstalados ?? '').toString(),
            size: 4
          },
          {
            label: "Descrição dos Paineis Instalados:",
            content: this.formGroup?.controls['descricaoPaineis'].value ?? this.proposal?.descricaoPaineisInstalados ?? '',
            size: 4
          }
        ],
        [
          {
            label: "Quantidade de Inversores Instalados:",
            content: (this.formGroup?.controls['quantidadeInversores'].value ?? this.proposal?.quantidadeInversoresInstalados ?? '').toString(),
            size: 4
          },
          {
            label: "Descrição dos Inversores Instalados:",
            content: this.formGroup?.controls['descricaoInversores'].value ?? this.proposal?.descricaoInversoresInstalados ?? '',
            size: 4
          },
          {
            label: "Demais Itens Instalados:",
            content: this.formGroup?.controls['demaisItensInstalados'].value ?? this.proposal?.demaisItensInstalados ?? '',
            size: 4
          }
        ]
      ]
    });
  }

  makeClauses() {
    this.pageClauses.push([
      {
        text: "Considerando que:",
        children: [
          { text: "O <b>EMITENTE</b> emitiu uma cédula de crédito bancário (“<b>CCB</b>”) descrita e caracterizada no item 3 acima, e cuja cópia integra o presente instrumento (Anexo I) em favor da <b>BMP MONEY PLUS SOCIEDADE DE CREDITO DIRETO S/A</b>, instituição financeira, inscrita no CNPJ/MF sob nº 34.337.707/0001-00, com sede na Av. Paulista, 1765, 1º Andar, CEP 01311-200, São Paulo, SP, neste ato, representada na forma do seu Contrato Social." },
          { text: "Os recursos oriundos da <b>CCB</b> foram integralmente utilizados pelo <b>EMITENTE</b> para adquirir do FORNECEDOR os EQUIPAMENTOS a serem entregues e instalados pelo FORNECEDOR no endereço acima indicado." },
          { text: "O <b>EMITENTE</b> declara ciência de que a <b>CCB</b> poderá ser endossada, pelo <b>CREDOR</b> a quaisquer terceiros, passando referidos terceiros a ser(em) titular(es) de todos e quaisquer direitos conferidos pela <b>CCB</b>." },
          { text: "O Fornecedor irá fornecer e instalar os Equipamentos no endereço especificado acima e as especificações dos Equipamentos constarão da Nota Fiscal a ser emitida pelo Fornecedor, documento que passará a integrar o presente instrumento <b>(Anexo II)</b>." },
          { text: "O <b>EMITENTE</b> deseja constituir a alienação fiduciária sobre os Equipamentos, conforme previsto na <b>CCB</b>." },
        ],
      },
      {
        text: "Resolvem as Partes, <b>EMITENTE</b> e <b>CREDOR</b>, celebrar o presente instrumento particular de Alienação Fiduciária em Garantia de bens futuros, que será regido pelos seguintes termos e condições:",
        children: [
          { text: "Em garantia ao integral e pontual cumprimento de todas as obrigações previstas na <b>CCB</b>, o <b>EMITENTE</b> cede e transfere ao <b>CREDOR</b>, em alienação fiduciária, a propriedade fiduciária, o domínio resolúvel e a posse indireta dos  Equipamentos, tornando-se o <b>EMITENTE</b> possuidor direito e depositário fiel dos Equipamentos em nome do <b>CREDOR</b>, até a efetiva quitação de todas as obrigações decorrentes da <b>CCB</b>." },
          { text: "Sem prejuízo das obrigações previstas neste instrumento e na <b>CCB</b>, os Equipamentos poderão ser livremente utilizados pelo <b>EMITENTE</b> segundo sua destinação, na forma do art. 1.363 do Código Civil." },
          {
            text: "Na hipótese de quaisquer atrasos ou inadimplementos das obrigações do <b>EMITENTE</b> previstas na <b>CCB</b>, ou ainda nas hipóteses de vencimento antecipado previstas na <b>CCB</b>, o CREDOR poderá, excutir a presente garantia, retomando a posse direta do sistema fotovoltaico e vendê-lo independente de leilão, hasta pública ou qualquer outra medida judicial ou extrajudicial, aplicando o produto da venda na amortização ou liquidação da dívida, podendo o CREDOR praticar todos os atos necessários para tanto. O <b>EMITENTE</b> concorda e se declara ciente de que:",
            children: [
              { text: "Caso a excussão dos Equipamentos ou a execução de eventuais garantias previstas na <b>CCB</b> não sejam suficientes para o integral pagamento do montante devido pelo <b>EMITENTE</b> em decorrência do inadimplemento da <b>CCB</b>, o <b>EMITENTE</b> continuará pessoalmente responsável até a integral quitação de suas obrigações; e " },
              { text: "Nada neste instrumento diminui a eficácia e responsabilidade das demais garantias prestadas por meio da <b>CCB</b>." },
            ]
          },
          { text: "A alienação fiduciária que ora se constitui permanecerá íntegra e em pleno vigor até a efetiva e total quitação de todas as obrigações previstas na <b>CCB</b>, restando claro que o seu cumprimento parcial não importa a exoneração proporcional da alienação fiduciária." },
          { text: "O <b>EMITENTE</b> arcará com todas as despesas de registro da alienação fiduciária, a qual deverá ser registrada em cartório pelas <b>PARTES</b> em até 20 (vinte) dias úteis contados da celebração da <b>CCB</b>." },
        ]
      },
    ]);
    this.pageClauses.push([{
      children: [
        {
          text: "O <b>EMITENTE</b> declara e concorda que:",
          children: [
            { text: "Na qualidade de FIEL DEPOSITÁRIO dos Equipamentos, conforme definido no <b>QUADRO II</b> da <b>CCB</b> contida no <b>Anexo I</b> deste documento, deverá praticar todos os atos necessários à existência e boa conservação dos Equipamentos, assumindo todas as obrigações estabelecidas nos artigos 627 a 646 do Código Civil, a título gratuito. " },
            { text: "Os Equipamentos foram adquiridos diretamente do Fornecedor livremente escolhido pelo <b>EMITENTE</b>, de modo que em caso de constatação de qualquer vício ou defeito dos Equipamentos, esta situação deve ser solucionada diretamente com o Fornecedor, sendo este o único responsável pela qualidade e funcionamentos dos Equipamentos. " },
            { text: "É responsável pela ocorrência de qualquer fato superveniente, caso fortuito ou de força maior que venha a deteriorar, diminuir e/ou extinguir o valor dos Equipamentos, ou turbar a posse mansa e pacífica do <b>EMITENTE</b> sobre os Equipamentos." },
            { text: "Na ocorrência de qualquer evento que deteriore, diminua ou extinga o valor dos Equipamentos, deverá consertar, recompor ou substituir os Equipamentos, conforme o caso, às suas expensas, de modo que o valor da garantia ora prestada não diminua. " },
            { text: "A ocorrência de qualquer evento que deteriore, diminua ou extinga o valor dos Equipamentos não diminui ou extingue as obrigações previstas na <b>CCB</b>. " },
            { text: "De nenhuma maneira alienará, venderá, permutará, emprestará, doará, locará, dará em garantia, criará qualquer ônus, ou assinará qualquer compromisso que, direta ou indiretamente, restrinja os direitos do CREDOR sobre os Equipamentos, e que conhece os termos do §2º do artigo 66-B da Lei 4.728/65  e do inciso I do § 2º do artigo 171 do Decreto-Lei nº 2.748/40 (Código Penal), que consideram infração penal a disposição de coisa alheia como própria." },
          ]
        },
        { text: "O <b>EMITENTE</b> constitui o <b>CREDOR</b> seu bastante procurador para que este, às suas expensas, realize o registro do presente Contrato no Cartório de Registro de Títulos e Documentos da Comarca de domicílio do <b>EMITENTE</b> e do <b>CREDOR</b>." },
        { text: "Fica eleito o foro da Comarca de São Paulo para resolver quaisquer controvérsias decorrentes do presente instrumento." }
      ]
    }]);
  }

  getFullEquipamentAddress(): string {
    let retorno = (this.formGroup?.controls['logradouroEquipamento']?.value ?? this.proposal?.endereco?.logradouro) ?? '';
    if (this.formGroup?.controls['numeroEquipamento']?.value || this.proposal?.endereco?.numero) {
      retorno += ', '+(this.formGroup?.controls['numeroEquipamento']?.value
        ?? this.proposal?.endereco?.numero);
    }
    if (this.formGroup?.controls['complementoEquipamento']?.value || this.proposal?.endereco?.complemento) {
      retorno += ', '+(this.formGroup?.controls['complementoEquipamento']?.value
        ?? this.proposal?.endereco?.complemento);
    }
    if (this.formGroup?.controls['bairroEquipamento']?.value || this.proposal?.endereco?.bairro) {
      retorno += ' - '+(this.formGroup?.controls['bairroEquipamento']?.value
        ?? this.proposal?.endereco?.bairro);
    }
    if (this.formGroup?.controls['cidadeEquipamento']?.value || this.proposal?.endereco?.cidade) {
      retorno += ', '+(this.formGroup?.controls['cidadeEquipamento']?.value
        ?? this.proposal?.endereco?.cidade); }
    if (this.formGroup?.controls['estadoEquipamento']?.value || this.proposal?.endereco?.estado) {
      retorno += ' - '+(this.stateList.find(e => e.id.toString() == (this.formGroup?.controls['estadoEquipamento']?.value
        ?? this.proposal?.endereco?.estadoId)?.toString()))?.sigla ?? ''; }
    if (this.formGroup?.controls['cepEquipamento']?.value || this.proposal?.endereco?.cep) {
      retorno += ', CEP: '+(this.formGroup?.controls['cepEquipamento']?.value
        ?? this.proposal?.endereco?.cep);
    }
    return retorno;
  }

  countCellNumber(table: any, row: number, column: number) {
    let numbersBefore = 0;
    if (row != 0) {
      for (let i = 0; i < row; i++) {
        numbersBefore += table.rows[i].length;
      }
    }
    numbersBefore += column + 1;
    return numbersBefore;
  }

  get getCurdate() {
    return genericFormatter(new Date(), 'date');
  }
}
