import { Deserializable } from "../deserializable";

export class LiquidationRequest implements Deserializable {
  documentoCcb?: File;
  dacaoEmPagamento?: File;
  alienacaoFiduciaria?: File;
  assinarManual!: boolean;
  propostaGuid!: string;
  assinaturaGuid?: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  toSave() {
    const formData: FormData = new FormData();
    Object.keys(this).forEach(key => {
      let value = (this as any)[key];
      if (value) {
        formData.append(key, value);
      }
    });
    if (this.documentoCcb) {
      formData.append("documentoCcb", this.documentoCcb);
    }
    if (this.dacaoEmPagamento) {
      formData.append("dacaoEmPagamento", this.dacaoEmPagamento);
    }
    if (this.alienacaoFiduciaria) {
      formData.append("alienacaoFiduciaria", this.alienacaoFiduciaria);
    }
    return formData;
  }
}
