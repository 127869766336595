export enum ProposalStage {
  CADASTRO = 'Cadastro',
  PREANALISEDECREDITO = 'Aguardando pré-análise',
  NEGOCIACAOCOMERCIAL = 'Aguardando negociação comercial',
  APROVACAODECREDITO = 'Aguardando parecer de crédito',
  FORMALIZACAO = 'Formalização',
  PROCESSODELIQUIDACAO = 'Em liquidação',
  LIQUIDADO = 'Liquidado',
  RECUSADO = 'Recusa de crédito',
  RECUSADOPREANALISE = 'Recusa na pré-análise de crédito',
  RECUSADONEGOCIACAO = 'Recusa na negociação comercial',
  RECUSADOAPROVACAO = 'Recusa na aprovação de crédito',
}

export namespace ProposalStage {
  export function fromNumber(val?: number): ProposalStage {
    let response;
    switch (val) {
      case 0:
        response = ProposalStage.RECUSADOPREANALISE;
      break;
      case 1:
        response = ProposalStage.RECUSADONEGOCIACAO;
      break;
      case 2:
        response = ProposalStage.RECUSADOAPROVACAO;
      break;
      case 10:
        response = ProposalStage.CADASTRO;
      break;
      case 20:
        response = ProposalStage.PREANALISEDECREDITO;
      break;
      case 30:
        response = ProposalStage.NEGOCIACAOCOMERCIAL;
      break;
      case 40:
        response = ProposalStage.APROVACAODECREDITO;
      break;
      case 50:
        response = ProposalStage.FORMALIZACAO;
      break;
      case 60:
        response = ProposalStage.PROCESSODELIQUIDACAO;
      break;
      case 70:
        response = ProposalStage.LIQUIDADO;
      break;
      default:
        response = ProposalStage.RECUSADO;
      break;
    }
    return response;
  }
  export function toNumber(val?: ProposalStage): number {
    let response;
    switch (val) {
      case ProposalStage.RECUSADOPREANALISE:
        response = 0;
      break;
      case ProposalStage.RECUSADONEGOCIACAO:
        response = 1;
      break;
      case ProposalStage.RECUSADOAPROVACAO:
        response = 2;
      break;
      case ProposalStage.CADASTRO:
        response = 10;
      break;
      case ProposalStage.PREANALISEDECREDITO:
        response = 20;
      break;
      case ProposalStage.NEGOCIACAOCOMERCIAL:
        response = 30;
      break;
      case ProposalStage.APROVACAODECREDITO:
        response = 40;
      break;
      case ProposalStage.FORMALIZACAO:
        response = 50;
      break;
      case ProposalStage.PROCESSODELIQUIDACAO:
        response = 60;
      break;
      case ProposalStage.LIQUIDADO:
        response = 70;
      break;
      default:
        response = 0;
      break;
    }
    return response;
  }

  export function isRefused(val?: ProposalStage): boolean {
    return val == ProposalStage.RECUSADO
      || val == ProposalStage.RECUSADOAPROVACAO
      || val == ProposalStage.RECUSADONEGOCIACAO
      || val == ProposalStage.RECUSADOPREANALISE;
  }
}
