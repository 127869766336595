import { Injectable } from '@angular/core';
import { HttpDataSource } from '../data_source/config';
import { User } from '../shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private dataSource: HttpDataSource) { }

  login(payload: { username: string, password: string}) {
    return this.dataSource.post<User>({ url: '/login', payload: payload });
  }
}
