<div class="data-table">
  <button *ngIf="showSelectAllBtn" mat-raised-button (click)="$event ? masterToggle() : null">
    Selecionar Todos
  </button>
  <div [class]="(showSearchInput || showExportButtons ? 'd-flex' : 'd-none') + ' mb-4 justify-content-between'">
    <div class="form-group form-floating col-4" *ngIf="showSearchInput">
      <input (keyup)="applyFilter($event)" id="buscar" name="buscar" class="form-control"
        placeholder="Realizar Busca">
      <label for="buscar">Realizar Busca</label>
    </div>
    <div class="col-4 d-flex" *ngIf="showExportButtons">
      <div class="col my-auto">
        <button class="btn btn-outline-secondary" (click)="
            exporter.exportTable('xlsx', {
              fileName: exportTablesName,
              Props: { Author: 'Gol' }
            })
          ">
          Excel
        </button>
      </div>
      <div class="col my-auto">
        <button class="btn btn-outline-secondary" (click)="
            exporter.exportTable('csv', {
              fileName: exportTablesName,
              Props: { Author: 'Gol' }
            })
          ">
          CSV
        </button>
      </div>
      <div class="col my-auto">
        <button class="btn btn-outline-secondary" (click)="
            exporter.exportTable('json', {
              fileName: exportTablesName,
              Props: { Author: 'Gol' }
            })
          ">
          Json
        </button>
      </div>
      <div class="col my-auto">
        <button class="btn btn-outline-secondary" (click)="
            exporter.exportTable('txt', {
              fileName: exportTablesName,
              Props: { Author: 'Gol' }
            })
          ">
          txt
        </button>
      </div>
    </div>
  </div>

  <div class="mat-elevation-z8 table-container">
    <table mat-table [dataSource]="dataSource" #exporter="matTableExporter" [hiddenColumns]="exportHiddenColumns"
      matTableExporter matSort>
      <ng-container [class]="showSelectBox ? 'd-block' : 'd-none'" matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let row">
        </td>
      </ng-container>

      <ng-container [matColumnDef]="column" *ngFor="let column of columnsToDisplay | slice: 1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!showSorting">
          {{column.toUpperCase()}}
        </th>
        <td mat-cell *matCellDef="let row">
          {{
            column === "active"
            ? null
            : column === "price"
            ? (row[column] | currency: "BRL":true)
            : row[column]
          }}

          <span class="mat-button-wrapper" *ngIf="column === 'ações'">
            <mat-icon class="mat-icon material-icons me-1" color="primary" style="cursor: pointer"
              *ngIf="showViewButton" (click)="onClick(row, 'view')" matTooltip="Visualizar"
              matTooltipPosition="above">visibility</mat-icon>

            <mat-icon class="mat-icon material-icons me-1" color="global" style="cursor: pointer" *ngIf="showEditButton"
              (click)="onClick(row, 'edit')" matTooltip="Editar" matTooltipPosition="above">edit</mat-icon>

            <mat-icon class="mat-icon material-icons me-1" style="cursor: pointer; color: #b60303"
              *ngIf="showDeleteButton" (click)="onClick(row, 'delete')" matTooltip="Excluir"
              matTooltipPosition="above">delete_forever</mat-icon>

            <div *ngIf="showCustomButton && customActionIcon"
              (click)="onClick(row, 'custom')"
              [innerHTML]="customActionIcon!(row)"></div>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
    </table>
    <!-- <mat-paginator *ngIf="showPaginator" class="paginator" [length]="dataSource.data.length" [pageSizeOptions]="[50,100,200]">
    </mat-paginator> -->
  </div>
</div>
