import { Deserializable } from "./deserializable";

export class SplitData implements Deserializable {
  uid?: string;
  codigoBanco?: string;
  tipoConta?: string;
  agencia?: string;
  conta?: string;
  contaDigito?: string;
  documentoFederal?: string;
  nomePagamento?: string;
  documentoFederalCedente?: string;

  criadoPor?: string;
  criadoEm?: string;
  atualizadoPor?: string;
  atualizadoEm?: string;

  get documentOnlyNumber(): string | undefined {
    return this.documentoFederal?.replace(/[^0-9]/g, '');
  }

  get contaEDigito(): string | undefined {
    var response = this.conta;
    if (this.contaDigito) {
      response += '-' + this.contaDigito;
    }
    return response;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  toSave() {
    return this;
  }
}
