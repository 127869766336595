import { cpfMask } from "src/app/core/helpers/masks";
import { Contact } from "./contact.model";
import { Deserializable } from "./deserializable";
import { Address } from "./address.model";
import { Document } from "./document.model";

export class User implements Deserializable {
  uid!: string;
  login!: string;
  nascimento?: string;
  documento?: string;
  token?: string;
  ativo?: boolean = true;
  perfil!: 'admin' | 'integrator' | 'client';
  criadoEm?: string;
  criadoPor?: string;
  contatos?: Contact[]
  enderecos?: Address[]
  documentos!: Document[];

  deserialize(input: any): this {
    Object.assign(this, input);
    this.contatos = this.contatos ? this.contatos.map(e => new Contact().deserialize(e)): undefined;
    this.documentos = this.documentos ? this.documentos.map(e => new Document().deserialize(e)) : [];
    return this;
  }

  get documentoFormatado() {
    return cpfMask(this.documento ?? '');
  }

  get ultimoContato(): Contact | null {
    if (this.contatos && this.contatos?.length > 0) {
      return this.contatos[this.contatos.length - 1];
    }
    return null;
  }

  get ultimoEndereco(): Address | null {
    if (this.enderecos && this.enderecos?.length > 0) {
      return this.enderecos[this.enderecos.length - 1];
    }
    return null;
  }

  get typeName(): string {
    switch (this.perfil) {
      case 'admin':
        return 'Administrador';
      case 'client':
        return 'Cliente';
      case 'integrator':
        return 'Integrador';
      default:
        return 'Anônimo';
    }
  }

  toSave() {
    return this;
  }
}
