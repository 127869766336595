import { Injectable } from '@angular/core';
import { HttpDataSource } from '../data_source/config';
import { BureauType } from '../shared/enums/bureau-type';
import { CreditAnalisis } from '../shared/models/credit-analisis.model';

@Injectable({
  providedIn: 'root'
})
export class BureauService {
  constructor(private dataSource: HttpDataSource) {}

  getBureau(clientUid: string, bureauType: BureauType) {
    let payload = { tipoBureau: bureauType };
    return this.dataSource.post<CreditAnalisis[]>({ url: `/bureau/${clientUid}`,
      payload, authenticate: true, cache: true, cacheSeconds: 3 });
  }
}
