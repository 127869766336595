export enum EstadoCivil {
    SOLTEIRO = 'solteiro',
    CASADO = 'casado',
    SEPARADO = 'separado',
    DIVORCIADO = 'divorciado',
    VIUVO = 'viuvo'
}

export namespace EstadoCivil {
  export function fromNumber(val?: number): EstadoCivil | undefined {
    let response;
    switch (val) {
      case 10:
        response = EstadoCivil.SOLTEIRO;
      break;
      case 20:
        response = EstadoCivil.CASADO;
      break;
      case 30:
        response = EstadoCivil.SEPARADO;
      break;
      case 40:
        response = EstadoCivil.DIVORCIADO;
      break;
      case 50:
        response = EstadoCivil.VIUVO;
      break;
    }
    return response;
  }
}
