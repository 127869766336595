import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-clause-orderer',
  templateUrl: './clause-orderer.component.html',
  styleUrls: ['./clause-orderer.component.scss']
})
export class ClauseOrdererComponent {
  @Input() startClause?: number;
  @Input() startSubclause?: number;
  @Input() startSubSubclause?: number;
  @Input() clauses!: {
    text?: string;
    children?: {
      text?: string;
      children?: {
        text: string;
        children?: string[]
      }[]
    }[]
  }[];

  changeBold(html: string) {
    const openTagB = '<b>';
    const closeTagB = '</b>';

    const openTagSpan = '<span class="font-weight-500">';
    const closeTagSpan = '</span>';

    return html.replace(new RegExp(openTagB, 'g'), openTagSpan)
      .replace(new RegExp(closeTagB, 'g'), closeTagSpan);
  }
}
