import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cache: Map<string, { data: any; expiration: number }> = new Map<
    string,
    { data: any; expiration: number }
  >();

  constructor() {}

  get(url: string): any {
    const cachedItem = this.cache.get(url);

    // Verifica se o item está no cache e se ainda não expirou
    if (cachedItem && cachedItem.expiration > Date.now()) {
      return cachedItem.data;
    }

    // Se não estiver em cache ou já expirou, retorna undefined
    return undefined;
  }

  put(url: string, data: any, expiresInMs: number): void {
    // Calcula o momento de expiração a partir do tempo atual e do tempo de expiração em milissegundos
    const expiration = Date.now() + expiresInMs;
    this.cache.set(url, { data, expiration });
  }
}
