<app-collapsable-form id="preanalisisForm" title="Pré-análise de crédito" [middle]="true" [mainForm]="main" [startCollapsed]="!main" [disabled]="disabled" [formGroup]="formGroup">
  <!-- <app-collapsable-form *ngIf="sessionService.isAdmin" id="preanalisisFormBureaus" title="Bureaus de crédito" [mainForm]="main" [startCollapsed]="!main || devolving">
    <app-collapsable-form id="preanalisisFormBureausSerasa" [styless]="true" title="Serasa"></app-collapsable-form>
    <app-collapsable-form id="preanalisisFormBureausBacen" [styless]="true" title="Bacen"></app-collapsable-form>
    <app-collapsable-form id="preanalisisFormBureausDatabusca" [styless]="true" title="Databusca"></app-collapsable-form>
  </app-collapsable-form> -->
  <app-collapsable-form id="preanalisisFormParams" title="Parâmetros da oferta" [mainForm]="main" [startCollapsed]="!main || devolving">
    <div class="d-flex w-70 mx-auto">
      <div class="d-flex">
        <div class="h3 title pe-2 mb-auto mt-1">Valor total:</div>
        <div class="w-55">
          <input matInput formControlName="valorTotal"
            prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="."
            [class]="hasErrors('valorTotal') + ' form-control'">
          <div class="invalid-feedback">{{inputErrors('valorTotal')}}</div>
        </div>
      </div>
      <div class="d-flex">
        <div class="h3 title pe-2 mb-auto mt-1">Valor entrada:</div>
        <div class="w-55">
          <input matInput formControlName="valorEntrada"
            prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="."
            [class]="hasErrors('valorEntrada') + ' form-control me-auto'">
          <div class="invalid-feedback">{{inputErrors('valorEntrada')}}</div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column w-55 mx-auto pt-4">
      <div class="h3 title mb-0">Taxa:</div>
      <div class="mx-auto w-95">
        <ngx-slider formControlName="taxaDeJurosSlider" [options]="taxOptions"></ngx-slider>
      </div>
    </div>
    <div class="d-flex flex-column w-55 mx-auto pt-2">
      <div class="h3 title mb-0">Prazo:</div>
      <div class="mx-auto w-95">
        <ngx-slider formControlName="prazoSlider" [options]="dueOptions"></ngx-slider>
      </div>
    </div>
    <div class="d-flex flex-column w-55 mx-auto pt-2">
      <div class="h3 title mb-0">Carência:</div>
      <div class="mx-auto w-95">
        <ngx-slider formControlName="carenciaSlider" [options]="carencyOptions"></ngx-slider>
      </div>
    </div>
    <button [hidden]="disabledForm" [disabled]="!formValid" class="btn btn-primary mt-4 mb-2 float-right float-right w-40" (click)="changeGridParcelas()">Gerar oferta</button>
  </app-collapsable-form>
  <app-collapsable-form id="preanalisisFormOffer" title="Oferta" [mainForm]="main" [startCollapsed]="!main || devolving">
    <app-grid-offer [gridInstallments]="gridInstallments"></app-grid-offer>
    <!-- <div *ngIf="!gridInstallments && sessionService.isAdmin" class="loading-area clearfix d-flex">
      <div class="spinner-border text-primary mx-auto" role="status"></div>
    </div>
    <div *ngIf="gridInstallments" class="column-grid w-100 py-3 text-center">
      <div class="d-flex">
        <div class="title col-1"></div>
        <div *ngFor="let carencyValue of getCarencyDates" class="col-1 px-4 d-flex">
          <div *ngIf="gridInstallments[carencyValue.value]" class="text-left m-auto">
            <div class="title mb-2">{{ carencyValue.value }} dias de carência</div>
            <div *ngIf="gridInstallments[carencyValue.value][getDueDates[0].value]">
              <span>Primeira parcela:</span>
              <div class="h3 mb-0">{{ genericFormatter(gridInstallments[carencyValue.value][getDueDates[0].value]?.resposta?.Proposta?.Parcelas[0]?.DtVencimento ?? '-', 'date') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div *ngFor="let dueValue of getDueDates" class="d-flex">
        <div class="title col-1 my-auto">{{ dueValue.value }}x</div>
        <div *ngFor="let carencyValue of getCarencyDates" class="col-1">
          <div *ngIf="gridInstallments[carencyValue.value] && gridInstallments[carencyValue.value][dueValue.value]" class="offer-card">
            <div class="subtitle py-2">{{ genericFormatter(gridInstallments[carencyValue.value][dueValue.value]?.resposta?.Proposta?.VlrParcela ?? 0, 'money') }}</div>
            <div class="text-left">
              <div class="h6 mb-0">Taxa de juros mensal: {{ genericFormatter(gridInstallments[carencyValue.value][dueValue.value].resposta?.Proposta?.PercJurosMensal ?? 0, 'decimal', 4) }}</div>
              <div class="h6 mb-0">Taxa de juros anual: {{ genericFormatter(gridInstallments[carencyValue.value][dueValue.value].resposta?.Proposta?.PercJurosAnual ?? 0, 'decimal', 4) }}</div>
              <div class="h6 mb-0">CET mensal: {{ genericFormatter(gridInstallments[carencyValue.value][dueValue.value].resposta?.Proposta?.PercCETMensal ?? 0, 'decimal', 4) }}</div>
              <div class="h6 mb-0">CET anual: {{ genericFormatter(gridInstallments[carencyValue.value][dueValue.value].resposta?.Proposta?.PercCETAnual ?? 0, 'decimal', 4) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="d-flex justify-content-between pt-4 pb-3 px-3">
      <button [hidden]="disabledForm" [class]="'btn w-40 pe-2 ' + (propostaEtapa == ProposalStage.RECUSADOPREANALISE ? 'btn-secondary' : 'btn-outline-secondary')" (click)="onRefused()" type="button">Recusar</button>
      <button [hidden]="disabledForm" [class]="'btn w-40 ' + (propostaEtapa == ProposalStage.RECUSADOPREANALISE ? 'btn-outline-primary' : 'btn-primary')" (click)="onApproved()" type="submit">Aprovar</button>
    </div>

  </app-collapsable-form>
  <app-collapsable-form id="preanalisisFormComment" title="Comentário" [mainForm]="main" [startCollapsed]="!devolving">
    <app-proposal-comments [canComment]="canComment" [comments]="comments" (onChange)="this.comment = $event"></app-proposal-comments>
  </app-collapsable-form>
  <div class="d-flex pt-4 pb-3 px-3 justify-content-between">
    <button [hidden]="disabledForm" class="btn btn-outline-secondary w-40 pe-2" (click)="devolve()" type="button">Devolver</button>
    <!-- <button [hidden]="!canEdit || edit" class="btn btn-primary w-40" (click)="startEdit()">Editar</button>
    <button [hidden]="!edit" class="btn btn-danger w-20" (click)="cancelEdit()">Cancelar</button> -->
    <button [hidden]="disabledForm" [disabled]="!formValid" [class]="'btn btn-primary ' + (edit ? 'w-20' :'w-40')" (click)="onSubmit()" type="submit">Concluir</button>
    <button [hidden]="!devolving" class="btn btn-primary ms-auto w-40" (click)="commentAndReturn()" type="submit">Comentar</button>
  </div>

</app-collapsable-form>
