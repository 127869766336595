import { EstadoCivil } from "../enums/estado-civil";
import { Deserializable } from "./deserializable";
import { State } from "./state.model";

export class Contact implements Deserializable {
  uid!: string;
  criadoPor?: string;
  criadoEm?: string;
  usuarioGuid?: string;
  nome?: string;
  ufrg?: string;
  email?: string;
  celular?: string;
  telefone?: string;
  orgaExpedidor?: string;
  estadoCivil?: EstadoCivil;
  documentoIdentificacao?: string;
  tipoDocumentoIdentificacao?: number;
  dataEmissaoDocumento?: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.estadoCivil = EstadoCivil.fromNumber(input.estadoCivil);
    return this;
  }

  toSave() {
    return this;
  }

  get firstNLastNames(): string {
    let names = this.nome?.split(' ') ?? [];
    if (names?.length > 1) {
      return names[0] + ' ' + names[names.length - 1];
    }
    return names[0];
  }
}
