import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './core/components/header/header.component';
import { LoadingComponent } from './core/components/loading/loading.component';
import { NotificationService } from './shared/services/notification.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { Error404Component } from './pages/error404/error404.component';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { HttpClientModule } from '@angular/common/http';

registerLocaleData(localePt);
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoadingComponent,
    Error404Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule
  ],
  providers: [ { provide: LOCALE_ID, useValue: 'pt-BR' }, NotificationService  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
