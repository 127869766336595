<div class="form-group mt-4 mb-3">
  <div *ngFor="let comment of comments; let first = first" class="comment card border-primary shadow rounded-bottom-0">
    <div class="d-flex justify-content-between pt-3 pb-1 px-3">
      <div class="col-auto">
        {{ comment.firstNLastNames }} ({{ comment.typeName }})
      </div>
      <div class="col-auto">Etapa: {{ comment.propostaEtapa.toString() }}</div>
      <!-- <div class="col-auto">há {{ comment.passedTime }}</div> -->
    </div>
    <textarea matInput class="form-control shadow border-primary" (change)="changeCommentText($event)" disabled [rows]="4">{{ comment.descricao }}</textarea>
    <div *ngIf="comment.docs" class="attachments d-flex flex-wrap justify-content-end">
      <div *ngFor="let attachment of comment.docs">
        <a [href]="attachment.caminhoArquivo" target="_blank" class="d-flex px-1 col">
          <mat-icon class="text-muted" style="min-width: 24px;">file_download</mat-icon>
          <span class="text-decoration-underline text-primary">
            {{ attachment.nomeArquivo }}
          </span>
        </a>
      </div>
    </div>
  </div>
  <div [hidden]="!canComment" class="comment-input">
    <textarea matInput (change)="changeCommentText($event)" [class]="'form-control shadow border-primary ' + (comments && comments.length > 0 ? 'rounded-top-0' : '')"
      placeholder="Escreva aqui o seu comentário.." [rows]="6"></textarea>
    <div class="file-names">
      <a *ngFor="let file of attachmentFiles; let last = last" href="#">
        {{ file.name + (!last ? ',   ' : '') }}
      </a>
    </div>
    <mat-icon (click)="commentAttachments.click()">attachment</mat-icon>
    <input type="file"
      multiple
      (change)="onAttachmentsChange($event)"
      [hidden]="true"
      #commentAttachments
      accept=".pdf,.png,.jpg,.jpeg"
    >
  </div>
</div>
