import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  protected showHeaderMenu!: boolean;

  adminMenuItems: { label: string, link: string, active?: boolean }[] = [
    { label: 'Propostas', link: 'proposals' },
    { label: 'Administradores', link: 'admins', },
    { label: 'Integradores', link: 'integrators', },
    { label: 'Relatórios', link: 'reports', },
    { label: 'Registros', link: 'logs', }
  ];

  constructor(
    private router: Router,
    public sessionService: SessionService,
  ) {}

  get isAdmin() { return this.sessionService.isAdmin; }

  ngOnInit(): void {
    this.showHeaderMenu = true;
    this.router.events.subscribe(event => {
      let currentRoute = (event as NavigationEnd).url;
      if (currentRoute) {
        this.adminMenuItems.map(e => e.active = currentRoute.includes(e.link));
      }
    });
  }

  logOut() {
    this.sessionService.logOut();
    this.router.navigateByUrl('/login');
  }

  get showHeader(): boolean {
    return this.sessionService.isLogged && !this.router.url.includes('login');
  }
}
