<div id="printable-ccb" class="sheet-shadow">
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 1 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <div class="subtitle">I. CÉDULA DE CRÉDITO BANCÁRIO</div>
      <div class="doc-table" *ngIf="tables[0]">
        <div class="doc-row" *ngFor="let row of tables[0].rows; let i = index;">
          <div [class]="'doc-cell col-'+cell.size" *ngFor="let cell of row; let j = index;">
            <label>1.{{ countCellNumber(tables[0], i, j) }}.&nbsp;&nbsp;{{ cell.label }}</label>
            <span>{{ cell.content }}</span>
          </div>
        </div>
      </div>
      <div class="subtitle">II. EMITENTE E FIEL DEPOSITÁRIO DOS BENS DADOS EM GARANTIA</div>
      <div class="doc-table" *ngIf="tables[1]">
        <div class="doc-row" *ngFor="let row of tables[1].rows; let i = index;">
          <div [class]="'doc-cell col-'+cell.size" *ngFor="let cell of row; let j = index;">
            <label>2.{{ countCellNumber(tables[1], i, j) }}.&nbsp;&nbsp;{{ cell.label }}</label>
            <span>{{ cell.content }}</span>
          </div>
        </div>
      </div>
      <div class="subtitle">III. CARACTERÍSTICAS DA OPERAÇÃO E CONDIÇÕES DE PAGAMENTO</div>
      <div class="doc-table" *ngIf="tables[2]">
        <div class="doc-row" *ngFor="let row of tables[2].rows; let i = index;">
          <div [class]="'doc-cell col-'+cell.size" *ngFor="let cell of row; let j = index;">
            <label>3.{{ countCellNumber(tables[2], i, j) }}.&nbsp;&nbsp;{{ cell.label }}</label>
            <span>{{ cell.content }}</span>
          </div>
        </div>
      </div>
      <div class="subtitle">IV. INFORMAÇÕES PARA LIBERAÇÃO DO CRÉDITO</div>
      <div class="doc-table" *ngIf="tables[3]">
        <div class="doc-row" *ngFor="let row of tables[3].rows; let i = index;">
          <div [class]="'doc-cell col-'+cell.size" *ngFor="let cell of row; let j = index;">
            <label>4.{{ countCellNumber(tables[3], i, j) }}.&nbsp;&nbsp;{{ cell.label }}</label>
            <span>{{ cell.content }}</span>
          </div>
        </div>
      </div>
      <div class="subtitle">V. DESCRIÇÃO E LOCAL DOS EQUIPAMENTOS DADOS EM GARANTIA DE ALIENAÇÃO FIDUCIÁRIA</div>
      <div class="doc-table" *ngIf="tables[4]">
        <div class="doc-row" *ngFor="let row of tables[4].rows; let i = index;">
          <div [class]="'doc-cell col-'+cell.size" *ngFor="let cell of row; let j = index;">
            <label>5.{{ countCellNumber(tables[4], i, j) }}.&nbsp;&nbsp;{{ cell.label }}</label>
            <span>{{ cell.content }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 2 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <div class="title">CONDIÇÕES GERAIS - CÉDULA DE CRÉDITO BANCÁRIO</div>
      <app-clause-orderer [clauses]="pageClauses[0]"></app-clause-orderer>
    </div>
  </section>
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 3 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <app-clause-orderer [clauses]="pageClauses[1]" [startClause]="1" [startSubclause]="7"></app-clause-orderer>
    </div>
  </section>
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 4 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <app-clause-orderer [clauses]="pageClauses[2]" [startClause]="2" [startSubclause]="2"></app-clause-orderer>
    </div>
  </section>
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 5 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <app-clause-orderer [clauses]="pageClauses[3]" [startClause]="3" [startSubclause]="9"></app-clause-orderer>
    </div>
  </section>
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 6 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <app-clause-orderer [clauses]="pageClauses[4]" [startClause]="4" [startSubclause]="2"></app-clause-orderer>
    </div>
  </section>
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 7 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <app-clause-orderer [clauses]="pageClauses[5]" [startClause]="5" [startSubclause]="2" [startSubSubclause]="6"></app-clause-orderer>
    </div>
  </section>
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 8 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <app-clause-orderer [clauses]="pageClauses[6]" [startClause]="6" [startSubclause]="6"></app-clause-orderer>
    </div>
  </section>
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 9 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <app-clause-orderer [clauses]="pageClauses[7]" [startClause]="7" [startSubclause]="7"></app-clause-orderer>
    </div>
  </section>
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 10 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <app-clause-orderer [clauses]="pageClauses[8]" [startClause]="7" [startSubclause]="15"></app-clause-orderer>
      <div class="text-center pt-4">Assinaturas</div>
      <div class="text-center pb-3 pt-3"><h5>{{ 'São Paulo, ' + getCurdate + '.' }}</h5></div>
      <div class="signing-area">
        <div class="d-flex justify-content-center w-100">
          <div class="col-5 sign">
            <span class="pt-2">EMITENTE</span>
          </div>
        </div>
        <div class="py-4 font-weight-500">Testemunhas:</div>
        <div class="d-flex justify-content-evenly w-100 pt-0">
          <div class="col-5 d-flex flex-column" style="max-height: 50px;">
            <img src="/assets/img/signings/bruno.png" />
          </div>
          <div class="col-5 d-flex flex-column" style="max-height: 50px;">
            <img src="/assets/img/signings/yuri.png" />
          </div>
        </div>
        <div class="d-flex justify-content-evenly w-100 pt-0">
          <div class="col-5 sign">
            <span class="pt-2">Nome: Bruno Henrique Fonseca Teixeira</span>
            <span>RG nº 40.679.674-9 SSP/SP</span>
          </div>
          <div class="col-5 sign">
            <span class="pt-2">Nome: Yuri Siqueira Faria Inokuti</span>
            <span>RG nº 39.265.240-7 SSP/SP</span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 11 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <div class="title">ANEXO I DA CCB</div>
      <div class="subtitle text-center">(Detalhamento do Quadro III)</div>
      <div class="inline-table">
        <div class="ccb-header px-2 py-3">
          <div class="d-flex justify-content-between">
            <div class="col-6 d-flex">
              <div class="col-6">Data de emissão:</div>
              <div class="col-6">{{ getEmissionDate }}</div>
            </div>
            <div class="col-6 d-flex">
              <div class="col-6">Taxa (%a.m.):</div>
              <div class="col-6">{{ genericFormatter(this.financingOption?.resposta?.Proposta?.PercJurosMensal ?? 0, 'decimal', 4).toString(4) }}</div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="col-6 d-flex">
              <div class="col-6">Data de vencimento:</div>
              <div class="col-6">{{ genericFormatter(ultimaParcela?.DtVencimento ?? '', 'date') }}</div>
            </div>
            <div class="col-6 d-flex">
              <div class="col-6">Taxa (%a.a.):</div>
              <div class="col-6">{{ genericFormatter(financingOption?.resposta?.Proposta?.PercJurosAnual ?? 0, 'decimal', 4).toString(4) }}</div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="col-6 d-flex">
              <div class="col-6">Custo emissão</div>
              <div class="col-6">R$ {{ (fixedParameters ? fixedParameters['tac'] : 0) }}</div>
            </div>
            <div class="col-6 d-flex">
              <div class="col-6">Indexador</div>
              <div class="col-6">PRÉ-FIXADO</div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="col-6 d-flex">
              <div class="col-6">IOF</div>
              <div class="col-6">{{ genericFormatter(this.financingOption?.resposta?.Proposta?.VlrIOF ?? '', 'money') }}</div>
            </div>
            <div class="col-6 d-flex">
              <div class="col-6">CET (%a.m. / %a.a.)</div>
              <div class="col-6">{{ genericFormatter(this.financingOption?.resposta?.Proposta?.PercCETAnual ?? 0, 'decimal', 4).toString()}}% a.a. / {{ genericFormatter(this.financingOption?.resposta?.Proposta?.PercCETMensal ?? 0, 'decimal', 4).toString()}}% a.m.</div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="col-6 d-flex">
              <div class="col-6">Valor da CCB</div>
              <div class="col-6">{{ genericFormatter(totalDebt, 'money') }}</div>
            </div>
            <div class="col-6 d-flex">
              <div class="col-6">Prazo (dias)</div>
              <div class="col-6">{{ prazo }}</div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="col-6 d-flex">
              <div class="col-6">Valor líquido</div>
              <div class="col-6">{{ genericFormatter(this.proposal?.valorTotalInstalacao ?? '', 'money') }}</div>
            </div>
          </div>
        </div>
        <div class="ccb-table px-2" *ngIf="ccbFlux && ccbFlux.length > 0">
          <app-data-table
            [columnsToDisplay]="['select', 'Parcela', 'Dias', 'Data de vencimento', 'Saldo devedor', 'Amortização', 'Juros', 'Valor Parcela']"
            [showExportButtons]="false"
            [showPaginator]="false"
            [showSearchInput]="false"
            [showSorting]="false"
            [data]="ccbFlux.slice(0, 12)"
          ></app-data-table>
        </div>
      </div>
    </div>
  </section>
  <section class="sheet" *ngIf="ccbFlux && ccbFlux.length > 12">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 12 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <div class="inline-table">
        <div class="ccb-table px-2">
          <app-data-table
            [columnsToDisplay]="['select', 'Parcela', 'Dias', 'Data de vencimento', 'Saldo devedor', 'Amortização', 'Juros', 'Valor Parcela']"
            [showExportButtons]="false"
            [showPaginator]="false"
            [showSearchInput]="false"
            [showSorting]="false"
            [data]="ccbFlux.slice(12, 30)"
          ></app-data-table>
        </div>
      </div>
    </div>
  </section>
  <section class="sheet" *ngIf="ccbFlux && ccbFlux.length > 30">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 13 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <div class="inline-table">
        <div class="ccb-table px-2">
          <app-data-table
            [columnsToDisplay]="['select', 'Parcela', 'Dias', 'Data de vencimento', 'Saldo devedor', 'Amortização', 'Juros', 'Valor Parcela']"
            [showExportButtons]="false"
            [showPaginator]="false"
            [showSearchInput]="false"
            [showSorting]="false"
            [data]="ccbFlux.slice(30, 48)"
          ></app-data-table>
        </div>
      </div>
    </div>
  </section>
  <section class="sheet" *ngIf="ccbFlux && ccbFlux.length > 48">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 14 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <div class="inline-table">
        <div class="ccb-table px-2">
          <app-data-table
            [columnsToDisplay]="['select', 'Parcela', 'Dias', 'Data de vencimento', 'Saldo devedor', 'Amortização', 'Juros', 'Valor Parcela']"
            [showExportButtons]="false"
            [showPaginator]="false"
            [showSearchInput]="false"
            [showSorting]="false"
            [data]="ccbFlux.slice(48, 60)"
          ></app-data-table>
        </div>
      </div>
    </div>
  </section>
  <section class="sheet" *ngIf="ccbFlux && ccbFlux.length > 60">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página 15 de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <div class="inline-table">
        <div class="ccb-table px-2">
          <app-data-table
            [columnsToDisplay]="['select', 'Parcela', 'Dias', 'Data de vencimento', 'Saldo devedor', 'Amortização', 'Juros', 'Valor Parcela']"
            [showExportButtons]="false"
            [showPaginator]="false"
            [showSearchInput]="false"
            [showSorting]="false"
            [data]="ccbFlux.slice(60, 72)"
          ></app-data-table>
        </div>
      </div>
    </div>
  </section>
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página {{11 + countCcbFluxPages}} de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <div class="title">ANEXO II DA CCB</div>
      <div class="subtitle text-center">(Nota Fiscal)</div>
    </div>
  </section>
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div>
      <div class="my-auto col"><span class="ms-auto me-0">Página {{12 + countCcbFluxPages}} de {{ 12 + countCcbFluxPages }}</span></div>
    </header>
    <div class="content">
      <div class="title">ANEXO III DA CCB</div>
      <div class="subtitle text-center">(Proposta Comercial)</div>
    </div>
  </section>
</div>
