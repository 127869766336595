import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { cpfMask } from 'src/app/core/helpers/masks';
import { ProposalsService } from 'src/app/services/proposals.service';
import { ProposalStage } from 'src/app/shared/enums/proposal-stage';
import { Proposal } from 'src/app/shared/models/proposal.model';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-view-proposals',
  templateUrl: './view-proposals.component.html',
  styleUrls: ['./view-proposals.component.scss']
})
export class ViewProposalsComponent implements OnInit {
  columnsToDisplay = ['select','ID','NOME','CPF','Etapa', 'INTEGRADOR', 'DATA DE ENTRADA','ações'];
  clients: Proposal[] = []

  constructor(
    private router: Router,
    private loading: LoadingService,
    private notification: NotificationService,
    private service: ProposalsService,
    protected session: SessionService,
  ) {}

  ngOnInit(): void {
    this.getProposals();
  }

  getProposals() {
    this.loading.showLoading(true);
    this.service.getAll().subscribe({
      next: (res) => {
        let isAdmin = this.session.isAdmin;
        let data: any[] = res.map(e => new Proposal().deserialize(e)).sort((a, b) => formatDate(a.criadoEm!, 'yyyy-MM-dd', 'pt-br') > formatDate(b.criadoEm!, 'yyyy-MM-dd', 'pt-br') ? 1 : -1);
        data.map((a: any) => {
          a['ID'] = a.numero ?? '';
          a['NOME'] = a.cliente.ultimoContato?.nome ?? '';
          a['CPF'] = cpfMask(a.cliente.documento ?? '');
          a['Etapa'] = a.propostaEtapa ?? '';
          a['INTEGRADOR'] = a.integrador?.ultimoContato?.nome ?? '';
          a['alerta'] = showAlert(a, isAdmin) ? '0'
            : (!ProposalStage.isRefused(a.propostaEtapa) && a.propostaEtapa !== ProposalStage.LIQUIDADO ? '1'
              : (a.propostaEtapa === ProposalStage.LIQUIDADO ? '2' : '3')),
          a['DATA DE ENTRADA'] = a.criadoEm ? formatDate(a.criadoEm, 'dd/MM/yyyy', 'pt-BR') : '';
        });
        this.clients = data;
      },
      error: (err) => {
        this.notification.error(`Erro ao buscar propostas: ${err}`);
      },
      complete: () => {
        this.loading.showLoading(false);
      }
    })
  }

  clientIcon(row: any) {
    if (showAlert(row, this.session.isAdmin)) {
      return '<img class="pointer" width="38" height="38" src="/assets/icons/alert-eye.svg" />';
    } else if (!ProposalStage.isRefused(row.propostaEtapa)
     && row.propostaEtapa !== ProposalStage.LIQUIDADO) {
      return '<img class="pointer" width="38" height="38" src="/assets/icons/warning-eye.svg" />';
    } else if (row.propostaEtapa === ProposalStage.LIQUIDADO) {
      return '<img class="pointer" width="38" height="38" src="/assets/icons/green-eye.svg" />';
    } else {
      return '<img class="pointer" width="38" height="38" src="/assets/icons/blue-eye.svg" />';
    }
  }

  clickAlert(row: any) {
    this.router.navigateByUrl(`/proposals/${row.uid}/offer`);
  }
}

export function showAlert(row: any, isAdmin: boolean): boolean {
  if (isAdmin) {
    return row.propostaEtapa == ProposalStage.PREANALISEDECREDITO
     || row.propostaEtapa == ProposalStage.APROVACAODECREDITO
     || row.propostaEtapa == ProposalStage.FORMALIZACAO
     || row.propostaEtapa == ProposalStage.PROCESSODELIQUIDACAO;
  } else {
    return row.propostaEtapa == ProposalStage.CADASTRO
     || row.propostaEtapa == ProposalStage.NEGOCIACAOCOMERCIAL;
  }
}
