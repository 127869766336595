import { Options } from '@angular-slider/ngx-slider';
import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/core/helpers/customValidators';
import { hasErrors, inputErrors } from 'src/app/core/helpers/form-functions';
import { genericFormatter } from 'src/app/core/helpers/masks';
import { BureauService } from 'src/app/services/bureau.service';
import { CommentsService } from 'src/app/services/comments.service';
import { ProposalsService } from 'src/app/services/proposals.service';
import { BureauType } from 'src/app/shared/enums/bureau-type';
import { DocumentType } from 'src/app/shared/enums/document-type';
import { EstadoCivil } from 'src/app/shared/enums/estado-civil';
import { FileType } from 'src/app/shared/enums/file-type';
import { ProposalStage } from 'src/app/shared/enums/proposal-stage';
import { Address } from 'src/app/shared/models/address.model';
import { Comment } from 'src/app/shared/models/comment.model';
import { Contact } from 'src/app/shared/models/contact.model';
import { CreditAnalisis } from 'src/app/shared/models/credit-analisis.model';
import { Document } from 'src/app/shared/models/document.model';
import { Proposal } from 'src/app/shared/models/proposal.model';
import { FormalizationRequest } from 'src/app/shared/models/requests/formalization.request';
import { SplitData } from 'src/app/shared/models/split-data.model';
import { State } from 'src/app/shared/models/state.model';
import { User } from 'src/app/shared/models/user.model';
import { CepService } from 'src/app/shared/services/cep.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { jsPDF } from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import { SignDocumentRequest } from 'src/app/shared/models/requests/sign-document.request';
import { forkJoin, from } from 'rxjs';
import { bacenBankList } from 'src/app/core/constants/bacen-bank-list';

@Component({
  selector: 'client-formalization',
  templateUrl: './formalization.component.html',
  styleUrls: ['./formalization.component.scss']
})
export class FormalizationComponent implements OnChanges {
  ProposalStage = ProposalStage;
  FileType = FileType;

  @Input() proposal?: Proposal;
  @Input() latestBureauProposal?: CreditAnalisis;
  @Input() main: boolean = false;
  @Input() disabled: boolean = false;
  @Input() comments?: Comment[];
  @Input() devolving: boolean = false;
  @Input() stateList: State[] = [];
  @Input() fixedParameters: any;

  @Output() devolveStage = new EventEmitter();

  formGroup!: FormGroup;
  comment?: Comment;
  gridInstallments: any;
  protected financingOption?: CreditAnalisis;
  protected ccbFlux: any[] = [];
  protected ccbNumber?: number | string;

  dueOptions: Options = { floor: 12, ceil: 72, noSwitching: true, pushRange: true,
    stepsArray: [12,24,36,48,60,72].map(e => { return { value: e }; }) };
  carencyOptions: Options = { floor: 30, ceil: 120, noSwitching: true, pushRange: true,
    stepsArray: [30,60,90,120].map(e => { return { value: e }; }) };
  splitCodigoBancoOptions: { name: string, value: number }[] = bacenBankList;
  estadoCivilOptions: { name: string, value: EstadoCivil }[] = [
    { name: 'Casado', value: EstadoCivil.CASADO },
    { name: 'Divorciado', value: EstadoCivil.DIVORCIADO },
    { name: 'Separado', value: EstadoCivil.SEPARADO },
    { name: 'Solteiro', value: EstadoCivil.SOLTEIRO },
    { name: 'Viuvo', value: EstadoCivil.VIUVO },
  ];
  tipoDocumentoIdentificacaoOptions: { name: string, value: DocumentType }[] = [
    { name: 'RG', value: DocumentType.RG },
    { name: 'CNH', value: DocumentType.CNH },
  ];
  splitIntegrators: { name: string, value: SplitData }[] = [];
  selectedSplit?: SplitData;

  constructor(
    private router: Router,
    private viacep: CepService,
    private loading: LoadingService,
    private service: ProposalsService,
    protected formBuilder: FormBuilder,
    private bureauService: BureauService,
    private commentService: CommentsService,
    protected sessionService: SessionService,
    private notification: NotificationService,
  ) {
    this.formGroup = formBuilder.group({
      nome: [{value: '', disabled: true}, [Validators.required, Validators.maxLength(64)]],
      nascimento: [{value: '', disabled: true}, [Validators.required, CustomValidators.OlderThan18]],
      cpf: [{value: '', disabled: true}, [Validators.required, CustomValidators.ValidateCpf]],
      email: [{value: '', disabled: true}, [Validators.required, Validators.email, Validators.maxLength(64)]],
      estadoCivil: [{value: '', disabled: true}, Validators.required],
      documento: [{value: '', disabled: true}, [Validators.required, Validators.maxLength(16)]],
      dataEmissao: [{value: '', disabled: true}, [Validators.required, CustomValidators.dateBeforeToday, CustomValidators.OlderThan50]],
      orgaoExpedidor: [{value: '', disabled: true}, [Validators.required, Validators.maxLength(16)]],
      ufrg: [{value: '', disabled: true}, Validators.required],
      cep: [{value: '', disabled: true}, Validators.required],
      numero: [{value: '', disabled: true}, Validators.required],
      complemento: [{value: '', disabled: true}, Validators.maxLength(128)],
      logradouro: [{value: '', disabled: true}, Validators.required],
      bairro: [{value: '', disabled: true}, Validators.required],
      cidade: [{value: '', disabled: true}, Validators.required],

      valorTotalInstalacao: [{value: '', disabled: true}],
      valorIOF: [{value: '', disabled: true}],
      valorTotalDivida: [{value: '', disabled: true}],
      dataDesembolso: [{value: genericFormatter(new Date(), 'date'), disabled: true}],
      prazo: [{value: '', disabled: true}],
      quantidadeParcelas: [{value: '', disabled: true}],
      carencia: [{value: '', disabled: true}],
      vencimentoParcelas: [{value: '', disabled: true}],
      valorParcela: [{value: '', disabled: true}],
      vencimentoPrimeiraParcela: [{value: '', disabled: true}],
      vencimentoUltimaParcela: [{value: '', disabled: true}],
      tac: [{value: 0, disabled: true}], /// Aqui
      jurosAnual: [{value: '', disabled: true}],
      jurosMensal: [{value: '', disabled: true}],
      cet: [{value: '', disabled: true}],
      valorDesembolso: [{value: '', disabled: true}],
      dataEmissaoPagamento: [{value: genericFormatter(new Date(), 'date'), disabled: true}],
      dataVencimento: [{value: '', disabled: true}],

      cepEquipamento: [{ value: '', disabled: true }, Validators.required],
      numeroEquipamento: [{ value: '', disabled: true }, Validators.required],
      complementoEquipamento: [{ value: '', disabled: true }, Validators.maxLength(128)],
      bairroEquipamento: [{ value: '', disabled: true }, Validators.required],
      logradouroEquipamento: [{ value: '', disabled: true }, Validators.required],
      cidadeEquipamento: [{ value: '', disabled: true }, Validators.required],
      estadoEquipamento: [{ value: '', disabled: true }, Validators.required],
      potenciaInstalada: [{ value: '', disabled: true }, Validators.required],
      quantidadePaineis: [{ value: '', disabled: true }, [Validators.required, Validators.min(1), Validators.max(120)]],
      descricaoPaineis: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(128)]],
      quantidadeInversores: [{ value: '', disabled: true }, [Validators.required, Validators.min(1), Validators.max(120)]],
      descricaoInversores: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(128)]],
      demaisItensInstalados: [{ value: '', disabled: true }, Validators.maxLength(128)],

      splitCheck: [{ value: '', disabled: true }],
      splitValue: [{ value: '', disabled: true }],
      splitUid: [{ value: '', disabled: true }],
      splitDocumentoFederal: [{ value: '', disabled: true }],
      splitCodigoBanco: [{ value: '', disabled: true }],
      splitAgencia: [{ value: '', disabled: true }],
      splitContaEDigito: [{ value: '', disabled: true }],
    }, {
      validators: [this.hasSplitData.bind(this)],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    /// Set data
    if (this.proposal && (changes['proposal'])) {
      if (!changes['proposal'].previousValue?.uid) {
        this.getGridInstallments();

        let client: User = this.proposal?.cliente;
        let contact: Contact | null = client.ultimoContato;
        let address: Address | null = client?.ultimoEndereco;
        let installationAddress: Address | undefined = this.proposal?.endereco;

        let estadoUfrg;
        let estadoInstalacao;
        if (this.stateList) {
          if (contact?.ufrg) {
            estadoUfrg = this.stateList.find(e => e.id.toString() == contact?.ufrg?.toString())?.id ?? '';
          }
          if (installationAddress?.estadoId) {
            estadoInstalacao = this.stateList.find(e => e.id.toString() == installationAddress?.estadoId?.toString())?.id ?? '';
          }
        }

        let hasSplit = this.proposal?.splitGuid != null && this.proposal?.splitGuid != "";

        this.formGroup.controls['nome'].setValue(contact?.nome);
        this.formGroup.controls['cpf'].setValue(client.documentoFormatado);
        this.formGroup.controls['nascimento'].setValue(formatDate(client.nascimento ?? new Date(), 'yyyy-MM-dd', 'pt-br'));
        this.formGroup.controls['ufrg'].setValue(estadoUfrg ?? '');
        this.formGroup.controls['email'].setValue(contact?.email ?? '');
        this.formGroup.controls['estadoCivil'].setValue(contact?.estadoCivil ?? '');
        this.formGroup.controls['orgaoExpedidor'].setValue(contact?.orgaExpedidor ?? '');
        this.formGroup.controls['dataEmissao'].setValue(formatDate(contact?.dataEmissaoDocumento ?? '', 'yyyy-MM-dd', 'pt-br'), 'date');
        this.formGroup.controls['documento'].setValue(contact?.documentoIdentificacao ?? '');
        this.formGroup.controls['cep'].setValue(address?.cep ?? '');
        this.formGroup.controls['numero'].setValue(address?.numero ?? '');
        this.formGroup.controls['complemento'].setValue(address?.complemento ?? '');
        this.formGroup.controls['bairro'].setValue(address?.bairro ?? '');
        this.formGroup.controls['logradouro'].setValue(address?.logradouro ?? '');
        this.formGroup.controls['cidade'].setValue(address?.cidade ?? '');
        this.formGroup.controls['valorTotalInstalacao'].setValue(((this.proposal?.valorTotalInstalacao ?? 0) - (this.proposal?.valorEntrada ?? 0)) + 0.001);
        this.formGroup.controls['valorDesembolso'].setValue(this.proposal?.valorTotalInstalacao ?? '');
        this.formGroup.controls['quantidadeParcelas'].setValue(this.proposal?.parcelaSelecionada ?? '');
        this.formGroup.controls['carencia'].setValue(this.proposal?.carenciaSelecionada ?? '');

        this.formGroup.controls['cepEquipamento'].setValue(installationAddress?.cep ?? ''),
        this.formGroup.controls['numeroEquipamento'].setValue(installationAddress?.numero ?? ''),
        this.formGroup.controls['complementoEquipamento'].setValue(installationAddress?.complemento ?? ''),
        this.formGroup.controls['bairroEquipamento'].setValue(installationAddress?.bairro ?? ''),
        this.formGroup.controls['logradouroEquipamento'].setValue(installationAddress?.logradouro ?? ''),
        this.formGroup.controls['cidadeEquipamento'].setValue(installationAddress?.cidade ?? ''),
        this.formGroup.controls['estadoEquipamento'].setValue(estadoInstalacao ?? ''),
        this.formGroup.controls['potenciaInstalada'].setValue(this.proposal?.potenciaInstalada ?? '');
        this.formGroup.controls['descricaoPaineis'].setValue(this.proposal?.descricaoPaineisInstalados ?? '');
        this.formGroup.controls['quantidadePaineis'].setValue(this.proposal?.quantidadePaineisInstalados ?? '');
        this.formGroup.controls['descricaoInversores'].setValue(this.proposal?.descricaoInversoresInstalados ?? '');
        this.formGroup.controls['quantidadeInversores'].setValue(this.proposal?.quantidadeInversoresInstalados ?? '');
        this.formGroup.controls['demaisItensInstalados'].setValue(this.proposal?.demaisItensInstalados ?? '');

        if (hasSplit) {
          this.getSplitList();
          this.formGroup.controls['splitCheck'].setValue(hasSplit);
          this.formGroup.controls['splitValue'].setValue(this.proposal.valorSplit);
          this.formGroup.controls['splitUid'].setValue(this.proposal.splitGuid);
        }

        if (this.proposal.propostaEtapa == ProposalStage.FORMALIZACAO
         && this.sessionService.isAdmin) {
          this.formGroup.controls['nome'].enable();
          this.formGroup.controls['cpf'].enable();
          this.formGroup.controls['nascimento'].enable();
          this.formGroup.controls['ufrg'].enable();
          this.formGroup.controls['email'].enable();
          this.formGroup.controls['estadoCivil'].enable();
          this.formGroup.controls['orgaoExpedidor'].enable();
          this.formGroup.controls['dataEmissao'].enable();
          this.formGroup.controls['documento'].enable();
          this.formGroup.controls['cep'].enable();
          this.formGroup.controls['numero'].enable();
          this.formGroup.controls['complemento'].enable();
          this.formGroup.controls['bairro'].enable();
          this.formGroup.controls['logradouro'].enable();
          this.formGroup.controls['cidade'].enable();
          this.formGroup.controls['cepEquipamento'].enable();
          this.formGroup.controls['numeroEquipamento'].enable();
          this.formGroup.controls['complementoEquipamento'].enable();
          this.formGroup.controls['bairroEquipamento'].enable();
          this.formGroup.controls['logradouroEquipamento'].enable();
          this.formGroup.controls['cidadeEquipamento'].enable();
          this.formGroup.controls['estadoEquipamento'].enable();
          this.formGroup.controls['potenciaInstalada'].enable();
          this.formGroup.controls['descricaoPaineis'].enable();
          this.formGroup.controls['quantidadePaineis'].enable();
          this.formGroup.controls['descricaoInversores'].enable();
          this.formGroup.controls['quantidadeInversores'].enable();
          this.formGroup.controls['demaisItensInstalados'].enable();
          this.formGroup.controls['splitValue'].enable();
          this.formGroup.controls['splitUid'].enable();
          this.formGroup.controls['splitDocumentoFederal'].enable();
          this.formGroup.controls['splitCodigoBanco'].enable();
          this.formGroup.controls['splitAgencia'].enable();
          this.formGroup.controls['splitContaEDigito'].enable();
        }
      }
    }

    if (this.proposal?.propostaEtapa == ProposalStage.FORMALIZACAO) {
      this.proposal!.numeroCcb = undefined;
      this.formGroup.controls['dataDesembolso'].setValue('');
      // this.formGroup.controls['dataEmissaoPagamento'].setValue('');
    } else if (changes['latestBureauProposal']) {
      this.formGroup.controls['dataDesembolso'].setValue(genericFormatter(this.latestBureauProposal?.criadoEm, 'date'));
      this.formGroup.controls['dataEmissaoPagamento'].setValue(genericFormatter(this.latestBureauProposal?.criadoEm, 'date'));
    }
    if (changes['fixedParameters']) {
      this.formGroup.controls['tac'].setValue(this.fixedParameters ? this.fixedParameters['tac'] : 0);
    }
  }

  async onSubmit(changeStage: boolean = true) {
    const values = this.formGroup.value;

    let payload: FormalizationRequest = {
      propostaGuid: this.proposal!.uid!,
      potenciaInstalada: values['potenciaInstalada'],
      quantidadePaineisInstalados: values['quantidadePaineis'],
      quantidadeInversoresInstalados: values['quantidadeInversores'],
      demaisItensInstalados: values['demaisItensInstalados'],
      descricaoPaineisInstalados: values['descricaoPaineis'],
      descricaoInversoresInstalados: values['descricaoInversores'],
      cpf: values['cpf'],
      dataNascimento: values['nascimento'],
      nome: values['nome'],
      email: values['email'],
      documento: values['documento'],
      dataEmissaoDocumento: values['dataEmissao'],
      estadoCivil: values['estadoCivil'],
      estadoEmissorId: values['ufrg'],
      orgaoExpedidor: values['orgaoExpedidor'],
      cepEquipamento: values['cepEquipamento'],
      ruaEquipamento: values['logradouroEquipamento'],
      numeroEquipamento: values['numeroEquipamento'],
      complementoEquipamento: values['complementoEquipamento'],
      bairroEquipamento: values['bairroEquipamento'],
      cidadeEquipamento: values['cidadeEquipamento'],
      estadoId: values['estadoEquipamento']
    };

    if (this.formGroup.controls['splitCheck'].value) {
      payload.valorSplit = values['splitValue'];
      payload.dadosSplitGuid = values['splitUid'];
      payload.splitGuid = values['splitUid'];
      payload.codigoBanco = values['splitCodigoBanco'];
      payload.tipoConta = this.selectedSplit?.tipoConta;
      payload.agencia = values['splitAgencia'];
      payload.conta = values['splitContaEDigito'].toString().split('-')[0];
      payload.contaDigito = values['splitContaEDigito'].toString().split('-')[1];
      payload.documentoFederal = values['splitDocumentoFederal'];
      payload.nomePagamento = this.selectedSplit?.nomePagamento;
    }

    this.loading.showLoading(true);
    this.service.formalization(changeStage, payload).subscribe({
      next: (res) => {
        if (this.comment) {
          this.comment = new Comment().deserialize(this.comment);
          this.comment.propostaEtapa = ProposalStage.FORMALIZACAO;
          this.comment.propostaNumero = this.proposal!.numero!;
          this.commentService.store(this.comment).subscribe();
        }

        if (changeStage) {
          this.ccbNumber = res.numeroCcb;
          this.formGroup!.controls['dataEmissaoPagamento'].setValue(genericFormatter(new Date(), 'date'));
          this.proposal!.numeroCcb = res.numeroCcb;

          setTimeout(() => {
            this.sendDocumentsToSign(res);

            this.notification.success('Proposta formalizada com sucesso.');
            this.router.navigateByUrl('/proposals');
          }, 3000);
        } else {
          location.reload();
        }
      },
      error: (err) => {
        this.notification.error('Erro ao concluir formalização.');
      },
      complete: () => {
        this.loading.showLoading(false);
      }
    });
  }

  devolve() {
    this.devolveStage.emit(ProposalStage.APROVACAODECREDITO)
  }

  commentAndReturn() {
    this.loading.showLoading(true);
    if (this.proposal === undefined || this.proposal === null) {
      this.notification.error('Não existe proposta cadastrada!');
      this.loading.showLoading(false);
    } else if (!this.comment || !this.comment.descricao || this.comment.descricao == "") {
      this.notification.error('Insira um comentário de justificativa.');
      this.loading.showLoading(false);
    } else {
      this.comment = new Comment().deserialize(this.comment);
      this.comment.propostaEtapa = ProposalStage.FORMALIZACAO;
      this.comment.propostaNumero = this.proposal!.numero!;
      this.commentService.store(this.comment).subscribe();

      let newStage = ProposalStage.toNumber(ProposalStage.FORMALIZACAO);
      this.service.changeStage(this.proposal!.uid!, { propostaEtapa: newStage }).subscribe({
        next: (res) => {
          this.notification.success('Proposta retornada com sucesso.');
          this.router.navigateByUrl('/proposals');
        },
        error: (err) => {
          this.notification.error('Erro ao retornar etapa: ' + err);
        },
        complete: () => {
          this.loading.showLoading(false);
        }
      });
    }
  }

  get disabledForm(): boolean {
    return this.proposal?.propostaEtapa != ProposalStage.FORMALIZACAO
      || !this.sessionService.isAdmin;
  }
  get canComment(): boolean {
    return (this.proposal?.propostaEtapa === ProposalStage.FORMALIZACAO && this.sessionService.isAdmin)
     || this.devolving;
  }

  get formValid(): any { return this.formGroup.valid; }
  hasErrors(control: string, isRequired: boolean = false): string | null | void {
    if (this.formGroup.controls[control]) {
      return hasErrors(this.formGroup.controls[control], isRequired);
    }
  }
  inputErrors(control: string): string {
    if (this.formGroup.controls[control]) {
      return inputErrors(this.formGroup.controls[control]);
    }
    return '';
  }

  private async sendDocumentsToSign(res: Proposal) {
    const documentoCcb = from(this.generatePdf('ccb'));
    const dacaoEmPagamento = from(this.generatePdf('dacao-em-pagamento'));
    const alienacaoFiduciaria = from(this.generatePdf('alienacao-fiduciaria'));

    forkJoin([documentoCcb, dacaoEmPagamento, alienacaoFiduciaria]).subscribe(([ccbFile, dacaoFile, alienacaoFile]) => {
      const payloadDocs: SignDocumentRequest = new SignDocumentRequest().deserialize({
        documentoCcb: ccbFile,
        dacaoEmPagamento: dacaoFile,
        alienacaoFiduciaria: alienacaoFile
      });
      this.service.sendDocumentToSign(res.guid ?? res.uid ?? this.proposal!.uid!,
        payloadDocs).subscribe();
    });
  }

  getSplitList() {
    this.service.splitDataList().subscribe({
      next: (res) => {
        this.splitIntegrators = res.map(e => {
          return { name: e.nomePagamento ?? '', value: new SplitData().deserialize(e) }
        });

        this.selectedSplit = this.splitIntegrators.find(e => e.value.uid == this.proposal?.splitGuid)?.value;

        const codigoBancoAsNumber: number | undefined = Number(this.selectedSplit?.codigoBanco);

        this.formGroup.controls['splitDocumentoFederal'].setValue(this.selectedSplit?.documentoFederal);
        this.formGroup.controls['splitCodigoBanco'].setValue(codigoBancoAsNumber);
        this.formGroup.controls['splitAgencia'].setValue(this.selectedSplit?.agencia);
        this.formGroup.controls['splitContaEDigito'].setValue(this.selectedSplit?.contaEDigito);
      },
    })
  }

  getGridInstallments() {
    if (this.proposal?.cliente?.uid) {
      this.loading.showLoading(true);
      this.bureauService.getBureau(this.proposal!.cliente.uid, BureauType.GRIDPARCELAS).subscribe({
        next: (res) => {
          if (res.length > 0) {
            this.gridInstallments = {};
            let data = res.map(e => new CreditAnalisis().deserialize(e));

            this.carencyOptions.stepsArray?.forEach(carency => {
              let filterCarency = [this.proposal?.carenciaMinima ?? 30, this.proposal?.carenciaMaxima ?? 120];
              if (carency.value >= filterCarency[0] && carency.value <= filterCarency[1]) {
                let filter = data.filter(e => (e.requisição?.dto.NroDiasAcrescimo + 30) == carency.value);
                if (filter.length > 0) {
                  this.gridInstallments[carency.value] = {};
                  this.dueOptions.stepsArray?.forEach(due => {
                    let filterDue = [this.proposal?.parcelaMinima ?? 12, this.proposal?.parcelaMaxima ?? 72];
                    if (due.value >= filterDue[0] && due.value <= filterDue[1]) {
                      let dueFilter = filter.filter(e => e.requisição?.dto.Prazo == due.value);
                      dueFilter.sort((a,b) => Date.parse(a.criadoEm!) <= Date.parse(b.criadoEm!) ? 1 : -1);
                      if (dueFilter.length > 0) {
                        this.gridInstallments[carency.value][due.value]
                          = dueFilter[0];
                      }
                    }
                  })
                }
              }
            });

            if (this.gridInstallments && this.proposal?.carenciaSelecionada && this.proposal?.parcelaSelecionada && this.gridInstallments[this.proposal.carenciaSelecionada][this.proposal.parcelaSelecionada]) {
              this.financingOption = this.gridInstallments[this.proposal!.carenciaSelecionada!][this.proposal!.parcelaSelecionada!];

              let primeiraParcela = this.financingOption?.resposta?.Proposta?.Parcelas[0];
              let ultimaParcela = this.financingOption?.resposta?.Proposta?.Parcelas[this.financingOption?.resposta?.Proposta?.Parcelas.length - 1];
              let dueDay = primeiraParcela?.DtVencimento.toString().split('T')[0];
              dueDay = dueDay?.split('-')[dueDay.split('-').length - 1];
              let prazo = (Math.ceil(((new Date(ultimaParcela?.DtVencimento ?? new Date()) as any) - (new Date() as any))) / (1000 * 60 * 60 * 24)).toFixed(0)
              let totalDebt = parseFloat((this.proposal?.valorTotalInstalacao ?? 0).toString())
                + parseFloat((this.financingOption?.resposta?.Proposta?.VlrIOF ?? 0).toString())
                + (this.fixedParameters ? this.fixedParameters['tac'] : 0);
              totalDebt -= (this.proposal?.valorEntrada ?? 0);

              this.formGroup.controls['valorTotalDivida'].setValue(totalDebt + 0.001);
              this.formGroup.controls['valorIOF'].setValue((this.financingOption?.resposta?.Proposta?.VlrIOF ?? 0) + 0.001);
              this.formGroup.controls['vencimentoUltimaParcela'].setValue(genericFormatter(ultimaParcela?.DtVencimento ?? new Date(), 'date'));
              this.formGroup.controls['vencimentoPrimeiraParcela'].setValue(genericFormatter(primeiraParcela?.DtVencimento ?? new Date(), 'date'));
              this.formGroup.controls['dataVencimento'].setValue(genericFormatter(ultimaParcela?.DtVencimento ?? new Date(), 'date'));
              this.formGroup.controls['prazo'].setValue(prazo);
              this.formGroup.controls['vencimentoParcelas'].setValue("Todo dia "+dueDay+" ou dia útil subsequente.");
              this.formGroup.controls['valorParcela'].setValue(this.financingOption?.resposta?.Proposta?.VlrParcela);
              this.formGroup.controls['jurosAnual'].setValue(genericFormatter(this.financingOption?.resposta?.Proposta?.PercJurosAnual ?? 0, 'decimal', 4).toString(4));
              this.formGroup.controls['jurosMensal'].setValue(genericFormatter(this.financingOption?.resposta?.Proposta?.PercJurosMensal ?? 0, 'decimal', 4).toString(4));
              this.formGroup.controls['cet'].setValue(`${genericFormatter(this.financingOption?.resposta?.Proposta?.PercCETAnual ?? 0, 'decimal', 4).toString()}% a.a. / ${genericFormatter(this.financingOption?.resposta?.Proposta?.PercCETMensal ?? 0, 'decimal', 4).toString()}% a.m.`);
            }
            this.setQuadroCCB();
          }
        },
        error: (err) => {
          this.notification.error('Erro ao trazer tabela de parcelas: ' + err)
        },
        complete: () => {
          this.loading.showLoading(false);
        }
      });
    }
  }

  setQuadroCCB() {
    const dados = this.financingOption?.resposta?.Proposta?.Parcelas ?? [];

    dados.map((a: any) => {
      a['Data de vencimento'] = genericFormatter(a['DtVencimento'], 'date'),
      a['Amortização'] = genericFormatter(a['VlrAmortizacao'] ?? 0, 'money'),
      a['Juros'] = genericFormatter(a['VlrJuros'] ?? 0, 'money'),
      a['Valor Parcela'] = genericFormatter(a['VlrPrestacaoTotal'] ?? 0, 'money'),

      a['Saldo devedor'] = genericFormatter(a['VlrSaldoDevedor'] ?? 0, 'money');
    });

    this.ccbFlux = dados;
  }

  hasSplitData(formGroup: FormGroup) {
    let hasSplit = formGroup.get('splitCheck');
    let splitValue = formGroup.get('splitValue');
    let splitUid = formGroup.get('splitUid');
    let splitDocumentoFederal = formGroup.get('splitDocumentoFederal');
    let splitCodigoBanco = formGroup.get('splitCodigoBanco');
    let splitAgencia = formGroup.get('splitAgencia');
    let splitContaEDigito = formGroup.get('splitContaEDigito');
    let valorDesembolso = formGroup.get('valorDesembolso');
    if (hasSplit && hasSplit.value === true) {
      if (!splitValue?.value) {
        splitValue?.setErrors({ required: true });
      } else if ((valorDesembolso?.value ?? 0) <= (splitValue?.value ?? 0)) {
        splitValue?.setErrors({ 'valor do split não pode ser maior ou igual que o valor total da instalação': true });
      } else {
        splitValue?.setErrors(null);
      }
      if (!splitUid?.value) {
        splitUid?.setErrors({ required: true });
      } else {
        splitUid?.setErrors(null);
      }

      var splitIntegrators = this.splitIntegrators?.map(e => e.value) ?? [];
      if (!splitDocumentoFederal?.value) {
        splitDocumentoFederal?.setErrors({ required: true });
      } else if (splitIntegrators?.length > 0 && splitIntegrators
        .filter(e => e.documentOnlyNumber == splitDocumentoFederal?.value.replace(/[^0-9]/g, '')
          && e.uid != splitUid?.value).length > 0
      ) {
        splitDocumentoFederal?.setErrors({ 'documento já associado à outro registro': true });
      } else {
        splitDocumentoFederal?.setErrors(CustomValidators.ValidateDocument(splitDocumentoFederal));
      }
      if (!splitCodigoBanco?.value) {
        splitCodigoBanco?.setErrors({ required: true });
      } else {
        splitCodigoBanco?.setErrors(null);
      }
      if (!splitAgencia?.value) {
        splitAgencia?.setErrors({ required: true });
      } else {
        splitAgencia?.setErrors(null);
      }
      if (!splitContaEDigito?.value) {
        splitContaEDigito?.setErrors({ required: true });
      } else {
        splitContaEDigito?.setErrors(null);
      }
    } else {
      splitValue?.setErrors(null);
      splitUid?.setErrors(null);
      splitDocumentoFederal?.setErrors(null);
      splitCodigoBanco?.setErrors(null);
      splitAgencia?.setErrors(null);
      splitContaEDigito?.setErrors(null);
    }
  }

  generatePdf(value: 'alienacao-fiduciaria' | 'dacao-em-pagamento' | 'ccb'): Promise<File | undefined> {
    return new Promise((resolve) => {
      const div: HTMLElement | null = document.getElementById("printable-" + value);
      if (div) {
        if (div.classList) {
          div.classList.remove('sheet-shadow');
        }

        var options = {
          filename: value+'.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          // html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };

        setTimeout(() => {
          html2pdf().set(options).from(div).toPdf().output('blob').then((data: Blob) => {
            var file = new File([data], value+'.pdf', { type: 'application/pdf' });
            resolve(file);
          }).catch((err) => {
            console.error(err);
            resolve(undefined);
          });
        }, 300);
      } else {
        resolve(undefined);
      }
    });
  }

  printDocument(value: 'alienacao-fiduciaria' | 'dacao-em-pagamento' | 'ccb'): void {
    if (!this.formValid) {
      this.notification.error('Preencha todos os campos corretamente para realizar a impressão.');
      return;
    }
    const div = document.getElementById("printable-"+value);
    if (div) {
      document.title = value;
      const conteudoDiv = div.innerHTML;
      const conteudoOriginal = document.body.innerHTML;

      document.body.innerHTML = conteudoDiv;
      window.print();
      document.body.innerHTML = conteudoOriginal;
      document.title = 'BSF Crédito'

      this.onSubmit(false);
    }
  }

  getDoc(value: FileType): Document | undefined {
    let files = this.proposal?.cliente?.documentos.filter(e => e.tipoArquivo == value) ?? [];
    if (files && files.length > 0) {
      return files[files!.length - 1];
    } else {
      return undefined;
    }
  }

  onChangeCep(event: any, cepEquipamento = false) {
    const cep = event.target.value?.replace("-", "");
    if (cep && cep.length >= 8) {
      let controlSuffix = cepEquipamento ? 'Equipamento' : '';
      this.viacep.buscar(cep).subscribe({
        next: (res) => {
          if (res.erro) {
            this.notification.error('Erro ao buscar CEP, preencha manualmente');
          }
          if (res?.cep) {
            this.formGroup.controls['cep' + controlSuffix].setValue(res?.cep);
          }
          if (res?.bairro) {
            this.formGroup.controls['bairro' + controlSuffix].setValue(res?.bairro);
          } else {
            this.formGroup.controls['bairro' + controlSuffix].enable();
          }
          if (res?.localidade) {
            this.formGroup.controls['cidade' + controlSuffix].setValue(res?.localidade);
          } else {
            this.formGroup.controls['cidade' + controlSuffix].enable();
          }
          if (res?.logradouro) {
            this.formGroup.controls['logradouro' + controlSuffix].setValue(res?.logradouro);
          } else {
            this.formGroup.controls['logradouro' + controlSuffix].enable();
          }
          // if (res?.uf) {
          //   this.formGroup.controls['estado' + controlSuffix].setValue(this.stateList.find((e) => e.nome.includes(res!.uf!))?.id);
          // } else {
          //   this.formGroup.controls['estado' + controlSuffix].enable();
          // }
        },
        error: (err: any) => {
          this.notification.error('Erro ao buscar cep, por favor preencha os dados manualmente.' + err);
          this.formGroup.controls['bairro' + controlSuffix].enable();
          this.formGroup.controls['cidade' + controlSuffix].enable()
          this.formGroup.controls['logradouro' + controlSuffix].enable()
          this.formGroup.controls['estado' + controlSuffix].enable()
        }
      });
    }
  }

  getFullAddress(address?: Address): string {
    let retorno = address?.logradouro ?? '';
    if (address?.numero) { retorno += ', '+address.numero; }
    if (address?.bairro) { retorno += '- '+address.bairro; }
    if (address?.cidade) { retorno += ', '+address.cidade; }
    if (address?.estadoId) { retorno += '- '+this.stateList.find(e => e.id.toString() == address?.estadoId?.toString())?.sigla ?? ''; }
    if (address?.cep) { retorno += ', '+address.cep; }
    return retorno;
  }

  genericFormatter(value: any, format: "date" | "money" | "decimal", decimalPoints = 0) {
    return genericFormatter(value, format, decimalPoints);
  }

  changeIntegrator() {
    var uid = this.formGroup.controls['splitUid'].value;
    if (uid) {
      this.selectedSplit = this.splitIntegrators.find(e => e.value.uid == uid)?.value;
      this.formGroup.controls['splitDocumentoFederal'].setValue(this.selectedSplit?.documentoFederal);

      const codigoBancoAsNumber: number | undefined = Number(this.selectedSplit?.codigoBanco);

      this.formGroup.controls['splitCodigoBanco'].setValue(codigoBancoAsNumber);
      this.formGroup.controls['splitAgencia'].setValue(this.selectedSplit?.agencia);
      this.formGroup.controls['splitContaEDigito'].setValue(this.selectedSplit?.contaEDigito);
    } else {
      this.selectedSplit = undefined;
    }
  }
}
