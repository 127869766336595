<div class="d-grid">
  <div class="card shadow border-0 w-75 mx-auto mt-4 p-4" style="max-width: 70rem; min-width: 70rem;">
    <app-proposal-stepper [currentIndex]="stageNumber + 1"></app-proposal-stepper>
  </div>
  <div class="card shadow border-0 w-75 mx-auto mt-4 d-flex" style="max-width: 70rem; min-width: 70rem;">
    <div class="card-header border-primary p-3">
      <div class="h2 title mb-0">Proposta {{ proposal?.numero != undefined ? '#'+proposal!.numero : '' }} {{ proposal?.cliente?.ultimoContato?.nome != undefined ? '- '+proposal!.cliente!.ultimoContato!.nome : '' }}</div>
    </div>
    <div class="card-body p-4">
      <client-register
        [comments]="filtereComments(ProposalStage.CADASTRO)"
        [proposal]="proposal"
        [main]="isMain(ProposalStage.CADASTRO)"
        [disabled]="isDisabled(ProposalStage.CADASTRO)"
        [devolving]="devolving == ProposalStage.CADASTRO"
      ></client-register>
      <client-pre-analisis
        [comments]="filtereComments(ProposalStage.PREANALISEDECREDITO)"
        [proposal]="proposal"
        [main]="isMain(ProposalStage.PREANALISEDECREDITO)"
        [disabled]="isDisabled(ProposalStage.PREANALISEDECREDITO)"
        [devolving]="devolving == ProposalStage.PREANALISEDECREDITO"
        (devolveStage)="devolveStage($event)"
      ></client-pre-analisis>
      <client-negociation
        [comments]="filtereComments(ProposalStage.NEGOCIACAOCOMERCIAL)"
        [proposal]="proposal"
        [stateList]="stateList"
        [main]="isMain(ProposalStage.NEGOCIACAOCOMERCIAL)"
        [disabled]="isDisabled(ProposalStage.NEGOCIACAOCOMERCIAL)"
        [devolving]="devolving == ProposalStage.NEGOCIACAOCOMERCIAL"
        (devolveStage)="devolveStage($event)"
      ></client-negociation>
      <client-credit-approval
        [comments]="filtereComments(ProposalStage.APROVACAODECREDITO)"
        [proposal]="proposal"
        [stateList]="stateList"
        [main]="isMain(ProposalStage.APROVACAODECREDITO)"
        [disabled]="isDisabled(ProposalStage.APROVACAODECREDITO)"
        [devolving]="devolving == ProposalStage.APROVACAODECREDITO"
        (devolveStage)="devolveStage($event)"
      ></client-credit-approval>
      <client-formalization
        [comments]="filtereComments(ProposalStage.FORMALIZACAO)"
        [proposal]="proposal"
        [stateList]="stateList"
        [fixedParameters]="fixedParameters"
        [latestBureauProposal]="bureauiIncludeProposals[bureauiIncludeProposals.length - 1]"
        [main]="isMain(ProposalStage.FORMALIZACAO)"
        [disabled]="isDisabled(ProposalStage.FORMALIZACAO)"
        [devolving]="devolving == ProposalStage.FORMALIZACAO"
        (devolveStage)="devolveStage($event)"
      ></client-formalization>
      <client-liquidation
        [comments]="filtereComments(ProposalStage.PROCESSODELIQUIDACAO)"
        [proposal]="proposal"
        [stateList]="stateList"
        [fixedParameters]="fixedParameters"
        [latestBureauProposal]="bureauiIncludeProposals[bureauiIncludeProposals.length - 1]"
        [main]="isMain(ProposalStage.PROCESSODELIQUIDACAO)"
        [disabled]="isDisabled(ProposalStage.PROCESSODELIQUIDACAO)"
        (devolveStage)="devolveStage($event)"
      ></client-liquidation>
    </div>
  </div>
</div>
