import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { cpfMask, genericFormatter } from 'src/app/core/helpers/masks';
import { StateService } from 'src/app/services/state.service';
import { Address } from 'src/app/shared/models/address.model';
import { Contact } from 'src/app/shared/models/contact.model';
import { CreditAnalisis } from 'src/app/shared/models/credit-analisis.model';
import { Proposal } from 'src/app/shared/models/proposal.model';
import { State } from 'src/app/shared/models/state.model';
import { User } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-doc-dacao-em-pagamento',
  templateUrl: './doc-dacao-em-pagamento.component.html',
  styleUrls: ['./doc-dacao-em-pagamento.component.scss']
})
export class DocDacaoEmPagamentoComponent implements OnInit, OnChanges {
  @Input() proposal?: Proposal;
  @Input() emissionDate?: any;
  @Input() formGroup?: FormGroup;
  @Input() financingOption?: CreditAnalisis;
  @Input() stateList: State[] = [];
  @Input() fixedParameters: any;

  tables: {
    rows: {
      size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
      label: string;
      content?: any;
    }[][]
  }[] = [];
  pageClauses: {
    text?: string;
    children?: {
      text?: string;
      children?: {
        text: string;
        children?: string[]
      }[]
    }[]
  }[][] = [];

  constructor(private stateService: StateService) {
    this.makeClauses();
  }

  ngOnInit(): void {
    this.formGroup?.valueChanges.subscribe(val => {
      if (this.proposal) {
        this.makeTables();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.proposal && (changes['proposal'] || changes['financingOption'] || changes['fixedParameters'])) {
      if (!changes['proposal']?.previousValue?.uid
       || (changes['financingOption'].previousValue == undefined
            && changes['financingOption'].currentValue != undefined)
      ) {
        this.makeTables();
      }
    }
  }

  makeTables() {
    let client = this.proposal!.cliente;
    let contact: Contact | null = client.ultimoContato;
    let address: Address | null = client?.ultimoEndereco;
    let installationAddress: Address | undefined = this.proposal?.endereco;

    let estado;
    let estadoUfrg;
    if (this.stateList) {
      if (address?.estadoId) {
        estado = this.stateList.find(e => e.id.toString() == address?.estadoId?.toString())?.sigla ?? '';
      }
      if (contact?.ufrg) {
        estadoUfrg = this.stateList.find(e => e.id.toString() == (this.formGroup?.controls['ufrg'].value ?? contact?.ufrg)?.toString()
        )?.sigla ?? '';
      }
    }

    let primeiraParcela = this.financingOption?.resposta?.Proposta?.Parcelas[0];
    let ultimaParcela = this.financingOption?.resposta?.Proposta?.Parcelas[this.financingOption?.resposta?.Proposta?.Parcelas.length - 1];
    let dueDay = primeiraParcela?.DtVencimento.toString().split('T')[0];
    dueDay = dueDay?.split('-')[dueDay.split('-').length - 1];
    let prazo = (Math.ceil(((new Date(ultimaParcela?.DtVencimento ?? new Date()) as any) - (new Date() as any))) / (1000 * 60 * 60 * 24)).toFixed(0)
    let totalDebt = parseFloat((this.proposal?.valorTotalInstalacao ?? 0).toString())
      + parseFloat((this.financingOption?.resposta?.Proposta?.VlrIOF ?? 0).toString())
      + (this.fixedParameters ? this.fixedParameters['tac'] : 0);
    totalDebt -= this.proposal?.valorEntrada ?? 0;

    this.tables = [];

    /// Emitente
    this.tables.push({
      rows: [
        [
          {
            label: 'Nome:',
            content: this.formGroup?.controls['nome'].value ?? contact?.nome,
            size: 9
          },
          {
            label: 'CPF:',
            content: cpfMask(this.formGroup?.controls['cpf'].value ?? client?.documento),
            size: 3
          }
        ],
        [
          {
            label: 'Dt Nasc:',
            content: genericFormatter(this.formGroup?.controls['nascimento'].value ?? client?.nascimento, 'date'),
            size: 2
          },
          {
            label: 'Estado Civil:',
            content: this.formGroup?.controls['estadoCivil'].value ?? contact?.estadoCivil ?? '',
            size: 2
          },
          {
            label: 'Doc. Identidade:',
            content: this.formGroup?.controls['documento'].value ?? contact?.documentoIdentificacao ?? '',
            size: 3
          },
          {
            label: 'Emissor/UF:',
            content: estadoUfrg ?? '',
            size: 2
          },
          {
            label: 'Data da Emissão:',
            content: genericFormatter(this.formGroup?.controls['dataEmissao'].value ?? contact?.dataEmissaoDocumento ?? '', 'date'),
            size: 3
          }
        ],
        [
          {
            label: 'Endereço:',
            content: (this.formGroup?.controls['logradouro'].value ?? address?.logradouro ?? '') + ', ' + (this.formGroup?.controls['numero'].value ?? address?.numero) + (this.formGroup?.controls['complemento'].value || address?.complemento ? (', ' + (this.formGroup?.controls['complemento'].value ?? address?.complemento)) : ''),
            size: 9
          },
          {
            label: 'Bairro:',
            content: this.formGroup?.controls['bairro'].value ?? address?.bairro ?? '',
            size: 3
          }
        ],
        [
          {
            label: 'Cidade:',
            content: this.formGroup?.controls['cidade'].value ?? address?.cidade ?? '',
            size: 7
          },
          {
            label: 'Estado:',
            content: estado,
            size: 2
          },
          {
            label: 'CEP:',
            content: (this.formGroup?.controls['cep'].value ?? address?.cep)?.replace(/^([\d]{2})\.*([\d]{3})-*([\d]{3})/, "$1$2-$3"),
            size: 3
          }
        ],
        [
          {
            label: 'E-mail:',
            content: this.formGroup?.controls['email'].value ?? contact?.email ?? '',
            size: 12
          }
        ]
      ]
    });

    /// Credor
    this.tables.push({
      rows: [
        [
          {
            label: "Nome:",
            content: "BMP SOCIEDADE DE CREDITO DIRETO S/A",
            size: 8
          },
          {
            label: "CNPJ:",
            content: "34.337.707/0001-00",
            size: 4
          }
        ],
        [
          {
            label: "Endereço:",
            content: "Paulista, 1765, 1º Andar",
            size: 8
          },
          {
            label: "Bairro:",
            content: "Cerqueira César",
            size: 4
          }
        ],
        [
          {
            label: "Cidade:",
            content: "São Paulo",
            size: 8
          },
          {
            label: "Estado:",
            content: "SP",
            size: 2
          },
          {
            label: "CEP:",
            content: "01311-200",
            size: 2
          }
        ],
        [
          {
            label: "E-mail:",
            content: "atendimento@moneyp.com.br",
            size: 12
          }
        ]
      ]
    });

    /// Dados da <b>CCB</b>
    this.tables.push({
      rows: [
        [
          {
            label: "Valor Líquido do Crédito:",
            content: genericFormatter(((this.proposal?.valorTotalInstalacao ?? 0) - (this.proposal?.valorEntrada ?? 0)) ?? '', 'money'),
            size: 4
          },
          {
            label: "Valor do IOF:",
            content: genericFormatter(this.financingOption?.resposta?.Proposta?.VlrIOF ?? '', 'money'),
            size: 4
          },
          {
            label: "Valor Total da Dívida:",
            content: genericFormatter(totalDebt ?? '', 'money'),
            size: 4
          }
        ],
        [
          {
            label: "Data de Desembolso:",
            content: this.formGroup?.controls['dataDesembolso'].value ?? this.emissionDate ?? this.getCurdate,
            size: 4
          },
          {
            label: "Prazo (dias):",
            content: prazo,
            size: 4
          },
          {
            label: "Quantidade de Parcelas:",
            content: (this.proposal?.parcelaSelecionada ?? '').toString(),
            size: 4
          }
        ],
        [
          {
            label: "Carência (dias):",
            content: (this.proposal?.carenciaSelecionada ?? '').toString(),
            size: 4
          },
          {
            label: "Forma de Pagamento das Parcelas:",
            content: "Boleto, TED ou PIX",
            size: 4
          },
          {
            label: "Vencimento das Parcelas:",
            content: "Todo dia " + dueDay + " ou dia útil subsequente.",
            size: 4
          }
        ],
        [
          {
            label: "Vencimento da Primeira Parcela:",
            content: genericFormatter(primeiraParcela?.DtVencimento ?? new Date(), 'date'),
            size: 4
          },
          {
            label: "Vencimento da Última Parcela:",
            content: genericFormatter(ultimaParcela?.DtVencimento ?? new Date(), 'date'),
            size: 4
          },
          {
            label: "Valor da Parcela:",
            content: genericFormatter(this.financingOption?.resposta?.Proposta?.VlrParcela ?? '', 'money'),
            size: 4
          }
        ],
        [
          {
            label: "Taxa de Juros (% a.a.):",
            content: genericFormatter(this.financingOption?.resposta?.Proposta?.PercJurosAnual ?? 0, 'decimal', 4).toString(4),
            size: 4
          },
          {
            label: "Taxa de Juros (% a.m):",
            content: genericFormatter(this.financingOption?.resposta?.Proposta?.PercJurosMensal ?? 0, 'decimal', 4).toString(4),
            size: 4
          },
          {
            label: "Custo Efetivo Total (% a.m.):",
            content: `${genericFormatter(this.financingOption?.resposta?.Proposta?.PercCETAnual ?? 0, 'decimal', 4).toString()}% a.a. / ${genericFormatter(this.financingOption?.resposta?.Proposta?.PercCETMensal ?? 0, 'decimal', 4).toString()}% a.m.`,
            size: 4
          }
        ]
      ]
    });

    /// Descrição e local
    this.tables.push({
      rows: [
        [
          {
            label: "Razão Social Fornecedor:",
            content: 'ENGEMETAL COMERCIO E MANUTENCAO LTDA',
            size: 4
          },
          {
            label: "CNPJ Fornecedor:",
            content: '10.383.997/0001-60',
            size: 4
          },
          {
            label: "Endereço de Instalação:",
            content: this.getFullEquipamentAddress() ?? '',
            size: 4
          }
        ],
        [
          {
            label: "Potência Instalada:",
            content: (this.formGroup?.controls['potenciaInstalada'].value ?? this.proposal?.potenciaInstalada ?? 0).toString() + ' kw',
            size: 4
          },
          {
            label: "Quantidade de Paineis Instalados:",
            content: (this.formGroup?.controls['quantidadePaineis'].value ?? this.proposal?.quantidadePaineisInstalados ?? '').toString(),
            size: 4
          },
          {
            label: "Descrição dos Paineis Instalados:",
            content: this.formGroup?.controls['descricaoPaineis'].value ?? this.proposal?.descricaoPaineisInstalados ?? '',
            size: 4
          }
        ],
        [
          {
            label: "Quantidade de Inversores Instalados:",
            content: (this.formGroup?.controls['quantidadeInversores'].value ?? this.proposal?.quantidadeInversoresInstalados ?? '').toString(),
            size: 4
          },
          {
            label: "Descrição dos Inversores Instalados:",
            content: this.formGroup?.controls['descricaoInversores'].value ?? this.proposal?.descricaoInversoresInstalados ?? '',
            size: 4
          },
          {
            label: "Demais Itens Instalados:",
            content: this.formGroup?.controls['demaisItensInstalados'].value ?? this.proposal?.demaisItensInstalados ?? '',
            size: 4
          }
        ]
      ]
    });
  }

  makeClauses() {
    this.pageClauses.push([
      {
        text: "DO OBJETO DO CONTRATO:",
        children: [
          { text: "O presente Contrato tem como objeto a possibilidade de dação em pagamento, a ser realizada pelo <b>EMITENTE</b> ao <b>CREDOR</b>, em razão de uma operação de financiamento solar, consubstanciada na Cédula de Crédito Bancário, ou simplesmente <b>CCB</b>, de número conforme descrito no item 3.16 do <b>QUADRO III</b> acima, cujos montantes de pagamento e data de vencimento estão descritos no <b>QUADRO III</b> e constam no Anexo I deste documento." },
          { text: "Na hipótese de não pagamento da operação de financiamento em seus montantes e datas avençadas, seja na data de vencimento e/ou na ocorrência de vencimento antecipado da <b>CCB</b>, poderá ocorrer, a critério do <b>CREDOR</b>, a dação em pagamento em bens, conforme abaixo definido, ao <b>CREDOR</b> (ou aos seus cessionários, conforme aplicável), de pleno direito, no montante exato e devido pela operação de financiamento, ainda que o <b>CREDOR</b> já tenha iniciado o processo de cobrança dos valores devidos." },
          {
            text: "O <b>EMITENTE</b> oferecerá em dação em pagamento os Equipamentos conforme descrição contida no QUADRO IV acima e da Nota Fiscal contida no Anexo II deste documento, com valor de avaliação definido no Item 4.10 do QUADRO IV (“Bens”), as quais correspondem ao total da dívida que o mesmo possui junto ao <b>CREDOR</b> por meio da operação de financiamento.",
            children: [
              { text: "O <b>EMITENTE</b>, neste mesmo ato, anui que entregará os bens ao <b>CREDOR</b>, observando os princípios de boa-fé, de modo a não obstar a retirada dos bens pelo <b>CREDOR</b>, ainda que este não apresente ordem extrajudicial e/ou não esteja acompanhado de um oficial de justiça." },
              { text: "O <b>EMITENTE</b>, em ato subsequente, anui e concede sua expressa ciência de que, dos recursos obtidos por meio da dação em pagamento, poderão ser descontados eventuais custos e/ou despesas, incluindo judiciais e/ou extrajudiciais, incorridas pelo <b>CREDOR</b> no âmbito de eventuais demandas promovidas pelo <b>CREDOR</b> em face do <b>EMITENTE</b>." },
            ]
          },
        ]
      },
      {
        text: "INDENIZAÇÕES",
        children: [
          { text: "Para os fins deste Contrato entende-se por “Perda” todo e qualquer Ônus sobre os Equipamentos, vício, restrição ou impedimento de uso, posse ou apropripação, limitação, passivos, prejuízos, danos, perdas, contingências, custos, despesas, exigências, acordos, decisões judiciais, multas, demandas judiciais ou administrativas, penalidades, obrigações para com terceiros, encargos (incluindo juros, correção monetária, honorários advocatícios e custas judiciais) e indenizações." },
          {
            text: "A Emitente concorda em defender, indenizar, reembolsar e isentar de responsabilidade o Credor de toda e qualquer:",
            children: [
              { text: "Perda sofrida por qualquer uma das Partes Indenizáveis da Emitente com relação a, ou em decorrência de qualquer violação pela Emitente de qualquer obrigação, compromisso ou acordo contido neste Contrato;" },
              { text: "inexatidão, erro, imprecisão, insuficiência, violação ou inveracidade de qualquer declaração ou garantia prestada pela Emitente neste Contrato ou na <b>CCB</b>, ainda que de conhecimento do Credor; " },
              { text: "qualquer Ônus existente ou que venha a ser constituído sobre os Equipamentos causados pela Emitente; " },
              { text: "atos, fatos ou omissões ou de quaisquer atividades da Emitente;" },
              { text: "qualquer ato, fato ou omissão de qualquer natureza ocorrido, e, que seja reivindicada em face do Credor, ou recaia sobre os Equipamentos por qualquer razão (inclusive na hipótese do Credor ser considerado subsidiário ou solidariamente responsável pelo ato, fato ou omissão em questão, por força de Lei ou de ordem expedida por uma autoridade governamental), e independentemente de qualquer limitação ou qualificação constante das declarações e garantias em relação ao ato, fato ou omissão em questão; e" },
            ]
          },
        ]
      }
    ]);
    this.pageClauses.push([
      {
        children: [
          {
            children: [
              { text: "em caso de evicção de direito total ou parcial, incluindo, mas não se limitando às despesas previstas no art. 450 do Código Civil; e" },
              { text: "qualquer violação pela Emitente de qualquer obrigação, compromisso ou acordo contido neste Contrato." },
            ]
          },
          { text: "Em caso de atraso no ressarcimento da Perda incorrida pela Emitente, será aplicado o art. 359 do Código Civil e o Credor poderá receber os Equipamentos como pagamento, considerando o valor máximo 50% (cinquenta por cento) do valor original desembolsado pela Emitente. O restante do valor deverá ser pago em moeda corrente nacional." },
        ]
      },
      {
        text: "NOTIFICAÇÕES:",
        children: [
          { text: "Todos os avisos, notificações, consentimentos, comunicações ou pedidos requeridos ou necessários de acordo com esta <b>CCB</b> serão considerados válidos e eficazes se feitos por escrito e enviados (i) encaminhados ou entregues pessoalmente, contrarrecibo; (ii) enviados por carta registrada, com aviso de recebimento; ou (iii) transmitidos por e-mail, caso em que serão considerados recebidos no primeiro dia útil subsequente à data de envio, desde que acompanhados do comprovante de envio ou verificar-se a confirmação por escrito ou por qualquer outro meio que assegure ter o destinatário recebido a comunicação ou a notificação, para os endereços e e-mails constantes no preâmbulo desta <b>CCB</b>." }
        ]
      },
      {
        text: "CONDIÇÕES GERAIS:",
        children: [
          { text: "O <b>EMITENTE</b> obriga-se, por si, seus herdeiros e sucessores, a fazerem a presente <b>DAÇÃO</b> sempre boa, firme e valiosa, respondendo pela evicção, na forma da lei. " },
          { text: "A <b>CCB</b> não estabelece entre as <b>PARTES</b> qualquer forma de associação, sociedade ou consórcio, permanecendo intactas, inclusive quanto aos direitos e obrigações previstos neste instrumento, sua autonomia e independência, societária e patrimonial." },
          { text: "As <b>PARTES</b> reconhecem que (i) o não exercício, por qualquer delas, ou o atraso no exercício de qualquer direito que seja assegurado por esta <b>CCB</b> ou por lei não constituirá novação ou renúncia de tal direito, nem prejudicará o seu exercício a qualquer tempo; (ii) a renúncia, por qualquer das <b>PARTES</b>, a algum desses direitos somente será válida se formalizada por escrito; e (iii) a nulidade ou invalidade de qualquer das cláusulas deste instrumento não prejudicará a validade e eficácia das demais cláusulas e da própria <b>CCB</b>, sendo que, nesse último caso, as <b>PARTES</b> e/ou o juiz deverão promover, nos limites permitidos em lei, a substituição da cláusula inquinada por outra que permita às <b>PARTES</b> alcançar o resultado prático inicialmente visado." },
          { text: "Os direitos e obrigações decorrentes deste Instrumento não poderão ser cedidos e transferidos, total ou parcialmente, por qualquer das <b>PARTES</b>, salvo mediante concordância prévia ou expressa da outra <b>PARTE</b>." },
          { text: "As <b>PARTES</b> assinam reconhecem a forma de contratação por meios eletrônicos, digitais e informáticos como válida e eficaz, constituindo título executivo extrajudicial para todos os fins de direito, ainda que seja estabelecida com assinatura eletrônica ou certificação fora dos padrões ICP-BRASIL, conforme disposto pelo artigo 10 da Medida Provisória nº 2.200, de 24 de agosto de 2001, conforme alterada." },
        ]
      },
    ]);
    this.pageClauses.push([
      {
        text: "DO FORO:",
        children: [
          { text: "Fica eleito o foro da Comarca de São Paulo, Estado de São Paulo, para dirimir eventuais litígios advindos do presente contrato." }
        ]
      }
    ]);
  }

  getFullEquipamentAddress(): string {
    let retorno = (this.formGroup?.controls['logradouroEquipamento']?.value ?? this.proposal?.endereco?.logradouro) ?? '';
    if (this.formGroup?.controls['numeroEquipamento']?.value || this.proposal?.endereco?.numero) {
      retorno += ', '+(this.formGroup?.controls['numeroEquipamento']?.value
        ?? this.proposal?.endereco?.numero);
    }
    if (this.formGroup?.controls['complementoEquipamento']?.value || this.proposal?.endereco?.complemento) {
      retorno += ', '+(this.formGroup?.controls['complementoEquipamento']?.value
        ?? this.proposal?.endereco?.complemento);
    }
    if (this.formGroup?.controls['bairroEquipamento']?.value || this.proposal?.endereco?.bairro) {
      retorno += ' - '+(this.formGroup?.controls['bairroEquipamento']?.value
        ?? this.proposal?.endereco?.bairro);
    }
    if (this.formGroup?.controls['cidadeEquipamento']?.value || this.proposal?.endereco?.cidade) {
      retorno += ', '+(this.formGroup?.controls['cidadeEquipamento']?.value
        ?? this.proposal?.endereco?.cidade); }
    if (this.formGroup?.controls['estadoEquipamento']?.value || this.proposal?.endereco?.estado) {
      retorno += ' - '+(this.stateList.find(e => e.id.toString() == (this.formGroup?.controls['estadoEquipamento']?.value
        ?? this.proposal?.endereco?.estadoId)?.toString()))?.sigla ?? ''; }
    if (this.formGroup?.controls['cepEquipamento']?.value || this.proposal?.endereco?.cep) {
      retorno += ', CEP: '+(this.formGroup?.controls['cepEquipamento']?.value
        ?? this.proposal?.endereco?.cep);
    }
    return retorno;
  }

  countCellNumber(table: any, row: number, column: number) {
    let numbersBefore = 0;
    if (row != 0) {
      for (let i = 0; i < row; i++) {
        numbersBefore += table.rows[i].length;
      }
    }
    numbersBefore += column + 1;
    return numbersBefore;
  }

  get getCurdate() {
    return genericFormatter(new Date(), 'date');
  }
}
