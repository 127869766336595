import { formatDate } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from 'src/app/core/helpers/customValidators';
import { hasErrors, inputErrors } from 'src/app/core/helpers/form-functions';
import { CommentsService } from 'src/app/services/comments.service';
import { ProposalsService } from 'src/app/services/proposals.service';
import { CarenciaPretendida } from 'src/app/shared/enums/carencia-pretendida';
import { ProposalStage } from 'src/app/shared/enums/proposal-stage';
import { Comment } from 'src/app/shared/models/comment.model';
import { Proposal } from 'src/app/shared/models/proposal.model';
import { ProposalRequest } from 'src/app/shared/models/requests/proposal.request';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'client-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnChanges {
  @Input() proposal?: Proposal;
  @Input() main: boolean = false;
  @Input() disabled: boolean = false;
  @Input() comments?: Comment[];
  @Input() devolving: boolean = false;

  edit: boolean = false;
  formGroup!: FormGroup;

  comment?: Comment;

  carenciaPretendidaOptions: { name: string, value: CarenciaPretendida }[] = [
    { name: '30', value: CarenciaPretendida.TRINTA },
    { name: '60', value: CarenciaPretendida.SESSENTA },
    { name: '90', value: CarenciaPretendida.NOVENTA },
    { name: '120', value: CarenciaPretendida.CENTOEVINTE },
  ];

  constructor(
    private router: Router,
    private loading: LoadingService,
    private service: ProposalsService,
    protected formBuilder: FormBuilder,
    private commentService: CommentsService,
    protected sessionService: SessionService,
    private notification: NotificationService,
  ) {
    this.formGroup = formBuilder.group({
      nome: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(64)]],
      cpf: [{ value: '', disabled: false }, [Validators.required, CustomValidators.ValidateCpf]],
      dataNascimento: [{ value: '', disabled: false }, [Validators.required, CustomValidators.OlderThan18]],
      valorFinanciarCliente: [{ value: '', disabled: false }, [Validators.required, Validators.max(9999999), Validators.min(1)]],
      valorEntradaCliente: [{ value: null, disabled: false }],
      carenciaPretendidaCliente: [{ value: '', disabled: false }, [Validators.required, Validators.min(30), Validators.max(120)]],
      financingTotal: [{ value: '', disabled: true }]
    }, { validator: CustomValidators.aBiggerThanB('valorFinanciarCliente', 'valorEntradaCliente') });
  }

  ngOnChanges(changes: SimpleChanges): void {
    /// Set data
    if (this.proposal && changes['proposal']) {
      if (!changes['proposal'].previousValue?.uid) {
        let total = (this.proposal.valorTotalInstalacao ?? 0) - (this.proposal.valorEntrada ?? 0);
        this.formGroup.setValue({
          nome: this.proposal.cliente.ultimoContato?.nome ?? '',
          cpf: this.proposal.cliente.documento ?? '',
          dataNascimento: formatDate(this.proposal.cliente.nascimento ?? '', 'yyyy-MM-dd', 'pt-br'),
          valorFinanciarCliente: this.proposal.valorFinanciamentoCadastro ?? this.proposal.valorTotalInstalacao ?? '',
          valorEntradaCliente: this.proposal.valorEntradaCadastro ?? this.proposal.valorEntrada ?? 0,
          carenciaPretendidaCliente: this.proposal.carenciaCliente ?? '',
          financingTotal: total
        });

        if (this.proposal.propostaEtapa == ProposalStage.CADASTRO
         && !this.sessionService.isAdmin) {
          this.startEdit();
        } else if (this.disabledForm) {
          this.formGroup.disable();
        }
      }
    }
  }

  startEdit() {
    this.edit = true;
    this.formGroup.enable();
    this.formGroup.controls['financingTotal'].disable();
  }

  cancelEdit() {
    this.edit = false;
    this.formGroup.disable();
  }

  get canComment(): boolean {
    return !this.proposal
     || (this.proposal.propostaEtapa == ProposalStage.CADASTRO && !this.sessionService.isAdmin)
     || this.devolving;
  }
  get canEdit(): boolean {
    return this.sessionService.get()!.uid == this.proposal?.criadoPor
      && this.proposal.propostaEtapa == ProposalStage.CADASTRO;
  }
  get disabledForm(): boolean {
    return this.proposal != undefined && !this.edit;
  }

  get formValid(): any { return this.formGroup.valid; }
  hasErrors(control: string, isRequired: boolean = false): string | null | void {
    if (this.formGroup.controls[control]) {
      return hasErrors(this.formGroup.controls[control], isRequired);
    }
  }
  inputErrors(control: string): string {
    if (this.formGroup.controls[control]) {
      return inputErrors(this.formGroup.controls[control]);
    }
    return '';
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode == 8){
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSubmit() {
    this.loading.showLoading(true);
    const values = this.formGroup.value;

    let valorEntradaCliente = 0;
    if (values['valorEntradaCliente'] && values['valorEntradaCliente'] != "") {
      valorEntradaCliente = values['valorEntradaCliente'];
    }
    const payload: ProposalRequest = {
      nome: values['nome'],
      documento: values['cpf'],
      dataNascimento: values['dataNascimento'],
      valorEntradaCliente: valorEntradaCliente,
      valorFinanciarCliente: values['valorFinanciarCliente'],
      carenciaPretendidaCliente: values['carenciaPretendidaCliente'],
    };

    this.loading.showLoading(true);
    let saveMethod;
    if (this.edit && this.proposal?.uid) {
      saveMethod = this.service.update(this.proposal?.uid, payload);
    } else {
      saveMethod = this.service.store(payload);
    }
    saveMethod.subscribe({
      next: (res: Proposal) => {
        this.cancelEdit();
        let uid = res?.uid ?? this.proposal?.uid;

        if (this.comment) {
          this.comment = new Comment().deserialize(this.comment);
          this.comment.propostaEtapa = ProposalStage.CADASTRO;
          this.comment.propostaNumero = res!.numero!;

          this.commentService.store(this.comment).subscribe();
        }

        this.notification.success('Cliente salvo com sucesso');
        this.loading.showLoading(false);
        this.router.navigateByUrl(`/proposals`);
      },
      error: (err: any) => {
        this.loading.showLoading(false);
        this.notification.error('Erro ao salvar cliente: ' + err);
      },
      complete: () => {
        this.loading.showLoading(false);
      }
    });
  }

  commentAndReturn() {
    this.loading.showLoading(true);
    if (this.proposal === undefined || this.proposal === null) {
      this.notification.error('Não existe proposta cadastrada!');
      this.loading.showLoading(false);
    } else if (!this.comment || !this.comment.descricao || this.comment.descricao == "") {
      this.notification.error('Insira um comentário de justificativa.');
      this.loading.showLoading(false);
    } else {
      this.comment = new Comment().deserialize(this.comment);
      this.comment.propostaEtapa = ProposalStage.CADASTRO;
      this.comment.propostaNumero = this.proposal!.numero!;
      this.commentService.store(this.comment).subscribe();

      let newStage = ProposalStage.toNumber(ProposalStage.CADASTRO);
      this.service.changeStage(this.proposal!.uid!, { propostaEtapa: newStage }).subscribe({
        next: (res) => {
          this.notification.success('Proposta retornada com sucesso.');
          this.router.navigateByUrl('/proposals');
        },
        error: (err) => {
          this.notification.error('Erro ao retornar etapa: ' + err);
        },
        complete: () => {
          this.loading.showLoading(false);
        }
      });
    }
  }

  calculateFinancingTotal() {
    let valorFinanciarCliente = this.formGroup.controls['valorFinanciarCliente'].value ?? 0;
    let valorEntradaCliente = this.formGroup.controls['valorEntradaCliente'].value ?? 0;
    let total = (valorFinanciarCliente - valorEntradaCliente).toFixed(2);
    this.formGroup.controls['financingTotal'].setValue(total);
  }
}
