import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../../shared/services/session.service';

@Injectable({ providedIn: 'root' })
export class UnauthGuard implements CanActivate {

    constructor(
      private sessionService: SessionService,
        private router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.sessionService.isLogged) {
            return true;
        }
        this.router.navigateByUrl('/proposals');
        return false;
    }
}
