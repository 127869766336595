<app-collapsable-form id="signingForm" title="Formalização" [middle]="true" [mainForm]="main" [startCollapsed]="!main" [disabled]="disabled" [formGroup]="formGroup">
  <app-collapsable-form id="signingFormIssuer" [mainForm]="main" [startCollapsed]="!main || devolving" title="Emitente e fiel depositário dos bens">
    <div class="d-flex pb-3">
      <div class="form-group col-5 pe-3">
        <label class="h3 mb-0" for="nome">Nome completo do cliente <span class="text-danger">{{hasErrors('nome', true) && '*'}}</span></label>
        <input matInput formControlName="nome" [class]="hasErrors('nome') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('nome')}}</div>
      </div>
      <div class="form-group col-3 pe-3">
        <label class="h3 mb-0" for="nascimento">Data de nascimento <span class="text-danger">{{hasErrors('nascimento', true) && '*'}}</span></label>
        <input matInput formControlName="nascimento" type="date" [class]="hasErrors('nascimento') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('nascimento')}}</div>
      </div>
      <div class="form-group col-2 pe-3">
        <label class="h3 mb-0" for="cpf">CPF do cliente <span class="text-danger">{{hasErrors('cpf', true) && '*'}}</span></label>
        <input matInput formControlName="cpf" mask="000.000.000-00" [class]="hasErrors('cpf') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('cpf')}}</div>
      </div>
      <div class="form-group col-2">
        <label class="h3 mb-0" for="estadoCivil">Estado civíl <span class="text-danger">{{hasErrors('estadoCivil', true) && '*'}}</span></label>
        <mat-select formControlName="estadoCivil" [class]="hasErrors('estadoCivil') + ' form-control'">
          <mat-option *ngFor="let value of estadoCivilOptions" [value]="value.value">
            {{value.name}}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="documento">Núm. documento (RG/CNH) <span class="text-danger">{{hasErrors('documento', true) && '*'}}</span></label>
        <input matInput formControlName="documento" [class]="hasErrors('documento') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('documento')}}</div>
      </div>
      <div class="form-group col-3 pe-3">
        <label class="h3 mb-0" for="dataEmissao">Data de emissão <span class="text-danger">{{hasErrors('dataEmissao', true) && '*'}}</span></label>
        <input matInput formControlName="dataEmissao" type="date" [class]="hasErrors('dataEmissao') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('dataEmissao')}}</div>
      </div>
      <div class="form-group col-2 pe-3">
        <label class="h3 mb-0" for="orgaoExpedidor">Órgão expeditor <span class="text-danger">{{hasErrors('orgaoExpedidor', true) && '*'}}</span></label>
        <input matInput formControlName="orgaoExpedidor" [class]="hasErrors('orgaoExpedidor') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('orgaoExpedidor')}}</div>
      </div>
      <div class="form-group col-3">
        <label class="h3 mb-0" for="ufrg">Emissor/UF <span class="text-danger">{{hasErrors('ufrg', true) && '*'}}</span></label>
        <mat-select formControlName="ufrg" [class]="hasErrors('ufrg') + ' form-control'">
          <mat-option *ngFor="let value of stateList" [value]="value.id">
            {{ value.nome }}
          </mat-option>
        </mat-select>
        <div class="invalid-feedback">{{inputErrors('ufrg')}}</div>
      </div>
    </div>
    <div class="d-flex pb-4">
      <div class="form-group col-5 pe-3">
        <div *ngIf="getDoc(FileType.COMPROVANTEDOCUMENTOPESSOAL)">
          <a class="d-flex no-style" [href]="getDoc(FileType.COMPROVANTEDOCUMENTOPESSOAL)?.caminhoArquivo" target="_blank">
            <mat-icon class="mt-1 ms-2">download</mat-icon>
            <span class="ps-2 my-auto">Comprovante de documento</span>
          </a>
        </div>
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="cep">CEP <span class="text-danger">{{hasErrors('cep', true) && '*'}}</span></label>
        <input matInput formControlName="cep" (change)="onChangeCep($event)" mask="00000-000" [class]="hasErrors('cep') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('cep')}}</div>
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="numero">Número <span class="text-danger">{{hasErrors('numero', true) && '*'}}</span></label>
        <input matInput formControlName="numero" mask="9999" [class]="hasErrors('numero') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('numero')}}</div>
      </div>
      <div class="form-group col-4">
        <label class="h3 mb-0" for="complemento">Complemento <span class="text-danger">{{hasErrors('complemento', true) && '*'}}</span></label>
        <input matInput formControlName="complemento" [class]="hasErrors('complemento') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('complemento')}}</div>
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="logradouro">Logradouro <span class="text-danger">{{hasErrors('logradouro', true) && '*'}}</span></label>
        <input matInput formControlName="logradouro" [class]="hasErrors('logradouro') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('logradouro')}}</div>
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="bairro">Bairro <span class="text-danger">{{hasErrors('bairro', true) && '*'}}</span></label>
        <input matInput formControlName="bairro" [class]="hasErrors('bairro') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('bairro')}}</div>
      </div>
      <div class="form-group col-4">
        <label class="h3 mb-0" for="cidade">Cidade <span class="text-danger">{{hasErrors('cidade', true) && '*'}}</span></label>
        <input matInput formControlName="cidade" [class]="hasErrors('cidade') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('cidade')}}</div>
      </div>
    </div>
    <div class="d-flex pb-4">
      <div class="form-group col-4 pe-3">
        <div *ngIf="getDoc(FileType.COMPROVANTEENDERECO)">
          <a class="d-flex no-style" [href]="getDoc(FileType.COMPROVANTEENDERECO)?.caminhoArquivo" target="_blank">
            <mat-icon class="mt-1 ms-2">download</mat-icon>
            <span class="ps-2 my-auto">Comprovante de residência</span>
          </a>
        </div>
      </div>
      <div class="form-group col-4 pe-3">
        <a class="d-flex no-style" [href]="'http://maps.google.com/maps?q=' + getFullAddress(proposal?.cliente?.ultimoEndereco ?? undefined)" target="_blank">
          <mat-icon class="mt-1 ms-2">place</mat-icon>
          <span class="ps-2 my-auto">Visualizar localização</span>
        </a>
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-12 pe-3">
        <label class="h3 mb-0" for="email">E-mail <span class="text-danger">{{hasErrors('email', true) && '*'}}</span></label>
        <input matInput formControlName="email" type="email" [class]="hasErrors('email') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('email')}}</div>
      </div>
    </div>
  </app-collapsable-form>
  <app-collapsable-form id="signingFormEquipamentInfo" [mainForm]="main" [startCollapsed]="!main || devolving" title="Descrição e local dos equipamentos em garantia de alienação fiduciária">
    <div class="d-flex pb-3">
      <div class="form-group col-3 pe-3">
        <label class="h3 mb-0" for="cepEquipamento">CEP <span class="text-danger">{{hasErrors('cepEquipamento', true) && '*'}}</span></label>
        <input matInput formControlName="cepEquipamento" (change)="onChangeCep($event, true)" mask="00000-000" [class]="hasErrors('cepEquipamento') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('cepEquipamento')}}</div>
      </div>
      <div class="form-group col-3 pe-3">
        <label class="h3 mb-0" for="numeroEquipamento">Número <span class="text-danger">{{hasErrors('numeroEquipamento', true) && '*'}}</span></label>
        <input matInput formControlName="numeroEquipamento" mask="9999" [class]="hasErrors('numeroEquipamento') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('numeroEquipamento')}}</div>
      </div>
      <div class="form-group col-6">
        <label class="h3 mb-0" for="complementoEquipamento">Complemento <span class="text-danger">{{hasErrors('complementoEquipamento', true) && '*'}}</span></label>
        <input matInput formControlName="complementoEquipamento" [class]="hasErrors('complementoEquipamento') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('complementoEquipamento')}}</div>
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-5 pe-3">
        <label class="h3 mb-0" for="logradouroEquipamento">Logradouro <span class="text-danger">{{hasErrors('logradouroEquipamento', true) && '*'}}</span></label>
        <input matInput formControlName="logradouroEquipamento" [class]="hasErrors('logradouroEquipamento') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('logradouroEquipamento')}}</div>
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="bairroEquipamento">Bairro <span class="text-danger">{{hasErrors('bairroEquipamento', true) && '*'}}</span></label>
        <input matInput formControlName="bairroEquipamento" [class]="hasErrors('bairroEquipamento') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('bairroEquipamento')}}</div>
      </div>
      <div class="form-group col-3">
        <label class="h3 mb-0" for="cidadeEquipamento">Cidade <span class="text-danger">{{hasErrors('cidadeEquipamento', true) && '*'}}</span></label>
        <input matInput formControlName="cidadeEquipamento" [class]="hasErrors('cidadeEquipamento') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('cidadeEquipamento')}}</div>
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-3 pe-2">
        <label class="h3 mb-0" for="estadoEquipamento">Estado <span class="text-danger">{{hasErrors('estadoEquipamento', true) && '*'}}</span></label>
        <mat-select formControlName="estadoEquipamento" [class]="hasErrors('estadoEquipamento') + ' form-control'">
          <mat-option *ngFor="let value of stateList" [value]="value.id">
            {{ value.nome }}
          </mat-option>
        </mat-select>
      </div>
      <div class="form-group col-4 mt-auto ps-3">
        <div *ngIf="getDoc(FileType.ENDERECOEQUIPAMENTO)">
          <a class="d-flex no-style" [href]="getDoc(FileType.ENDERECOEQUIPAMENTO)?.caminhoArquivo" target="_blank">
            <mat-icon class="mt-1 ms-2">download</mat-icon>
            <span class="ps-2 my-auto">Comprovante de residência do equipamento</span>
          </a>
        </div>
      </div>
      <div class="form-group col-3 ps-3 mt-auto mb-2">
        <a class="d-flex no-style" [href]="'http://maps.google.com/maps?q=' + getFullAddress(proposal?.endereco)" target="_blank">
          <mat-icon class="mt-1 ms-2">place</mat-icon>
          <span class="ps-2 my-auto">Visualizar localização</span>
        </a>
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="potenciaInstalada">Potência instalada <span class="text-danger">{{hasErrors('potenciaInstalada', true) && '*'}}</span></label>
        <input matInput formControlName="potenciaInstalada" mask="99" suffix=" KW" [class]="hasErrors('potenciaInstalada') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('potenciaInstalada')}}</div>
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="quantidadePaineis">Quantidade de painéis instalados <span class="text-danger">{{hasErrors('quantidadePaineis', true) && '*'}}</span></label>
        <input matInput formControlName="quantidadePaineis" mask="9999" [class]="hasErrors('quantidadePaineis') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('quantidadePaineis')}}</div>
      </div>
      <div class="form-group col-4">
        <label class="h3 mb-0" for="descricaoPaineis">Descrição dos painéis instalados <span class="text-danger">{{hasErrors('descricaoPaineis', true) && '*'}}</span></label>
        <input matInput formControlName="descricaoPaineis" [class]="hasErrors('descricaoPaineis') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('descricaoPaineis')}}</div>
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-6 pe-3">
        <label class="h3 mb-0" for="quantidadeInversores">Quantidade de inversores instalados <span class="text-danger">{{hasErrors('quantidadeInversores', true) && '*'}}</span></label>
        <input matInput formControlName="quantidadeInversores" mask="9999" [class]="hasErrors('quantidadeInversores') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('quantidadeInversores')}}</div>
      </div>
      <div class="form-group col-6 pe-3">
        <label class="h3 mb-0" for="descricaoInversores">Descrição dos inversores instalados <span class="text-danger">{{hasErrors('descricaoInversores', true) && '*'}}</span></label>
        <input matInput formControlName="descricaoInversores" [class]="hasErrors('descricaoInversores') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('descricaoInversores')}}</div>
      </div>
      <!-- <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="demaisItensInstalados">Demais itens instalados <span class="text-danger">{{hasErrors('demaisItensInstalados', true) && '*'}}</span></label>
        <input matInput formControlName="demaisItensInstalados" [class]="hasErrors('demaisItensInstalados') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('demaisItensInstalados')}}</div>
      </div> -->
    </div>
    <div class="d-flex pb-4" *ngIf="getDoc(FileType.FINANCIAMENTO)">
      <div class="form-group col-5 pe-3">
        <a class="d-flex no-style" [href]="getDoc(FileType.FINANCIAMENTO)?.caminhoArquivo" target="_blank">
          <mat-icon class="mt-1 ms-2">download</mat-icon>
          <span class="ps-2 my-auto">Proposta comercial</span>
        </a>
      </div>
    </div>
  </app-collapsable-form>
  <app-collapsable-form id="signingFormPaymentFeatures" [mainForm]="main" [startCollapsed]="!main || devolving" title="Características da operação e condições de pagamento">
    <div class="d-flex pb-3">
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="valorTotalInstalacao">Valor líquido do crédito</label>
        <input matInput formControlName="valorTotalInstalacao" prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="." [class]="hasErrors('      ') + ' form-control'">
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="valorIOF">Valor do IOF</label>
        <input matInput formControlName="valorIOF" prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="." [class]="hasErrors('      ') + ' form-control'">
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="valorTotalDivida">Valor total da dívida</label>
        <input matInput formControlName="valorTotalDivida" prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="." [class]="hasErrors('      ') + ' form-control'">
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="dataDesembolso">Data de desembolso</label>
        <input matInput formControlName="dataDesembolso" [class]="hasErrors('      ') + ' form-control'">
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="prazo">Prazo (dias)</label>
        <input matInput formControlName="prazo" [class]="hasErrors('      ') + ' form-control'">
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="quantidadeParcelas">Quantidade de parcelas</label>
        <input matInput formControlName="quantidadeParcelas" [class]="hasErrors('      ') + ' form-control'">
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="carencia">Carência (dias)</label>
        <input matInput formControlName="carencia" [class]="hasErrors('      ') + ' form-control'">
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="vencimentoParcelas">Vencimento das parcelas</label>
        <input matInput formControlName="vencimentoParcelas" [class]="hasErrors('      ') + ' form-control'">
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="valorParcela">Valor da parcela</label>
        <input matInput formControlName="valorParcela" prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="." [class]="hasErrors('      ') + ' form-control'">
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="vencimentoPrimeiraParcela">Vencimento da primeira parcela</label>
        <input matInput formControlName="vencimentoPrimeiraParcela" [class]="hasErrors('      ') + ' form-control'">
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="vencimentoUltimaParcela">Vencimento da última parcela</label>
        <input matInput formControlName="vencimentoUltimaParcela" [class]="hasErrors('      ') + ' form-control'">
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="tac">Tarifa de análise de crédito</label>
        <input matInput formControlName="tac" prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="." [class]="hasErrors('      ') + ' form-control'">
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="jurosAnual">Taxa de juros (%a.a.)</label>
        <input matInput formControlName="jurosAnual" [class]="hasErrors('      ') + ' form-control'">
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="jurosMensal">Taxa de juros (%a.m.)</label>
        <input matInput formControlName="jurosMensal" [class]="hasErrors('      ') + ' form-control'">
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="cet">Custo efetivo total (% a.a. / % a.m.)</label>
        <input matInput formControlName="cet" [class]="hasErrors('      ') + ' form-control'">
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="valorDesembolso">Valor do desembolso</label>
        <input matInput formControlName="valorDesembolso" prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="." [class]="hasErrors('      ') + ' form-control'">
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="dataEmissaoPagamento">Data de emissão</label>
        <input matInput formControlName="dataEmissaoPagamento" [class]="hasErrors('      ') + ' form-control'">
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="dataVencimento">Data de vencimento:</label>
        <input matInput formControlName="dataVencimento" [class]="hasErrors('      ') + ' form-control'">
      </div>
    </div>
  </app-collapsable-form>
  <app-collapsable-form id="signingFormCCBFlux" [mainForm]="main" title="Resumo do fluxo CCB" [startCollapsed]="!main || devolving">
    <div class="inline-table">
      <div class="ccb-header px-2 py-3">
        <div class="d-flex justify-content-between">
          <div class="col-6 d-flex">
            <div class="col-6">Data de emissão:</div>
            <div class="col-6">{{ this.formGroup.controls['dataEmissaoPagamento'].value }}</div>
          </div>
          <div class="col-6 d-flex">
            <div class="col-6">Taxa (%a.m.):</div>
            <div class="col-6">{{ this.formGroup.controls['jurosMensal'].value }}</div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="col-6 d-flex">
            <div class="col-6">Data de vencimento:</div>
            <div class="col-6">{{ this.formGroup.controls['dataVencimento'].value }}</div>
          </div>
          <div class="col-6 d-flex">
            <div class="col-6">Taxa (%a.a.):</div>
            <div class="col-6">{{ this.formGroup.controls['jurosAnual'].value }}</div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="col-6 d-flex">
            <div class="col-6">Custo emissão</div>
            <div class="col-6">R$ {{ fixedParameters ? fixedParameters['tac'] : 0 }}</div>
          </div>
          <div class="col-6 d-flex">
            <div class="col-6">Indexador</div>
            <div class="col-6">PRÉ-FIXADO</div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="col-6 d-flex">
            <div class="col-6">IOF</div>
            <div class="col-6">{{ genericFormatter(this.formGroup.controls['valorIOF'].value, 'money') }}</div>
          </div>
          <div class="col-6 d-flex">
            <div class="col-6">CET (%a.m. / %a.a.)</div>
            <div class="col-6">{{ this.formGroup.controls['cet'].value }}</div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="col-6 d-flex">
            <div class="col-6">Valor da CCB</div>
            <div class="col-6">{{ genericFormatter(this.formGroup.controls['valorTotalDivida'].value, 'money') }}</div>
          </div>
          <div class="col-6 d-flex">
            <div class="col-6">Prazo (dias)</div>
            <div class="col-6">{{ this.formGroup.controls['prazo'].value }}</div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="col-6 d-flex">
            <div class="col-6">Valor líquido</div>
            <div class="col-6">{{ genericFormatter(this.formGroup.controls['valorTotalInstalacao'].value, 'money') }}</div>
          </div>
        </div>
      </div>
      <div class="ccb-table px-2" *ngIf="ccbFlux && ccbFlux.length > 0">
        <app-data-table
          [columnsToDisplay]="['select', 'Parcela', 'Dias', 'Data de vencimento', 'Saldo devedor', 'Amortização', 'Juros', 'Valor Parcela']"
          [showExportButtons]="false"
          [showPaginator]="false"
          [showSearchInput]="false"
          [showSorting]="false"
          [data]="ccbFlux"
        ></app-data-table>
      </div>
    </div>
  </app-collapsable-form>
  <app-collapsable-form id="signingFormSplit" *ngIf="this.formGroup.controls['splitCheck'].value" title="Split" [mainForm]="main" [startCollapsed]="!main || devolving">
    <div class="d-flex pb-3">
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="splitValue">Valor do desembolso <span class="text-danger">{{hasErrors('splitValue', true) && '*'}}</span></label>
        <input matInput formControlName="splitValue" prefix="R$ " mask="separator.2" decimalMarker="," thousandSeparator="." [class]="hasErrors('splitValue') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('splitValue')}}</div>
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0" for="splitUid">Razão social do favorecido <span class="text-danger">{{hasErrors('splitUid', true) && '*'}}</span></label>
        <mat-select formControlName="splitUid"  [class]="hasErrors('splitUid') + ' form-control'" (selectionChange)="changeIntegrator()">
          <mat-option *ngFor="let value of splitIntegrators" [value]="value.value.uid">
            {{value.name}}
          </mat-option>
        </mat-select>
        <div class="invalid-feedback">{{inputErrors('splitUid')}}</div>
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0">CPF/CNPJ</label>
        <input matInput formControlName="splitDocumentoFederal" mask="CPF_CNPJ" [class]="hasErrors('splitDocumentoFederal') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('splitDocumentoFederal')}}</div>
      </div>
    </div>
    <div class="d-flex pb-3">
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0">Nº do banco</label>
        <!-- <input matInput formControlName="splitCodigoBanco" mask="000" [class]="hasErrors('splitCodigoBanco') + ' form-control'"> -->
        <mat-select formControlName="splitCodigoBanco" [class]="hasErrors('splitCodigoBanco') + ' form-control'">
          <mat-option *ngFor="let value of splitCodigoBancoOptions" [value]="value.value">
            {{value.name}}
          </mat-option>
        </mat-select>
        <div class="invalid-feedback">{{inputErrors('splitCodigoBanco')}}</div>
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0">Nº da agência</label>
        <input matInput formControlName="splitAgencia" mask="0000" [class]="hasErrors('splitAgencia') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('splitAgencia')}}</div>
      </div>
      <div class="form-group col-4 pe-3">
        <label class="h3 mb-0">Nº da conta</label>
        <input matInput formControlName="splitContaEDigito" maxlength="14" mask="0*-0" [class]="hasErrors('splitContaEDigito') + ' form-control'">
        <div class="invalid-feedback">{{inputErrors('splitContaEDigito')}}</div>
      </div>
    </div>
  </app-collapsable-form>
  <app-collapsable-form id="signingFormCCBDownload" [mainForm]="main" [startCollapsed]="!main || devolving" title="Visualizar os documentos para assinatura">
    <div>
      <!-- actionIcon="download"
      (onClickActionIcon)="printDocument('ccb')" -->
      <app-collapsable-form id="signingFormCCBDownloadCCB"
        title="CCB"
        [mainForm]="main"
        [styless]="true"
      >
        <app-doc-ccb
          [stateList]="stateList"
          [formGroup]="formGroup"
          [financingOption]="financingOption"
          [proposal]="proposal"
          [ccbNumber]="ccbNumber"
          [fixedParameters]="fixedParameters"
        ></app-doc-ccb>
      </app-collapsable-form>
    </div>
    <div class="py-3">
      <!-- actionIcon="download"
      (onClickActionIcon)="printDocument('alienacao-fiduciaria')" -->
      <app-collapsable-form id="signingFormCCBDownloadFiduciaryAlienation"
        title="Alienação fiduciária"
        [mainForm]="main"
        [styless]="true"
      >
        <app-doc-alienacao-fiduciaria
          [proposal]="proposal"
          [fixedParameters]="fixedParameters"
          [formGroup]="formGroup"
          [stateList]="stateList"
          [financingOption]="financingOption"
        ></app-doc-alienacao-fiduciaria>
      </app-collapsable-form>
    </div>
    <div>
      <!-- actionIcon="download"
      (onClickActionIcon)="printDocument('dacao-em-pagamento')" -->
      <app-collapsable-form id="signingFormCCBDownloadFiduciaryDonation"
        title="Dação em pagamento"
        [mainForm]="main"
        [styless]="true"
      >
        <app-doc-dacao-em-pagamento
          [stateList]="stateList"
          [formGroup]="formGroup"
          [financingOption]="financingOption"
          [proposal]="proposal"
          [fixedParameters]="fixedParameters"
        ></app-doc-dacao-em-pagamento>
      </app-collapsable-form>
    </div>
  </app-collapsable-form>
  <app-collapsable-form id="signingFormFiduciaryComments" [mainForm]="main" title="Comentário" [startCollapsed]="!devolving">
    <app-proposal-comments [canComment]="canComment" [comments]="comments" (onChange)="this.comment = $event"></app-proposal-comments>
  </app-collapsable-form>
  <div class="d-flex justify-content-between pt-4 pb-3">
    <button [hidden]="disabledForm" class="btn btn-outline-secondary w-40 pe-2" (click)="devolve()" type="button">Devolver</button>
    <button [hidden]="disabledForm" [disabled]="!formValid" class="btn btn-primary w-40" (click)="onSubmit()" type="submit">Concluir</button>
    <button [hidden]="!devolving" class="btn btn-primary ms-auto w-40" (click)="commentAndReturn()" type="submit">Comentar</button>
  </div>
</app-collapsable-form>
