import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { hasErrors, inputErrors } from 'src/app/core/helpers/form-functions';
import { Comment } from 'src/app/shared/models/comment.model';
import { Proposal } from 'src/app/shared/models/proposal.model';
import { Options } from '@angular-slider/ngx-slider';
import { CepService } from 'src/app/shared/services/cep.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CommentsService } from 'src/app/services/comments.service';
import { ProposalsService } from 'src/app/services/proposals.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { ProposalStage } from 'src/app/shared/enums/proposal-stage';
import { BureauService } from 'src/app/services/bureau.service';
import { BureauType } from 'src/app/shared/enums/bureau-type';
import { PreAnalisisRequest } from 'src/app/shared/models/requests/pre-analisis.request';
import { CreditAnalisis } from 'src/app/shared/models/credit-analisis.model';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/core/helpers/customValidators';

@Component({
  selector: 'client-pre-analisis',
  templateUrl: './pre-analisis.component.html',
  styleUrls: ['./pre-analisis.component.scss']
})
export class PreAnalisisComponent implements OnChanges {
  ProposalStage = ProposalStage;

  @Input() proposal?: Proposal;
  @Input() main: boolean = false;
  @Input() disabled: boolean = false;
  @Input() comments?: Comment[];
  @Input() devolving: boolean = false;

  @Output() devolveStage = new EventEmitter();

  formGroup!: FormGroup;
  edit: boolean = false;
  taxOptions: Options = { floor: 1.40, ceil: 2.60, step: 0.05, noSwitching: true, pushRange: true, };
  dueOptions: Options = { floor: 12, ceil: 72, noSwitching: true, pushRange: true,
    stepsArray: [12,24,36,48,60,72].map(e => { return { value: e }; }) };
  carencyOptions: Options = { floor: 30, ceil: 120, noSwitching: true, pushRange: true,
    stepsArray: [30,60,90,120].map(e => { return { value: e }; }) };

  propostaEtapa = ProposalStage.NEGOCIACAOCOMERCIAL;
  gridInstallments: any = [];
  selectedFinancing?: CreditAnalisis;
  comment?: Comment;

  constructor(
    private router: Router,
    private loading: LoadingService,
    private service: ProposalsService,
    protected formBuilder: FormBuilder,
    private bureauService: BureauService,
    private commentService: CommentsService,
    protected sessionService: SessionService,
    private notification: NotificationService,
  ) {
    this.formGroup = formBuilder.group({
      valorTotal: [{ value: '', disabled: false, }],
      valorEntrada: [{ value: '', disabled: false, }],
      taxaDeJurosSlider: [{ value: [1.40, 2.60], disabled: false, }],
      prazoSlider: [{ value: [12,72], disabled: false, }],
      carenciaSlider: [{ value: [30, 120], disabled: false, }],
    }, { validator: CustomValidators.aBiggerThanB('valorTotal', 'valorEntrada') });
  }

  ngOnChanges(changes: SimpleChanges): void {
    /// Set data
    if (this.proposal && changes['proposal']) {
      if (!changes['proposal'].previousValue?.uid) {
        this.formGroup.setValue({
          valorTotal: this.proposal.valorTotalInstalacao,
          valorEntrada: this.proposal.valorEntrada,
          taxaDeJurosSlider: [
            this.proposal.taxaDeJurosMinima ?? 1.4,
            this.proposal.taxaDeJurosMaxima ?? 2.6
          ],
          prazoSlider: [this.proposal.parcelaMinima ?? 12, this.proposal.parcelaMaxima ?? 72],
          carenciaSlider: [
            this.proposal.carenciaMinima ?? 30,
            this.proposal.carenciaMaxima ?? 120
          ],
        });

        this.getGridInstallments();
        if (this.disabledForm) {
          this.formGroup.disable();
        }
      }
    }
  }

  startEdit() {
    this.edit = true;
    this.formGroup.enable();
  }

  cancelEdit() {
    this.edit = false;
    this.formGroup.disable();
  }

  get canComment(): boolean {
    return this.proposal?.propostaEtapa === ProposalStage.PREANALISEDECREDITO
     || this.devolving;
  }
  get canEdit(): boolean {
    return this.sessionService.isAdmin
      && this.proposal?.propostaEtapa !== ProposalStage.PREANALISEDECREDITO;
  }
  get disabledForm(): boolean {
    return !((this.edit
      || this.proposal?.propostaEtapa == ProposalStage.PREANALISEDECREDITO)
      && this.sessionService.isAdmin);
  }

  get formValid(): any {
    return this.formGroup.valid;
  }
  hasErrors(control: string): string | null | void {
    if (this.formGroup.controls[control]) {
      return hasErrors(this.formGroup.controls[control]);
    }
  }
  inputErrors(control: string): string {
    if (this.formGroup.controls[control]) {
      return inputErrors(this.formGroup.controls[control]);
    }
    return '';
  }

  onRefused() {
    this.propostaEtapa = ProposalStage.RECUSADOPREANALISE;
    this.notification.info('Proposta recusada com sucesso');
  }

  onApproved() {
    this.propostaEtapa = ProposalStage.NEGOCIACAOCOMERCIAL;
    this.notification.info('Proposta aprovada com sucesso');
  }

  changeGridParcelas() {
    this.gridInstallments = null;
    if (this.formGroup.valid && this.proposal?.uid && this.sessionService.isAdmin) {
      let values = this.formGroup.value;
      let payload: PreAnalisisRequest = {
        valorTotal: values['valorTotal'] ?? 0,
        valorEntrada: values['valorEntrada'] ?? 0,
        taxaDeJurosMinima: values['taxaDeJurosSlider'][0],
        taxaDeJurosMaxima: values['taxaDeJurosSlider'][1],
        prazoMinimo: values['prazoSlider'][0],
        prazoMaximo: values['prazoSlider'][1],
        carenciaMinima: values['carenciaSlider'][0],
        carenciaMaxima: values['carenciaSlider'][1]
      };
      this.loading.showLoading(true);
      this.service.preanalisis(this.proposal!.uid!, payload, false).subscribe({
        next: (res) => {
          this.proposal!.uid = res.uid;
          if (res.ofertas && res.ofertas.length > 0) {
            this.gridInstallments = {};
            let data = res.ofertas.map(e => new CreditAnalisis().deserialize(e));

            this.carencyOptions.stepsArray?.forEach(carency => {
              let filterCarency = this.formGroup.controls['carenciaSlider'].value;
              if (carency.value >= filterCarency[0] && carency.value <= filterCarency[1]) {
                let filter = data.filter(e => (e.requisição?.dto.NroDiasAcrescimo + 30) == carency.value);
                if (filter.length > 0) {
                  this.gridInstallments[carency.value] = {};
                  this.dueOptions.stepsArray?.forEach(due => {
                    let selectedDue = this.formGroup.controls['prazoSlider'].value;
                    if (due.value >= selectedDue[0] && due.value <= selectedDue[1]) {
                      let dueFilter = filter.filter(e => e.requisição?.dto.Prazo == due.value);
                      if (dueFilter.length > 0) {
                        this.gridInstallments[carency.value][due.value]
                          = dueFilter[dueFilter.length - 1];
                      }
                    }
                  })
                }
              }
            });
          }
        },
        error: (err) => {
          console.error(err);
        },
        complete: () => {
          this.loading.showLoading(false);
        }
      });
    }
  }

  getGridInstallments(after: boolean = false) {
    if (this.proposal?.cliente?.uid) {
      this.bureauService.getBureau(this.proposal!.cliente.uid, BureauType.GRIDPARCELAS).subscribe({
        next: (res) => {
          if (res.length > 0) {
            this.gridInstallments = {};
            let data = res.map(e => new CreditAnalisis().deserialize(e));

            this.carencyOptions.stepsArray?.forEach(carency => {
              let filterCarency = this.formGroup.controls['carenciaSlider'].value;
              if (carency.value >= filterCarency[0] && carency.value <= filterCarency[1]) {
                let filter = data.filter(e => (e.requisição?.dto.NroDiasAcrescimo + 30) == carency.value);
                if (filter.length > 0) {
                  this.gridInstallments[carency.value] = {};
                  this.dueOptions.stepsArray?.forEach(due => {
                    let selectedDue = this.formGroup.controls['prazoSlider'].value;
                    if (due.value >= selectedDue[0] && due.value <= selectedDue[1]) {
                      let dueFilter = filter.filter(e => e.requisição?.dto.Prazo == due.value);
                      if (dueFilter.length > 0) {
                        this.gridInstallments[carency.value][due.value]
                          = dueFilter[dueFilter.length - 1];
                      }
                    }
                  })
                }
              }
            });
          }
        },

        error: (err) => {
          this.notification.error('Erro ao trazer tabela de parcelas: ' + err)
        }
      });
    }
  }

  onSubmit() {
    this.loading.showLoading(true);

    if (this.formGroup.valid && this.proposal?.uid) {
      let values = this.formGroup.value;
      let payload: PreAnalisisRequest = {
        valorTotal: values['valorTotal'] ?? 0,
        valorEntrada: values['valorEntrada'] ?? 0,
        taxaDeJurosMinima: values['taxaDeJurosSlider'][0],
        taxaDeJurosMaxima: values['taxaDeJurosSlider'][1],
        prazoMinimo: values['prazoSlider'][0],
        prazoMaximo: values['prazoSlider'][1],
        carenciaMinima: values['carenciaSlider'][0],
        carenciaMaxima: values['carenciaSlider'][1]
      };

      if (this.comment) {
        this.comment = new Comment().deserialize(this.comment);
        this.comment.propostaEtapa = ProposalStage.PREANALISEDECREDITO;
        this.comment.propostaNumero = this.proposal!.numero!;

        this.commentService.store(this.comment).subscribe();
      }
      this.service.preanalisis(this.proposal!.uid!, payload, (this.propostaEtapa != ProposalStage.RECUSADOPREANALISE)).subscribe({
        next: (res) => {
          const newGuid = res.uid ?? this.proposal!.uid!;
          this.service.changeStage(newGuid,
            { propostaEtapa: ProposalStage.toNumber(this.propostaEtapa) }
          ).subscribe({
            next: (res) => {
              this.notification.success('Proposta salva com sucesso');
              this.router.navigateByUrl('/proposals');
            },
            error: (err) => {
              this.notification.error('Erro ao reprovar proposta: ' + err);
            },
            complete: () => {
              this.loading.showLoading(false);
            }
          });
        },
        error: (err) => {
          this.loading.showLoading(false);
          this.notification.error('Erro ao salvar cliente: ' + err);
        }
      })
    }
  }

  devolve() {
    this.devolveStage.emit(ProposalStage.CADASTRO);
    // if (!this.comment) {
    //   this.showAllComment = true;
    //   this.notification.info('Preencha o comentário para prosseguir.');
    //   return;
    // }
    // this.loading.showLoading(true);

    // this.comment = new Comment().deserialize(this.comment);
    // this.comment.propostaEtapa = ProposalStage.CADASTRO;
    // this.comment.propostaNumero = this.proposal!.numero!;
    // this.commentService.store(this.comment).subscribe();

    // let newStage = ProposalStage.CADASTRO;
    // this.service.changeStage(this.proposal!.uid!, { propostaEtapa: ProposalStage.toNumber(newStage)
    // }).subscribe({
    //   next: (res) => {
    //     if (newStage === ProposalStage.CADASTRO) {
    //       this.notification.success('Proposta retornada com sucesso.');
    //     }
    //     this.router.navigateByUrl(`/proposals/${res.uid}`);
    //   },
    //   error: (err) => {
    //     this.notification.error('Erro ao retornar etapa: ' + err);
    //   },
    //   complete: () => {
    //     this.loading.showLoading(false);
    //   }
    // });
  }

  commentAndReturn() {
    this.loading.showLoading(true);
    if (this.proposal === undefined || this.proposal === null) {
      this.notification.error('Não existe proposta cadastrada!');
      this.loading.showLoading(false);
    } else if (!this.comment || !this.comment.descricao || this.comment.descricao == "") {
      this.notification.error('Insira um comentário de justificativa.');
      this.loading.showLoading(false);
    } else {
      this.comment = new Comment().deserialize(this.comment);
      this.comment.propostaEtapa = ProposalStage.PREANALISEDECREDITO;
      this.comment.propostaNumero = this.proposal!.numero!;
      this.commentService.store(this.comment).subscribe();

      let newStage = ProposalStage.toNumber(ProposalStage.PREANALISEDECREDITO);
      this.service.changeStage(this.proposal!.uid!, { propostaEtapa: newStage }).subscribe({
        next: (res) => {
          this.notification.success('Proposta retornada com sucesso.');
          this.router.navigateByUrl('/proposals');
        },
        error: (err) => {
          this.notification.error('Erro ao retornar etapa: ' + err);
        },
        complete: () => {
          this.loading.showLoading(false);
        }
      });
    }
  }
}
