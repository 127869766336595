export enum CarenciaPretendida {
    TRINTA = 30,
    SESSENTA = 60,
    NOVENTA = 90,
    CENTOEVINTE = 120,
}

export namespace CarenciaPretendida {
  export function fromNumber(val?: number): CarenciaPretendida | undefined {
    let response;
    switch (val) {
      case 30:
        response = CarenciaPretendida.TRINTA;
      break;
      case 60:
        response = CarenciaPretendida.SESSENTA;
      break;
      case 90:
        response = CarenciaPretendida.NOVENTA;
      break;
      case 120:
        response = CarenciaPretendida.CENTOEVINTE;
      break;
    }
    return response;
  }
}
