<div class="grid-offer">
  <div *ngIf="!gridInstallments" class="loading-area clearfix d-flex">
    <div class="spinner-border text-primary mx-auto" role="status"></div>
  </div>
  <div *ngIf="gridInstallments" class="column-grid w-100 py-3 text-center">
    <div class="d-flex">
      <div class="title col-1"></div>
      <div *ngFor="let carencyValue of getCarencyDates" class="col-1 px-4 d-flex">
        <div *ngIf="gridInstallments[carencyValue.value]" class="text-left m-auto">
          <div class="title mb-2">{{ carencyValue.value }} dias de carência</div>
          <div *ngIf="gridInstallments[carencyValue.value][getDueDates[0].value]">
            <span>Primeira parcela:</span>
            <div class="h3 mb-0">{{ getFirstInstallmentDueDate(gridInstallments[carencyValue.value][getDueDates[0].value]) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let dueValue of getDueDates" class="d-flex">
      <div class="title col-1 my-auto">{{ dueValue.value }}x</div>
      <div *ngFor="let carencyValue of getCarencyDates" class="col-1">
        <div *ngIf="gridInstallments[carencyValue.value] && gridInstallments[carencyValue.value][dueValue.value]"
          (click)="selectFinancingOption(gridInstallments[carencyValue.value][dueValue.value])"
          [class]="{ 'offer-card': true, pointer: !disabled, selected: isFinancingOptionSelected(gridInstallments[carencyValue.value][dueValue.value]) }"
        >
          <div class="subtitle py-2">{{ getOfferValue(gridInstallments[carencyValue.value][dueValue.value]) }}</div>
          <div class="text-left">
            <div class="h6 mb-0">Taxa de juros mensal: {{ getTax(gridInstallments[carencyValue.value][dueValue.value], 'montly') }}</div>
            <div class="h6 mb-0">Taxa de juros anual: {{ getTax(gridInstallments[carencyValue.value][dueValue.value], 'anual') }}</div>
            <div class="h6 mb-0">CET mensal: {{ getCET(gridInstallments[carencyValue.value][dueValue.value], 'montly') }}</div>
            <div class="h6 mb-0">CET anual: {{ getCET(gridInstallments[carencyValue.value][dueValue.value], 'anual') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
