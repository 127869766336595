<div class="login-background d-grid">
  <div class="card border-0 shadow w-35 pb-5" style="min-width: 24rem;max-width: 40rem;">
    <div class="card-header text-center py-3">
      <div class="h2 text-primary m-0">
        <b>Acesse sua conta
          <span class="text-secondary text-uppercase">BSF</span>
        </b>
      </div>
    </div>
    <form class="d-flex flex-column" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div class="card-body px-5 py-4">
        <div class="form-group mb-3">
          <label class="h3 mb-0" for="username">Usuário {{hasErrors('username') && '*'}}</label>
          <input formControlName="username" [class]="hasErrors('username') + ' form-control'">
          <div class="invalid-feedback">{{inputErrors('username')}}</div>
        </div>
        <div class="form-group">
          <label class="h3 mb-0" for="password">Senha {{hasErrors('password') && '*'}}</label>
          <input formControlName="password" [type]="hide ? 'password' : 'text'"
            [class]="hasErrors('password') + ' form-control'">
          <mat-icon class="suffix text-primary pointer" (click)="hide = !hide">
            visibility{{!hide && '_off'}}
          </mat-icon>
          <div class="invalid-feedback">{{inputErrors('password')}}</div>
        </div>
        <div class="d-flex justify-content-end">
          <button (click)="forgetPassword()" type="button" class="btn btn-link float-right">Esqueci minha senha</button>
        </div>
      </div>
      <div class="px-5">
        <button type="submit" [disabled]="!formValid" class="btn btn-primary w-100 mx-auto">
          Entrar
        </button>
      </div>
    </form>
  </div>
</div>
