<div class="stepper d-flex justify-content-center">
  <div class="col text-center" *ngFor="let step of steps; let index = index; let first = first; let last = last">
    <div class="col-auto d-flex">
      <div [class]="{ line: !first, col: true }"></div>
      <div *ngIf="absCurrentIndex - 1 > index" class="step checked"><mat-icon>{{ currentIndex >= 0 ? 'done' : 'close' }}</mat-icon></div>
      <div *ngIf="absCurrentIndex - 1 <= index" [class]="{ step: true, active: index == absCurrentIndex - 1 }">
        {{ index + 1 }}
      </div>
      <div [class]="{ line: !last, col: true }"></div>
    </div>
    <div class="pt-1">{{ step }}</div>
  </div>
</div>
