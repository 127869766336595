<div id="printable-alienacao-fiduciaria" class="sheet-shadow">
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <!-- <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div> -->
      <div class="my-auto col"><span class="ms-auto me-0">Página 1 de 3</span></div>
    </header>
    <div class="content">
      <div class="title">INSTRUMENTO PARTICULAR DE CONSTITUIÇÃO DE ALIENAÇÃO FIDUCIÁRIA</div>
      <div class="title">SOBRE EQUIPAMENTOS DE SISTEMA FOTOVOLTAICO</div>
      <div class="subtitle">I. EMITENTE</div>
      <div class="doc-table" *ngIf="tables[0]">
        <div class="doc-row" *ngFor="let row of tables[0].rows; let i = index">
          <div [class]="'doc-cell col-'+cell.size" *ngFor="let cell of row; let j = index;">
            <label>1.{{ countCellNumber(tables[0], i, j) }}.&nbsp;&nbsp;{{ cell.label }}</label>
            <span>{{ cell.content }}</span>
          </div>
        </div>
      </div>
      <div class="subtitle">II. CREDOR</div>
      <div class="doc-table" *ngIf="tables[1]">
        <div class="doc-row" *ngFor="let row of tables[1].rows; let i = index">
          <div [class]="'doc-cell col-'+cell.size" *ngFor="let cell of row; let j = index;">
            <label>2.{{ countCellNumber(tables[1], i, j) }}.&nbsp;&nbsp;{{ cell.label }}</label>
            <span>{{ cell.content }}</span>
          </div>
        </div>
      </div>
      <div class="subtitle">III. DADOS DA CCB #{{ proposal?.numeroCcb ?? '0' }} (ANEXO I)</div>
      <div class="doc-table" *ngIf="tables[2]">
        <div class="doc-row" *ngFor="let row of tables[2].rows; let i = index">
          <div [class]="'doc-cell col-'+cell.size" *ngFor="let cell of row; let j = index;">
            <label>3.{{ countCellNumber(tables[2], i, j) }}.&nbsp;&nbsp;{{ cell.label }}</label>
            <span>{{ cell.content }}</span>
          </div>
        </div>
      </div>
      <div class="subtitle">IV. DESCRIÇÃO E LOCAL DOS EQUIPAMENTOS DADOS EM GARANTIA DE ALIENAÇÃO FIDUCIÁRIA</div>
      <div class="doc-table" *ngIf="tables[3]">
        <div class="doc-row" *ngFor="let row of tables[3].rows; let i = index">
          <div [class]="'doc-cell col-'+cell.size" *ngFor="let cell of row; let j = index;">
            <label>4.{{ countCellNumber(tables[3], i, j) }}.&nbsp;&nbsp;{{ cell.label }}</label>
            <span>{{ cell.content }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <!-- <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div> -->
      <div class="my-auto col"><span class="ms-auto me-0">Página 2 de 3</span></div>
    </header>
    <div class="content">
      <app-clause-orderer [clauses]="pageClauses[0]"></app-clause-orderer>
    </div>
  </section>
  <section class="sheet">
    <header class="header">
      <div class="my-auto col"><img src="../assets/img/app-logo.png"></div>
      <!-- <div class="my-auto col"><span>VIA NEGOCIÁVEL</span></div> -->
      <div class="my-auto col"><span class="ms-auto me-0">Página 3 de 3</span></div>
    </header>
    <div class="content">
      <app-clause-orderer [clauses]="pageClauses[1]"
        [startClause]="2" [startSubclause]="6">
      </app-clause-orderer>
      <div class="text-center pb-3 pt-4"><h5>{{ 'São Paulo, ' + getCurdate + '.' }}</h5></div>
      <div class="signing-area">
        <div class="d-flex justify-content-center w-100">
          <div class="col-5 sign">
            <span class="pt-2">EMITENTE</span>
          </div>
        </div>
        <div class="py-4 font-weight-500">Testemunhas:</div>
        <div class="d-flex justify-content-evenly w-100 pt-0">
          <div class="col-5 d-flex flex-column" style="max-height: 50px;">
            <img src="/assets/img/signings/bruno.png" />
          </div>
          <div class="col-5 d-flex flex-column" style="max-height: 50px;">
            <img src="/assets/img/signings/yuri.png" />
          </div>
        </div>
        <div class="d-flex justify-content-evenly w-100 pt-0">
          <div class="col-5 sign">
            <span class="pt-2">Nome: Bruno Henrique Fonseca Teixeira</span>
            <span>RG nº 40.679.674-9 SSP/SP</span>
          </div>
          <div class="col-5 sign">
            <span class="pt-2">Nome: Yuri Siqueira Faria Inokuti</span>
            <span>RG nº 39.265.240-7 SSP/SP</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
