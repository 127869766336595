<div class="d-grid">
  <div class="card shadow border-0 w-85 mx-auto mt-5 p-4" style="min-width: 70rem;">
    <div class="d-flex flex-column">
      <div class="h2 title">Gestão de clientes</div>
      <div>
        <button *ngIf="session.isAdmin" routerLink="/integrators/new" type="button" class="btn btn-primary">
          Cadastrar Integrador
        </button>
        <button *ngIf="!session.isAdmin" routerLink="/proposals/new" type="button" class="btn btn-primary">
          Cadastrar Proposta
        </button>
      </div>
      <div class="pt-4">
        <app-data-table
          initialSort="alerta"
          [columnsToDisplay]="columnsToDisplay"
          [data]="clients"
          [showCustomButton]="true"
          [showExportButtons]="true"
          [customActionIcon]="clientIcon"
          (customAction)="clickAlert($event)"
        ></app-data-table>
      </div>
    </div>
  </div>
</div>
