export enum FileType {
  COMPROVANTEENDERECO = 10,
  COMPROVANTERENDA = 20,
  COMPROVANTELUZ = 30,
  OUTROS = 40,
  COMPROVANTEDOCUMENTOPESSOAL = 50,
  FINANCIAMENTO = 60,
  DOCUMENTOCCB = 70,
  DACAOEMPAGAMENTO = 80,
  ALIENACAOFIDUCIARIA = 90,
  ENDERECOEQUIPAMENTO = 100,
}
