import { Injectable } from '@angular/core';
import { HttpDataSource } from '../data_source/config';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {
  constructor(private dataSource: HttpDataSource) {}

  get(key: string) {
    return this.dataSource.get<{ nome: string, valor: any}>({ url: '/parametro/' + key, authenticate: true });
  }
}
