import { Injectable } from "@angular/core";
import { User } from "../models/user.model";

@Injectable({ providedIn: 'root' })
export class SessionService {
  set(user: User): void {
    localStorage.setItem('session', JSON.stringify(user));
  }

  get(): User | void {
    let session = localStorage.getItem('session');
    if (session != null) {
      return new User().deserialize(JSON.parse(session));
    }
  }

  get isLogged(): boolean {
    if (this.get()) {
      return true;
    }
    return false;
  }

  get isAdmin(): boolean {
    return this.get()?.perfil == 'admin';
  }

  getToken(): string | undefined {
    return this.get()?.token;
  }

  logOut() {
    localStorage.clear();
  }
}
