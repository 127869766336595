import { Options } from '@angular-slider/ngx-slider/options';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import exportFromJSON from 'export-from-json';
import { Router } from '@angular/router';
import { hasErrors, inputErrors } from 'src/app/core/helpers/form-functions';
import { genericFormatter } from 'src/app/core/helpers/masks';
import { BureauService } from 'src/app/services/bureau.service';
import { CommentsService } from 'src/app/services/comments.service';
import { ProposalsService } from 'src/app/services/proposals.service';
import { BureauType } from 'src/app/shared/enums/bureau-type';
import { ProposalStage } from 'src/app/shared/enums/proposal-stage';
import { Comment } from 'src/app/shared/models/comment.model';
import { CreditAnalisis } from 'src/app/shared/models/credit-analisis.model';
import { Proposal } from 'src/app/shared/models/proposal.model';
import { CepService } from 'src/app/shared/services/cep.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { formatDate } from '@angular/common';
import { FileType } from 'src/app/shared/enums/file-type';
import { Document } from 'src/app/shared/models/document.model';
import { EstadoCivil } from 'src/app/shared/enums/estado-civil';
import { Address } from 'src/app/shared/models/address.model';
import { Contact } from 'src/app/shared/models/contact.model';
import { User } from 'src/app/shared/models/user.model';
import { DocumentType } from 'src/app/shared/enums/document-type';
import { State } from 'src/app/shared/models/state.model';
import { CustomValidators } from 'src/app/core/helpers/customValidators';
import { SplitData } from 'src/app/shared/models/split-data.model';
import { bacenBankList } from 'src/app/core/constants/bacen-bank-list';

@Component({
  selector: 'client-credit-approval',
  templateUrl: './credit-approval.component.html',
  styleUrls: ['./credit-approval.component.scss']
})
export class CreditApprovalComponent implements OnChanges {
  ProposalStage = ProposalStage;
  FileType = FileType;

  @Input() proposal?: Proposal;
  @Input() main: boolean = false;
  @Input() disabled: boolean = false;
  @Input() comments?: Comment[];
  @Input() devolving: boolean = false;
  @Input() stateList: State[] = [];

  @Output() devolveStage = new EventEmitter();

  formGroup!: FormGroup;
  edit: boolean = false;
  taxOptions: Options = { floor: 1.40, ceil: 2.60, step: 0.05, noSwitching: true, pushRange: true, };
  dueOptions: Options = { floor: 12, ceil: 72, noSwitching: true, pushRange: true,
    stepsArray: [12,24,36,48,60,72].map(e => { return { value: e }; }) };
  carencyOptions: Options = { floor: 30, ceil: 120, noSwitching: true, pushRange: true,
    stepsArray: [30,60,90,120].map(e => { return { value: e }; }) };

  comment?: Comment;
  gridInstallments: any;
  protected financingOption?: CreditAnalisis;
  protected propostaEtapa: ProposalStage = ProposalStage.APROVACAODECREDITO;

  splitCodigoBancoOptions: { name: string, value: number }[] = bacenBankList;
  estadoCivilOptions: { name: string, value: EstadoCivil }[] = [
    { name: 'Casado', value: EstadoCivil.CASADO },
    { name: 'Divorciado', value: EstadoCivil.DIVORCIADO },
    { name: 'Separado', value: EstadoCivil.SEPARADO },
    { name: 'Solteiro', value: EstadoCivil.SOLTEIRO },
    { name: 'Viuvo', value: EstadoCivil.VIUVO },
  ];
  tipoDocumentoIdentificacaoOptions: { name: string, value: DocumentType }[] = [
    { name: 'RG', value: DocumentType.RG },
    { name: 'CNH', value: DocumentType.CNH },
  ];
  splitIntegrators: { name: string, value: SplitData }[] = [];
  selectedSplit?: SplitData;

  constructor(
    private router: Router,
    private loading: LoadingService,
    private service: ProposalsService,
    protected formBuilder: FormBuilder,
    private bureauService: BureauService,
    private commentService: CommentsService,
    protected sessionService: SessionService,
    private notification: NotificationService,
  ) {
    this.formGroup = formBuilder.group({
      estadoCivil: [{ value: '', disabled: false }, Validators.required],
      documento: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(16)]],
      dataEmissao: [{ value: '', disabled: false }, [Validators.required, CustomValidators.dateBeforeToday, CustomValidators.OlderThan50]],
      orgaoExpedidor: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(16)]],
      ufrg: [{ value: '', disabled: false }, Validators.required],
      celular: [{ value: '', disabled: false }, Validators.required],
      telefone: [{ value: '', disabled: false }],
      email: [{ value: '', disabled: false }, [Validators.required, Validators.email, Validators.maxLength(64)]],
      cep: [{ value: '', disabled: false }, Validators.required],
      numero: [{ value: '', disabled: false }, Validators.required],
      complemento: [{ value: '', disabled: false }, Validators.maxLength(128)],
      bairro: [{ value: '', disabled: false }, Validators.required],
      logradouro: [{ value: '', disabled: false }, Validators.required],
      cidade: [{ value: '', disabled: false }, Validators.required],
      estado: [{ value: '', disabled: false }, Validators.required],
      enderecoInstalacao: [{ value: true, disabled: false }],
      cepEquipamento: [{ value: '', disabled: false }],
      numeroEquipamento: [{ value: '', disabled: false }],
      complementoEquipamento: [{ value: '', disabled: false }, Validators.maxLength(128)],
      bairroEquipamento: [{ value: '', disabled: false }],
      logradouroEquipamento: [{ value: '', disabled: false }],
      cidadeEquipamento: [{ value: '', disabled: false }],
      estadoEquipamento: [{ value: '', disabled: false }],
      tipoDocumentoIdentificacao: [{ value: null, disabled: false }, Validators.required],
      valorContaLuz: [{ value: '', disabled: false }, Validators.required],
      rendaIndividual: [{ value: '', disabled: false }, Validators.required],
      rendaFamiliar: [{ value: '', disabled: false }],
      potenciaInstalada: [{ value: '', disabled: false }, Validators.required],
      quantidadePaineis: [{ value: '', disabled: false }, [Validators.required, Validators.min(1), Validators.max(120)]],
      descricaoPaineis: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(128)]],
      quantidadeInversores: [{ value: '', disabled: false }, [Validators.required, Validators.min(1), Validators.max(120)]],
      descricaoInversores: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(128)]],
      demaisItensInstalados: [{ value: '', disabled: false }, Validators.maxLength(128)],

      valorTotal: [{ value: '', disabled: true, }],
      valorEntrada: [{ value: '', disabled: true, }],
      taxaDeJurosSlider: [{ value: [1.40, 2.60], disabled: true, }],
      prazoSlider: [{ value: [12,72], disabled: true, }],
      carenciaSlider: [{ value: [30, 120], disabled: true, }],

      splitCheck: [{ value: '', disabled: true }],
      splitValue: [{ value: '', disabled: true }],
      splitUid: [{ value: '', disabled: true }],
      splitDocumentoFederal: [{ value: '', disabled: true }],
      splitCodigoBanco: [{ value: '', disabled: true }],
      splitAgencia: [{ value: '', disabled: true }],
      splitContaEDigito: [{ value: '', disabled: true }],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    /// Set data
    if (this.proposal && changes['proposal']) {
      if (!changes['proposal'].previousValue?.uid) {
        this.getGridInstallments();

        let client: User = this.proposal?.cliente;
        let contact: Contact | null = client.ultimoContato;
        let address: Address | null = client?.ultimoEndereco;
        let installationAddress: Address | undefined = this.proposal?.endereco;

        let estadoUfrg;
        let estadoEndereco;
        let estadoInstalacao;
        if (this.stateList) {
          if (contact?.ufrg) {
            estadoUfrg = this.stateList.find(e => e.id.toString() == contact?.ufrg?.toString())?.id ?? '';
          }
          if (address?.estadoId) {
            estadoEndereco = this.stateList.find(e => e.id.toString() == address?.estadoId?.toString())?.id ?? '';
          }
          if (installationAddress?.estadoId) {
            estadoInstalacao = this.stateList.find(e => e.id.toString() == installationAddress?.estadoId?.toString())?.id ?? '';
          }
        }

        let hasSplit = this.proposal?.splitGuid != null && this.proposal?.splitGuid != "";
        this.formGroup.setValue({
          valorContaLuz: this.proposal?.valorContaLuz ?? '',
          rendaIndividual: this.proposal?.rendaIndividual ?? '',
          rendaFamiliar: this.proposal?.rendaFamiliar ?? '',
          potenciaInstalada: this.proposal?.potenciaInstalada ?? '',
          descricaoPaineis: this.proposal?.descricaoPaineisInstalados ?? '',
          quantidadePaineis: this.proposal?.quantidadePaineisInstalados ?? '',
          descricaoInversores: this.proposal?.descricaoInversoresInstalados ?? '',
          quantidadeInversores: this.proposal?.quantidadeInversoresInstalados ?? '',
          demaisItensInstalados: this.proposal?.demaisItensInstalados ?? '',

          ufrg: estadoUfrg ?? '',
          email: contact?.email ?? '',
          celular: contact?.celular ?? '',
          telefone: contact?.telefone ?? '',
          estadoCivil: contact?.estadoCivil ?? '',
          orgaoExpedidor: contact?.orgaExpedidor ?? '',
          dataEmissao: formatDate(contact?.dataEmissaoDocumento ?? '', 'yyyy-MM-dd', 'pt-br'),
          documento: contact?.documentoIdentificacao ?? '', tipoDocumentoIdentificacao: contact?.tipoDocumentoIdentificacao ?? '',

          cep: address?.cep ?? '',
          numero: address?.numero ?? '',
          complemento: address?.complemento ?? '',
          bairro: address?.bairro ?? '',
          logradouro: address?.logradouro ?? '',
          cidade: address?.cidade ?? '',
          estado: estadoEndereco ?? '',

          enderecoInstalacao: true,
          cepEquipamento: installationAddress?.cep ?? '',
          numeroEquipamento: installationAddress?.numero ?? '',
          complementoEquipamento: installationAddress?.complemento ?? '',
          bairroEquipamento: installationAddress?.bairro ?? '',
          logradouroEquipamento: installationAddress?.logradouro ?? '',
          cidadeEquipamento: installationAddress?.cidade ?? '',
          estadoEquipamento: estadoInstalacao ?? '',

          valorTotal: this.proposal.valorTotalInstalacao,
          valorEntrada: this.proposal.valorEntrada,
          taxaDeJurosSlider: [this.proposal.taxaDeJurosMinima, this.proposal.taxaDeJurosMaxima],
          prazoSlider: [this.proposal.parcelaMinima, this.proposal.parcelaMaxima],
          carenciaSlider: [this.proposal.carenciaMinima, this.proposal.carenciaMaxima],

          splitCheck: hasSplit,
          splitUid: hasSplit ? this.proposal?.splitGuid : '',
          splitValue: hasSplit ? this.proposal?.valorSplit : '',
          splitDocumentoFederal: '',
          splitCodigoBanco: '',
          splitAgencia: '',
          splitContaEDigito: '',
        });
        if (hasSplit) {
          this.getProposalOnCreditApprovall(this.proposal!.numero!);
          this.getSplitList(this.proposal!.splitGuid!);
        }
        this.formGroup.disable();
      }
    }
  }

  get formValid(): any { return this.formGroup.valid; }
  hasErrors(control: string, isRequired: boolean = false): string | null | void {
    if (this.formGroup.controls[control]) {
      return hasErrors(this.formGroup.controls[control], isRequired);
    }
  }
  inputErrors(control: string): string {
    if (this.formGroup.controls[control]) {
      return inputErrors(this.formGroup.controls[control]);
    }
    return '';
  }

  get disabledForm(): boolean {
    return !((this.edit
      || this.proposal?.propostaEtapa == ProposalStage.APROVACAODECREDITO)
      && this.sessionService.isAdmin);
  }

  getSplitList(selectedUid: string) {
    this.service.splitDataList().subscribe({
      next: (res) => {
        this.splitIntegrators = res.map(e => {
          return { name: e.nomePagamento ?? '', value: new SplitData().deserialize(e) }
        });
        this.selectedSplit = this.splitIntegrators.find(e => e.value.uid == selectedUid)?.value;
      },
    })
  }

  getProposalOnCreditApprovall(proposalNumber: number) {
    this.service.proposalHistory(proposalNumber).subscribe({
      next: (res) => {
        var propostasEmAprovacao = res.map(e => new Proposal().deserialize(e)).filter(e => e.propostaEtapa == ProposalStage.APROVACAODECREDITO);
        if (propostasEmAprovacao.length > 0) {
          var propostaEmAprovacao = propostasEmAprovacao[propostasEmAprovacao.length - 1];
          this.formGroup.controls['splitUid'].setValue(propostaEmAprovacao.splitGuid);
          this.formGroup.controls['splitValue'].setValue(propostaEmAprovacao.valorSplit);
          this.formGroup.controls['splitDocumentoFederal'].setValue(propostaEmAprovacao.dadosSplit?.documentoFederal);

          const codigoBancoAsNumber: number | undefined = Number(propostaEmAprovacao.dadosSplit?.codigoBanco);

          this.formGroup.controls['splitCodigoBanco'].setValue(codigoBancoAsNumber);
          this.formGroup.controls['splitAgencia'].setValue(propostaEmAprovacao.dadosSplit?.agencia);
          this.formGroup.controls['splitContaEDigito'].setValue(propostaEmAprovacao.dadosSplit?.contaEDigito);
        }
      },
    })
  }

  getGridInstallments() {
    if (this.proposal?.cliente?.uid) {
      this.bureauService.getBureau(this.proposal!.cliente.uid, BureauType.GRIDPARCELAS).subscribe({
        next: (res) => {
          if (res.length > 0) {
            this.gridInstallments = {};
            let data = res.map(e => new CreditAnalisis().deserialize(e));

            this.carencyOptions.stepsArray?.forEach(carency => {
              let filterCarency = [this.proposal?.carenciaMinima ?? 30, this.proposal?.carenciaMaxima ?? 120];
              if (carency.value >= filterCarency[0] && carency.value <= filterCarency[1]) {
                let filter = data.filter(e => (e.requisição?.dto.NroDiasAcrescimo + 30) == carency.value);
                if (filter.length > 0) {
                  this.gridInstallments[carency.value] = {};
                  this.dueOptions.stepsArray?.forEach(due => {
                    let filterDue = [this.proposal?.parcelaMinima ?? 12, this.proposal?.parcelaMaxima ?? 72];
                    if (due.value >= filterDue[0] && due.value <= filterDue[1]) {
                      let dueFilter = filter.filter(e => e.requisição?.dto.Prazo == due.value);
                      if (dueFilter.length > 0) {
                        this.gridInstallments[carency.value][due.value]
                          = dueFilter[dueFilter.length - 1];
                      }
                    }
                  })
                }
              }
            });

            if (this.gridInstallments && this.proposal?.carenciaSelecionada && this.proposal?.parcelaSelecionada && this.gridInstallments[this.proposal.carenciaSelecionada][this.proposal.parcelaSelecionada]) {
              this.financingOption = this.gridInstallments[this.proposal!.carenciaSelecionada!][this.proposal!.parcelaSelecionada!];
            }
          }
        },

        error: (err) => {
          this.notification.error('Erro ao trazer tabela de parcelas: ' + err)
        }
      });
    }
  }

  onRefused() {
    this.propostaEtapa = ProposalStage.RECUSADOAPROVACAO;
    this.notification.info('Proposta recusada com sucesso.');
  }

  onApproved() {
    this.propostaEtapa = ProposalStage.APROVACAODECREDITO;
    this.notification.info('Proposta aprovada com sucesso.');
  }

  onSubmit() {
    // const data = this.proposal?.toExport();
    // if (data) {
    //   const fileName = 'cliente-' + this.proposal?.numero;
    //   const exportType = exportFromJSON.types.json

    //   exportFromJSON({ data, fileName, exportType })
    //   this.notification.success('Arquivo json gerado com sucesso.');
    // }
    this.loading.showLoading(true);
    if (this.comment) {
      this.comment = new Comment().deserialize(this.comment);
      this.comment.propostaEtapa = ProposalStage.APROVACAODECREDITO;
      this.comment.propostaNumero = this.proposal!.numero!;

      this.commentService.store(this.comment).subscribe();
    }
    if (this.propostaEtapa == ProposalStage.RECUSADOAPROVACAO) {
      this.service.changeStage(this.proposal!.uid!,
        { propostaEtapa: ProposalStage.toNumber(this.propostaEtapa) }
      ).subscribe({
        next: (res) => {
          this.notification.success('Proposta recusada com sucesso.');
          this.router.navigateByUrl('/proposals');
        },
        error: (err) => {
          this.notification.error('Erro ao recusar proposta: ' + err);
        },
        complete: () => {
          this.loading.showLoading(false);
        }
      });
    } else {
      this.service.creditApproval(this.proposal!.uid!).subscribe({
        next: (res) => {
          this.notification.success('Proposta aprovada com sucesso.');
          if (res?.uid) {
            this.router.navigateByUrl(`/proposals/${res.uid}/offer`);
          } else {
            this.router.navigateByUrl('/proposals');
          }
        },
        error: (err) => {
          this.notification.error('Erro ao aprovar proposta: ' + err);
        },
        complete: () => {
          this.loading.showLoading(false);
        }
      })
    }
  }

  devolve() {
    this.devolveStage.emit(ProposalStage.NEGOCIACAOCOMERCIAL)
  }

  commentAndReturn() {
    this.loading.showLoading(true);
    if (this.proposal === undefined || this.proposal === null) {
      this.notification.error('Não existe proposta cadastrada!');
      this.loading.showLoading(false);
    } else if (!this.comment || !this.comment.descricao || this.comment.descricao == "") {
      this.notification.error('Insira um comentário de justificativa.');
      this.loading.showLoading(false);
    } else {
      this.comment = new Comment().deserialize(this.comment);
      this.comment.propostaEtapa = ProposalStage.APROVACAODECREDITO;
      this.comment.propostaNumero = this.proposal!.numero!;
      this.commentService.store(this.comment).subscribe();

      let newStage = ProposalStage.toNumber(ProposalStage.APROVACAODECREDITO);
      this.service.changeStage(this.proposal!.uid!, { propostaEtapa: newStage }).subscribe({
        next: (res) => {
          this.notification.success('Proposta retornada com sucesso.');
          this.router.navigateByUrl('/proposals');
        },
        error: (err) => {
          this.notification.error('Erro ao retornar etapa: ' + err);
        },
        complete: () => {
          this.loading.showLoading(false);
        }
      });
    }
  }

  get canComment(): boolean {
    return (this.proposal?.propostaEtapa === ProposalStage.APROVACAODECREDITO && this.sessionService.isAdmin)
     || this.devolving;
  }

  getDoc(value: FileType): Document | undefined {
    let files = this.proposal?.cliente?.documentos.filter(e => e.tipoArquivo == value) ?? [];
    if (files && files.length > 0) {
      return files[files!.length - 1];
    } else {
      return undefined;
    }
  }

  getFullAddress(address?: Address): string {
    let retorno = address?.logradouro ?? '';
    if (address?.numero) { retorno += ', '+address.numero; }
    if (address?.bairro) { retorno += '- '+address.bairro; }
    if (address?.cidade) { retorno += ', '+address.cidade; }
    if (address?.estadoId) { retorno += '- '+this.stateList.find(e => e.id.toString() == address?.estadoId?.toString())?.sigla ?? ''; }
    if (address?.cep) { retorno += ', '+address.cep; }
    return retorno;
  }

  formatDate(date: string): string {
    try {
      return formatDate(date, 'dd/MM/yyyy', 'pt-br');
    } catch (error) {
      return '';
    }
  }
}
