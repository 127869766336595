import { AbstractControl, Validators } from "@angular/forms";

export function hasErrors(control: AbstractControl, isRequired: boolean = false): 'is-invalid' | null {
  let response: 'is-invalid' | null;
  response = control.touched && control.errors ? 'is-invalid' : null;

  if (isRequired) {
    if (control.errors?.hasOwnProperty('required') && control.errors['required']) {
      return 'is-invalid';
    }
    return control.hasValidator(Validators.required) ? 'is-invalid' : null;
  }
  return response;
}

export function inputErrors(control: AbstractControl): string {
  if (control?.errors) {
    return Object.entries(control?.errors).filter(value => value[1])
      .map(value => _errorMessage(value))
    .join(', ');
  }
  return '';
}

function _errorMessage(text: any[]): string {
  switch (text[0]) {
    case 'required':
      return 'Insira esse campo';
    case 'email':
      return 'Email inválido';
    case 'max':
      return `Insira um valor menor ou igual a ${text[1]?.max}`;
    case 'min':
      return `Insira um valor maior ou igual a ${text[1]?.min}`;
    case 'minlength':
      return `Insira no mínimo ${text[1]?.requiredLength} caracteres`;
    case 'maxlength':
      return `Insira no máximo ${text[1]?.requiredLength} caracteres`;
    case 'cpfInvalido':
      return 'CPF Incorreto. Por favor, verifique o número informado';
    case 'cnpjInvalido':
      return 'CNPJ Incorreto. Por favor, verifique o número informado';
    case 'menorDeIdade':
      return 'É preciso ser maior de 18 anos para continuar';
    case 'maiorQue50':
      return 'É preciso ser uma data menor que 50 anos para validação';
    case 'passwordNotMatch':
      return 'Senhas diferentes';
    case 'mask':
      return 'Insira esse campo corretamente';
    case 'dateBeforeToday':
      return 'A data precisa ser menor que hoje';
    case 'biggerThan':
      return 'Este campo deve ser menor que o antecessor';
    default:
      return 'Campo inválido: ' + text[0];
  }
}
