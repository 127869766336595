import { Injectable } from '@angular/core';
import { HttpDataSource } from '../data_source/config';
import { Proposal } from '../shared/models/proposal.model';
import { ProposalRequest } from '../shared/models/requests/proposal.request';
import { PreAnalisisRequest } from '../shared/models/requests/pre-analisis.request';
import { TradeNegotiationRequest } from '../shared/models/requests/trade-negotiation.request';
import { State } from '../shared/models/state.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  constructor(private dataSource: HttpDataSource) {}

  getAll(params?: { page: string, limit: string }) {
    return this.dataSource.get<{ estados: State[] }>({ url: '/estado', params, authenticate: true });
  }
}
