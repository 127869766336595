import { AbstractControl, FormGroup } from "@angular/forms";

export class CustomValidators {
  static ValidateDocument(control: AbstractControl) {
    if (control.value.toString().replace(/[^0-9]/g, '').length > 11) {
      return CustomValidators.ValidateCnpj(control);
    } else {
      return CustomValidators.ValidateCpf(control);
    }
  }

  static ValidateCpf(control: AbstractControl | { value: any }) {
    const cpf = control.value.replace(/\./g, '').replace(/-/g, '');

    let soma: number = 0;
    let resto: number;
    let valido: boolean;

    const regex = new RegExp('[0-9]{11}');

    if (
      cpf == '00000000000' ||
      cpf == '11111111111' ||
      cpf == '22222222222' ||
      cpf == '33333333333' ||
      cpf == '44444444444' ||
      cpf == '55555555555' ||
      cpf == '66666666666' ||
      cpf == '77777777777' ||
      cpf == '88888888888' ||
      cpf == '99999999999' ||
      !regex.test(cpf)
    )
      valido = false;
    else {
      for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(cpf.substring(9, 10))) valido = false;

      soma = 0;
      for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) resto = 0;
      (resto != parseInt(cpf.substring(10, 11))) ? valido = false : valido = true;
    }

    if (valido) return null;

    return { cpfInvalido: true };
  }

  static ValidateCnpj(control: AbstractControl) {
    const cnpj = control.value;

    if (cnpj == "") {
      return null;
    }

    let valido: boolean = true;

    const regexCnpj = new RegExp('[0-9]{14}');

    if (
      cnpj == '00000000000000' ||
      cnpj == '11111111111111' ||
      cnpj == '22222222222222' ||
      cnpj == '33333333333333' ||
      cnpj == '44444444444444' ||
      cnpj == '55555555555555' ||
      cnpj == '66666666666666' ||
      cnpj == '77777777777777' ||
      cnpj == '88888888888888' ||
      cnpj == '99999999999999' ||
      !regexCnpj.test(cnpj)
    ) {
      return { cnpjInvalido: true };
    }
    else {
      // Valida DVs
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--)
      {
          soma += numeros.charAt(tamanho - i) * pos--;

          if (pos < 2)
              pos = 9;
      }

      var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != parseInt(digitos[0].toString())) {
        valido = false;
      }

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--)
      {
          soma += numeros.charAt(tamanho - i) * pos--;

          if (pos < 2)
              pos = 9;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

      if (resultado != parseInt(digitos[1].toString())) {
        valido = false;
      }

      if (valido) {
        return null;
      }

      return { cnpjInvalido: true };
    }
  }

  static OlderThan18(control: AbstractControl) {
    const birthdate = control.value;
    const hoje = new Date();
    const nascimento = new Date(birthdate);

    let age = hoje.getFullYear() - nascimento.getFullYear();
    const m = hoje.getMonth() - nascimento.getMonth();

    if (m < 0 || (m === 0 && hoje.getDate() < nascimento.getDate() + 1)) age--;
    if (age >= 18) return null;

    return {menorDeIdade: true};
  }

  static dateBeforeToday(control: AbstractControl) {
    const value = control.value;
    const hoje = new Date();
    const date = new Date(value);

    if (date <= hoje) {
      return;
    }

    return {dateBeforeToday: true};
  }

  static OlderThan50(control: AbstractControl) {
    const birthdate = control.value;
    const hoje = new Date();
    const emission = new Date(birthdate);

    let age = hoje.getFullYear() - emission.getFullYear();
    const m = hoje.getMonth() - emission.getMonth();

    if (m < 0 || (m === 0 && hoje.getDate() < emission.getDate() + 1)) age--;
    if (age <= 50) return null;

    return {maiorQue50: true};
  }

  static ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  static aBiggerThanB(a: string, b: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[a];
      const matchingControl = formGroup.controls[b];

      if (matchingControl.errors && !matchingControl.errors['biggerThan']) {
        return;
      }
      if (control.value <= matchingControl.value) {
        matchingControl.setErrors({ biggerThan: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
}
