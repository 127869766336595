import { Deserializable } from "./deserializable";
import { State } from "./state.model";

export class Address implements Deserializable {
  uid?: string;
  cep?: string;
  usuarioGuid?: string;
  logradouro?: string;
  numero?: number;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  estadoId?: number;
  atualizadoEm?: string;
  atualizadoPor?: string;
  estado?: State;

  static isSameAs(first?: Address, other?: Address): boolean {
    return first?.cep?.trim() == other?.cep?.trim()
      && first?.logradouro?.trim() == other?.logradouro?.trim()
      && first?.numero == other?.numero
      && first?.complemento?.trim() == other?.complemento?.trim()
      && first?.bairro?.trim() == other?.bairro?.trim()
      && first?.cidade?.trim() == other?.cidade?.trim()
    && first?.estadoId == other?.estadoId;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  toSave() {
    return this;
  }
}
