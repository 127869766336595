import { MatPaginatorIntl } from "@angular/material/paginator";

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Mostrando:';
  customPaginatorIntl.nextPageLabel = "Próxima";
  customPaginatorIntl.previousPageLabel = "Anterior";
  customPaginatorIntl.firstPageLabel = "Primeira";
  customPaginatorIntl.lastPageLabel = "Ultima";

  return customPaginatorIntl;
}
