import { Injectable } from '@angular/core';
import { HttpDataSource } from '../data_source/config';
import { Proposal } from '../shared/models/proposal.model';
import { ProposalRequest } from '../shared/models/requests/proposal.request';
import { PreAnalisisRequest } from '../shared/models/requests/pre-analisis.request';
import { TradeNegotiationRequest } from '../shared/models/requests/trade-negotiation.request';
import { FormalizationRequest } from '../shared/models/requests/formalization.request';
import { LiquidationRequest } from '../shared/models/requests/liquidation.request';
import { SplitData } from '../shared/models/split-data.model';
import { SignDocumentRequest } from '../shared/models/requests/sign-document.request';

@Injectable({
  providedIn: 'root'
})
export class ProposalsService {
  constructor(private dataSource: HttpDataSource) {}

  getAll(params?: { page: string, limit: string }) {
    return this.dataSource.get<Proposal[]>({ url: '/proposta', params, authenticate: true });
  }

  get(proposalUid: string) {
    return this.dataSource.get<Proposal>({ url: `/proposta/${proposalUid}`, authenticate: true });
  }

  store(payload: ProposalRequest) {
    return this.dataSource.post<Proposal>({ url: '/proposta', payload, authenticate: true });
  }

  update(proposalUid: string, payload: ProposalRequest) {
    return this.dataSource.put<Proposal>({ url: `/proposta/${proposalUid}`, payload, authenticate: true });
  }

  preanalisis(proposalUid: string, payload: PreAnalisisRequest, save?: boolean) {
    let params;
    if (save !== undefined) {
      params = { save };
    }
    return this.dataSource.post<Proposal>({ url: `/proposta/preanalise/${proposalUid}`, payload,
      params, authenticate: true });
  }

  tradeNegociation(payload: TradeNegotiationRequest) {
    return this.dataSource.post<Proposal>({ url: `/proposta/negociacaocomercial`,
      payload: payload.toSave(), authenticate: true,
      options: { headers: { "Content-Type": "multipart/form-data" } }
    });
  }

  creditApproval(proposalUid: string) {
    return this.dataSource.post<Proposal>({ url: `/proposta/aprovacaodecredito/${proposalUid}`, payload: {}, authenticate: true });
  }

  formalization(changeStage: boolean, payload: FormalizationRequest) {
    payload.codigoBanco = payload.codigoBanco?.toString();
    return this.dataSource.post<Proposal>({ url: `/proposta/formalizarproposta?mudaEstado=${changeStage}`, payload, authenticate: true });
  }

  liquidation(propostaUid: string, payload: LiquidationRequest) {

    return this.dataSource.post({ url: `/proposta/liquidacaodecredito/${propostaUid}`,
      payload: payload.toSave(), authenticate: true,
      options: { headers: { "Content-Type": "multipart/form-data" } }
    });
  }

  splitDataList() {
    return this.dataSource.get<SplitData[]>({ url: '/DadosSplit', authenticate: true });
  }

  splitData(splitUid: string) {
    return this.dataSource.get<SplitData>({ url: `/DadosSplit/${splitUid}`, authenticate: true });
  }

  changeStage(proposalUid: string, payload: { propostaEtapa: number }) {
    return this.dataSource.post<Proposal>({ url: `/proposta/etapa/${proposalUid}`, payload, authenticate: true });
  }

  proposalHistory(numeroProposta: string | number) {
    return this.dataSource.get<Proposal[]>({ url: `/proposta/propostahistorico/${numeroProposta}`, authenticate: true });
  }

  sendDocumentToSign(proposalUid: string, payload: SignDocumentRequest) {
    payload.propostaGuid = proposalUid;
    return this.dataSource.post<Proposal>({ url: `/proposta/documentoassinatura`,
      payload: payload.toSave(), authenticate: true,
      options: { headers: { "Content-Type": "multipart/form-data" } },
      retryCount: 0
    });
  }
}
