import { Deserializable } from "../deserializable";

export class TradeNegotiationRequest implements Deserializable {
  propostaUid!: string;
  numeroProposta!: number;
  estadoCivil!: number;
  documento!: string;
  tipoDocumentoIdentificacao!: number;
  dataEmissao!: string;
  orgaoExpedidor!: string;
  ufrg!: string;
  celular!: string;
  telefone!: string;
  email!: string;
  endereco!: {
    cep?: string;
    logradouro?: string;
    numero?: number;
    complemento?: string;
    bairro?: string;
    cidade?: string;
    estadoId?: number;
  };
  enderecoInstalacao!: boolean;
  enderecoEquipamento?: {
    cep?: string;
    logradouro?: string;
    numero?: number;
    complemento?: string;
    bairro?: string;
    cidade?: string;
    estadoId?: number;
  };
  valorContaLuz!: number;
  rendaIndividual!: number;
  rendaFamiliar!: number;
  potenciaInstalada!: number;
  quantidadePaineis!: number;
  quantidadeInversores!: number;
  descricaoPaineis!: string;
  descricaoInversores!: string;
  demaisItensInstalados?: string;
  carenciaSelecionada!: number;
  prazoSelecionado!: number;
  taxaDeJurosSelecionada!: number;
  documentoPessoal?: File;
  comprovanteResidencia?: File;
  comprovanteContaDeLuz?: File;
  comprovanteRenda?: File;
  comprovanteResidenciaEquipamento?: File;
  comprovanteFinanciamento?: File;
  comprovanteOutros?: File[];

  splitGuid?: string;
  valorSplit?: number;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  toSave() {
    const formData: FormData = new FormData();
    Object.keys(this).forEach(key => {
      let value = (this as any)[key];
      if (typeof value == 'number') {
        formData.append(key, value.toString().replace(",","."));
      } else if (value && typeof value == 'object') {
        Object.keys(value).forEach(k => {
          if (key == 'enderecoEquipamento') {
            formData.append(k+'EnderecoEquipamento', value[k]);
          } else {
            formData.append(k, value[k]);
          }
        });
      } else {
        formData.append(key, value);
      }
    });
    if (this.documentoPessoal) {
      formData.append("documentoPessoal", this.documentoPessoal);
    }
    if (this.comprovanteResidencia) {
      formData.append("comprovanteResidencia", this.comprovanteResidencia);
    }
    if (this.comprovanteContaDeLuz) {
      formData.append("comprovanteContaDeLuz", this.comprovanteContaDeLuz);
    }
    if (this.comprovanteRenda) {
      formData.append("comprovanteRenda", this.comprovanteRenda);
    }
    if (this.comprovanteResidenciaEquipamento) {
      formData.append("comprovanteResidenciaEquipamento", this.comprovanteResidenciaEquipamento);
    }
    if (this.comprovanteFinanciamento) {
      formData.append("comprovanteFinanciamento", this.comprovanteFinanciamento);
    }
    // if (this.comprovanteOutros) {
    //   const files = this.comprovanteOutros;
    //   for (var i=0; i < files.length; i++) {
    //     formData.append("comprovanteOutros", files[i]);
    //   }
    // }
    return formData;
  }
}
