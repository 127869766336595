import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProposalsRoutingModule } from './proposals-routing.module';
import { DataTableModule } from 'src/app/shared/components/data-table/data-table.module';
import { OfferProposalsComponent } from './offer-proposals/offer-proposals.component';
import { ProposalStepperModule } from 'src/app/shared/components/client-stepper/client-stepper.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { CollapsableFormModule } from 'src/app/shared/components/collapsable-form/collapsable-form.module';
import { CepService } from 'src/app/shared/services/cep.service';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { DocAlienacaoFiduciariaComponent } from './components/doc-alienacao-fiduciaria/doc-alienacao-fiduciaria.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommentsComponent } from './offer-proposals/components/comments/comments.component';
import { CreditApprovalComponent } from './offer-proposals/components/credit-approval/credit-approval.component';
import { GridOfferComponent } from './offer-proposals/components/grid-offer/grid-offer.component';
import { LiquidationComponent } from './offer-proposals/components/liquidation/liquidation.component';
import { NegociationComponent } from './offer-proposals/components/negociation/negociation.component';
import { PreAnalisisComponent } from './offer-proposals/components/pre-analisis/pre-analisis.component';
import { RegisterComponent } from './offer-proposals/components/register/register.component';
import { FormalizationComponent } from './offer-proposals/components/formalization/formalization.component';
import { ViewProposalsComponent } from './view-proposals/view-proposals.component';
import { ClauseOrdererComponent } from './components/clause-orderer/clause-orderer.component';
import { DocDacaoEmPagamentoComponent } from './components/doc-dacao-em-pagamento/doc-dacao-em-pagamento.component';
import { DocCcbComponent } from './components/doc-ccb/doc-ccb.component';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';


@NgModule({
  declarations: [
    ViewProposalsComponent,
    OfferProposalsComponent,
    RegisterComponent,
    PreAnalisisComponent,
    NegociationComponent,
    CreditApprovalComponent,
    FormalizationComponent,
    LiquidationComponent,
    DocAlienacaoFiduciariaComponent,
    CommentsComponent,
    GridOfferComponent,
    ClauseOrdererComponent,
    DocDacaoEmPagamentoComponent,
    DocCcbComponent,
  ],
  imports: [
    CommonModule,
    ProposalsRoutingModule,
    DataTableModule,
    ProposalStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    CollapsableFormModule,
    ClipboardModule,
    NgxSliderModule,
    MatRadioModule,
    MatSliderModule,
    MatIconModule,
    MatInputModule,
    NgxExtendedPdfViewerModule,
    NgbCollapseModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [CepService]
})
export class ProposalsModule { }
