import { Injectable } from '@angular/core';
import { HttpDataSource } from '../data_source/config';
import { Proposal } from '../shared/models/proposal.model';
import { Comment } from '../shared/models/comment.model';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  constructor(private dataSource: HttpDataSource) {}

  getAll(params: { propostaGuid: string, page?: string, limit?: string }) {
    return this.dataSource.get<Proposal[]>({ url: '/comentario', params, authenticate: true });
  }

  store(comment: Comment) {
    return this.dataSource.post<Proposal[]>({ url: '/comentario', payload: comment.toSave(), authenticate: true });
  }
}
