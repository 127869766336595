import { SelectionModel } from '@angular/cdk/collections';
import { formatDate } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() columnsToDisplay: string[] = [];
  @Input() data: any[] = [];
  @Input() isLoadingResults = false;
  @Input() noDataMessage = 'Nenhum dado encontrado.';
  @Input() paginationMessage = 'Mostrando:';

  @Input() showSorting = true;
  @Input() showSearchInput = true;
  @Input() showPaginator = false;
  @Input() showExportButtons = false;
  @Input() showEditButton = false;
  @Input() showSelectBox = false;
  @Input() showViewButton = false;
  @Input() showDeleteButton = false;
  @Input() showCustomButton = false;
  @Input() showSelectAllBtn = false;

  @Input() initialSort = 'id';
  @Input() sortDirection = 'asc';

  @Input() customActionIcon: ((row: any) => string) | undefined;

  @Output() selectRowAction = new EventEmitter();
  @Output() deleteAction = new EventEmitter();
  @Output() editAction = new EventEmitter();
  @Output() viewAction = new EventEmitter();
  @Output() customAction = new EventEmitter();

  public selection = new SelectionModel<any>(true, []);
  dataSource!: MatTableDataSource<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  @ViewChild('TABLE', { static: true })
  table!: MatTableDataSource<any>;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private session: SessionService,
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
    this.cdr.detectChanges();
    this.dataSource.paginator = this.paginator;

    this.sort.sort(({ id: this.initialSort, start: this.sortDirection }) as MatSortable);
    // this.dataSource.sortingDataAccessor = this.sortingDateAccessor;
    this.dataSource.sortData = this.customSort();
  }

  convertSnakeCaseToTitleCase(str: string): string {
    return str
      .replace(/([A-Z]+)/g, ' $1')
      .replace(/([A-Z][a-z])/g, ' $1')
      .toUpperCase();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onClick(element: any, action: string): void {
    switch (action) {
      case 'view':
        this.viewAction.emit(element);
        break;
      case 'delete':
        this.deleteAction.emit(element);
        break;
      case 'edit':
        this.editAction.emit(element);
        break;
      case 'custom':
        this.customAction.emit(element);
        break;
      default:
        this.viewAction.emit(element);
        break;
    }
  }

  onSelectRow(row?: any): void {
    if (row) {
      this.selection.toggle(row);
    }

    if (this.selection.selected.length == 1) {
      this.selectRowAction.emit(row ?? this.selection.selected[0]);
    } else if (this.selection.selected.length > 0) {
      this.selectRowAction.emit(this.selection.selected);
    }
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // this.dataSource.sortingDataAccessor = this.sortingDateAccessor;
    this.dataSource.sortData = this.customSort();
  }

  // custom sort function
customSort() {
  let sortFunction =
  (items: any[], sort: MatSort): any[] =>  {
    if (!sort.active || sort.direction === '') {
      return items;
    }
    let column = sort.active;
    if (column.toLowerCase().includes("data")) {
      return items.sort((a: any, b: any) => {
        let comparatorResult = 0;
        try {
          comparatorResult = (a[column].split('/').reverse().join('-') > b[column].split('/').reverse().join('-') ? 1 : -1)
        } catch (error) {
          comparatorResult = 0;
        }
        return comparatorResult * (sort.direction == 'asc' ? 1 : -1);
      })
    } else if (column.toLowerCase().includes("ações")
     && (items.length > 0 && items[0].hasOwnProperty('alerta'))
    ) {
      column = 'alerta';
    }
    return items.sort((a: any, b: any) => {
      let comparatorResult = 0;
      if (a[column] > b[column]) {
        comparatorResult = 1;
      }
      if (a[column] < b[column]) {
        comparatorResult = -1;
      }
      return comparatorResult * (sort.direction == 'asc' ? 1 : -1);
    });
  }; return sortFunction;
 }

  sortingDateAccessor(item: any, property: string) {
    if (property.toLowerCase().includes("data")) {
      if (item[property].includes("/")) {
        return formatDate(item[property].split('/').reverse().join('-'), 'yyyy-MM-dd', 'pt-br');
      }

      return formatDate(item[property], 'yyyy-MM-dd', 'pt-br');
    }

    return item[property];
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource ? this.dataSource.data.length : 0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row) => this.selection.select(row));
      this.selection.isSelected(this.dataSource.data[0]);
    }
    this.onSelectRow();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  get exportHiddenColumns(): number[] {
    let hidden: number[] = [];
    if (this.columnsToDisplay.includes('select')) {
      hidden.push(this.columnsToDisplay.indexOf('select'));
    }
    if (this.columnsToDisplay.includes('reproc')) {
      hidden.push(this.columnsToDisplay.indexOf('reproc'));
    }
    if (this.columnsToDisplay.includes('ações')) {
      hidden.push(this.columnsToDisplay.indexOf('ações'));
    }
    return hidden;
  }

  get exportTablesName(): string {
    let name = this.router.url.split('/').join('-');
    if (name.startsWith('-')) {
      name = name.substring(1);
    }
    return name;
  }
}
