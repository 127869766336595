import { ProposalStage } from "../enums/proposal-stage";
import { Deserializable } from "./deserializable";
import { Document } from "./document.model";
import { User } from "./user.model";

export class Comment implements Deserializable {
  uid?: string;
  propostaNumero!: number;
  propostaEtapa!: ProposalStage;
  descricao!: string;
  login?: string;
  perfil!: 'admin' | 'integrator' | 'client';
  anexos!: (Document | File)[];
  // usuario?: User;
  nomeUsuario?: string;
  criadoEm?: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (!this.descricao) {
      this.descricao = "";
    }
    this.anexos = this.anexos ? this.anexos.map(e => {
      if (e instanceof File) {
        return e;
      }
      return new Document().deserialize(e)
    }) : [];
    if (typeof input.propostaEtapa == 'number') {
      this.propostaEtapa = ProposalStage.fromNumber(input.propostaEtapa);
    }
    return this;
  }

  toSave() {
    const formData: FormData = new FormData();
    formData.append('descricao', this.descricao);
    formData.append('propostaNumero', this.propostaNumero.toString());
    formData.append('propostaEtapa', ProposalStage.toNumber(this.propostaEtapa).toString());
    if (this.files) {
      const files = this.files;
      for (var i=0; i < files.length; i++) {
        formData.append("anexos", files[i]);
      }
    }

    return formData;
  }

  get docs(): Document[] {
    return (this.anexos as any[]).filter(e => (e instanceof Document));
  }
  get files(): File[] {
    return (this.anexos as any[]).filter(e => (e instanceof File));
  }

  get firstNLastNames(): string {
    let names = this.nomeUsuario?.split(' ') ?? [];
    if (names?.length > 1) {
      return names[0] + ' ' + names[names.length - 1];
    }
    return names[0];
  }

  get typeName(): string {
    switch (this.perfil) {
      case 'admin':
        return 'Administrador';
      case 'integrator':
        return 'Integrador';
      default:
        return '';
    }
  }

  get passedTime(): string {
    try {
      let curDate = new Date();
      let createdAt = new Date(this.criadoEm!);
      var diff = Math.abs(curDate.getTime() - createdAt.getTime());

      var days = Math.trunc(diff / (1000 * 3600 * 24));
      var hours = Math.trunc((diff / (1000 * 3600)) - (days * 24));

      let passed = '';
      if (days >= 1) {
        passed += days + ' dia';
        if (days > 1) {
          passed += 's';
        }
        if (hours >= 1) {
          passed += ' e ' + hours + ' hora';
          if (hours > 1) {
            passed += 's';
          }
        }
      } else if (hours >= 1) {
        passed += hours + ' hora';
        if (hours > 1) {
          passed += 's';
        }
      } else {
        passed = 'menos de 1 hora';
      }
      return passed;
    } catch (error) {
      return 'menos de 1 hora';
    }
  }
}
