import { ProposalStage } from "../enums/proposal-stage";
import { Address } from "./address.model";
import { Comment } from "./comment.model";
import { CreditAnalisis } from "./credit-analisis.model";
import { Deserializable } from "./deserializable";
import { Document } from "./document.model";
import { SplitData } from "./split-data.model";
import { User } from "./user.model";

export class Proposal implements Deserializable {
  uid?: string;
  guid?: string;
  numero?: number;
  propostaEtapa!: ProposalStage;
  valorTotalInstalacao?: number;
  valorEntrada?: number;
  carenciaCliente?: number;
  quantidadePaineisInstalados?: number;
  descricaoPaineisInstalados?: string;
  quantidadeInversoresInstalados?: number;
  descricaoInversoresInstalados?: string;
  razaoSocialFavorecido?: string;
  razaoSocialFornecedor?: string;
  cnpjFornecedor?: string;
  demaisItensInstalados?: string;
  carenciaMinima?: number;
  carenciaSelecionada?: number;
  carenciaMaxima?: number;
  parcelaMinima?: number;
  parcelaMaxima?: number;
  parcelaSelecionada?: number;
  taxaDeJurosMinima?: number;
  taxaDeJurosMaxima?: number;
  rendaIndividual?: number;
  rendaFamiliar?: number;
  valorContaLuz?: number;
  potenciaInstalada?: number;
  numeroCcb?: number;
  ofertas: CreditAnalisis[] = [];

  valorEntradaCadastro?: number;
  valorFinanciamentoCadastro?: number;

  splitGuid?: string;
  valorSplit?: number;
  dadosSplit?: SplitData;

  criadoPor?: string;
  criadoEm?: string;
  clienteGuid?: string;
  contatoGuid?: string;
  enderecoInstalacaoGuid?: string;
  endereco?: Address;

  comentarios?: Comment[];
  cliente!: User;
  integrador!: User;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.comentarios = this.comentarios ? this.comentarios.map(e => new Comment().deserialize(e)) : undefined;
    this.propostaEtapa = ProposalStage.fromNumber(input.propostaEtapa);
    this.integrador = new User().deserialize(this.integrador);
    this.cliente = new User().deserialize(this.cliente);
    if (this.splitGuid) {
      this.dadosSplit = new SplitData();
      this.dadosSplit.codigoBanco = input['codigoBanco'];
      this.dadosSplit.tipoConta = input['tipoConta'];
      this.dadosSplit.agencia = input['agencia'];
      this.dadosSplit.conta = input['conta'];
      this.dadosSplit.contaDigito = input['contaDigito'];
      this.dadosSplit.documentoFederal = input['documentoFederal'];
      this.dadosSplit.nomePagamento = input['nomePagamento'];
    }
    if (this.dadosSplit) {
      this.dadosSplit = new SplitData().deserialize(this.dadosSplit);
    }
    return this;
  }

  toSave() {
    return this;
  }

  get perfilDeAtuacao(): 'admin' | 'integrator' | '' {
    if (this.propostaEtapa == ProposalStage.PREANALISEDECREDITO
      || this.propostaEtapa == ProposalStage.APROVACAODECREDITO
      || this.propostaEtapa == ProposalStage.FORMALIZACAO
    ) {
      return 'admin';
    } else if (this.propostaEtapa == ProposalStage.CADASTRO
      || this.propostaEtapa == ProposalStage.NEGOCIACAOCOMERCIAL
    ) {
      return 'integrator';
    } else {
      return '';
    }
  }

  toExport() {
    let contact = this.cliente?.ultimoContato;
    let exportData = {
       cliente_id: this?.numero ?? '',
       nome: contact?.nome ?? '',
       data_nascimento: this.cliente?.nascimento ?? '',
       estado_civil: contact?.estadoCivil ?? '',
       cpf: this.cliente.documento ?? '',
       celular: contact?.celular ?? '',
       telefone: contact?.telefone ?? '',
       email: contact?.email ?? '',
       rg: contact?.documentoIdentificacao ?? '',
       data_emissao: contact?.dataEmissaoDocumento ?? '',
       orgao_expedicao: contact?.orgaExpedidor ?? '',
       emissor: contact?.ufrg ?? '',
       cep: this.cliente?.ultimoEndereco?.cep ?? '',
       numero: this.cliente?.ultimoEndereco?.numero ?? '',
       complemento: this.cliente?.ultimoEndereco?.complemento ?? '',
       logradouro: this.cliente?.ultimoEndereco?.logradouro ?? '',
       bairro: this.cliente?.ultimoEndereco?.bairro ?? '',
       cidade: this.cliente?.ultimoEndereco?.cidade ?? '',
       estado: this.cliente?.ultimoEndereco?.estado?.nome ?? '',
       pais: "Brazil",
       renda_individual: this.rendaIndividual ?? '',
       renda_familiar: this.rendaFamiliar ?? '',
       valor_conta_luz: this.valorContaLuz ?? '',
       valor_financiar: this.valorTotalInstalacao ?? '',
       valor_entrada: this.valorEntrada ?? '',
       carencia: this.carenciaSelecionada ?? "",
       quantidade_parcelas_cliente: this.parcelaSelecionada ?? "",
       equipamento_cep: this.endereco?.cep ?? '',
       equipamento_numero: this.endereco?.numero ?? '',
       equipamento_complemento: this.endereco?.complemento ?? '',
       equipamento_logradouro: this.endereco?.logradouro ?? '',
       equipamento_bairro: this.endereco?.bairro ?? '',
       equipamento_cidade: this.endereco?.cidade ?? '',
       equipamento_estado: this.endereco?.estado?.nome ?? '',
       equipamento_pais: "Brazil",
      //  fornecedor_razao_social: clienteData.equipamento.fornecedor.razaoSocialFornecedor,
      //  fornecedor_cnpj: clienteData.equipamento.fornecedor.cnpjFornecedor,
       potencia_instalada: this.potenciaInstalada ?? '',
       quantidade_paineis: this.quantidadePaineisInstalados ?? '',
       descricao_paineis: this.descricaoPaineisInstalados ?? '',
       quantidade_inversores: this.quantidadeInversoresInstalados ?? '',
       descricao_inversores: this.descricaoInversoresInstalados ?? '',
      //  valor_financiar_proposta1: primeiraOpcao?.propostaFinanciamento?.valorFinanciarProposta ,
      //  valor_entrada_proposta1: primeiraOpcao?.propostaFinanciamento?.valorEntradaProposta ,
      //  carencia_proposta1: primeiraOpcao?.propostaFinanciamento?.carenciaPretendidaProposta ,
      //  quantidade_parcelas_cliente_proposta1: primeiraOpcao?.propostaFinanciamento?.quantidadeParcelasProposta ,
      //  valor_parcela_proposta1: primeiraOpcao?.propostaFinanciamento?.valorParcelaProposta ,
      //  taxa_juros_proposta1: primeiraOpcao?.propostaFinanciamento?.valorJuros,
      //  cet_proposta1: primeiraOpcao?.propostaFinanciamento.cet,
      //  observacoes_proposta1: primeiraOpcao?.propostaFinanciamento?.observacao,
      //  valor_financiar_proposta2: segundaOpcao?.propostaFinanciamento?.valorFinanciarProposta ,
      //  valor_entrada_proposta2: segundaOpcao?.propostaFinanciamento?.valorEntradaProposta ,
      //  carencia_proposta2: segundaOpcao?.propostaFinanciamento?.carenciaPretendidaProposta ,
      //  quantidade_parcelas_cliente_proposta2: segundaOpcao?.propostaFinanciamento?.quantidadeParcelasProposta,
      //  valor_parcela_proposta2: segundaOpcao?.propostaFinanciamento?.valorParcelaProposta,
      //  taxa_juros_proposta2: segundaOpcao?.propostaFinanciamento?.valorJuros,
      //  cet_proposta2: segundaOpcao?.propostaFinanciamento?.cet,
      //  observacoes_proposta2: segundaOpcao?.propostaFinanciamento?.observacao
    };
    return exportData;
  }
}
