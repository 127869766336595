import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { genericFormatter } from 'src/app/core/helpers/masks';
import { CreditAnalisis } from 'src/app/shared/models/credit-analisis.model';

@Component({
  selector: 'app-grid-offer',
  templateUrl: './grid-offer.component.html',
  styleUrls: ['./grid-offer.component.scss']
})
export class GridOfferComponent {
  @Input() gridInstallments: any;
  @Input() selected?: CreditAnalisis;
  @Input() disabled: boolean = true;

  @Output() onSelect = new EventEmitter<CreditAnalisis | undefined>();
  constructor() { }

  get getCarencyDates() {
    let dates: { value: any }[] = [];
    if (this.gridInstallments && Object.keys(this.gridInstallments).length > 0) {
      Object.keys(this.gridInstallments).forEach(key => {
        dates.push({ value: key });
      });
    }
    return dates;
  }

  get getDueDates() {
    let dates: { value: any }[] = [];
    if (this.gridInstallments && Object.keys(this.gridInstallments).length > 0) {
      Object.keys(this.gridInstallments[Object.keys(this.gridInstallments)[0]]).forEach(key => {
        dates.push({ value: key });
      });
    }
    return dates;
  }

  selectFinancingOption(value: any) {
    if (!this.disabled) {
      if (this.selected != value) {
        this.selected = value;
      } else {
        this.selected = undefined;
      }
      this.onSelect.emit(this.selected);
    }
  }

  isFinancingOptionSelected(value: any) {
    return value == this.selected;
  }

  getOfferValue(value: any) {
    let response = value?.resposta?.Proposta?.VlrParcela ?? value?.resposta?.VlrParcela ?? 0;
    return genericFormatter(response, "money");
  }
  getTax(value: any, frequency: 'montly' | 'anual') {
    let response = 0;

    if (frequency == 'montly') {
      response = value.resposta?.Proposta?.PercJurosMensal ?? value.resposta?.PercJurosMensal ?? 0;
    } else if (frequency == 'anual') {
      response = value.resposta?.Proposta?.PercJurosAnual ?? value.resposta?.PercJurosAnual ?? 0;
    }
    return genericFormatter(response, "decimal", 4);
  }
  getCET(value: any, frequency: 'montly' | 'anual') {
    let response = 0;
    if (frequency == 'montly') {
      response = value.resposta?.Proposta?.PercCETMensal ?? value.resposta?.PercCETMensal ?? 0;
    } else if (frequency == 'anual') {
      response = value.resposta?.Proposta?.PercCETAnual ?? value.resposta?.PercCETAnual ?? 0;
    }
    return genericFormatter(response, "decimal", 4);
  }
  getFirstInstallmentDueDate(value: any) {
    let response = '-';
    if ((value.resposta.Proposta && 'Parcelas' in value?.resposta?.Proposta)
     || (value.resposta && 'Parcelas' in value?.resposta)) {
      response = value?.resposta?.Proposta?.Parcelas[0]?.DtVencimento
        ?? value?.resposta?.Parcelas[0]?.DtVencimento ?? '-';
    } else {
      response = value?.resposta?.Proposta?.DataPrimeiraParcela
        ?? value?.resposta?.DataPrimeiraParcela ?? '-';
    }
    return genericFormatter(response, 'date');
  }
}
